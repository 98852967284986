import TranslationJobService from "services/jobs/translation-job/translationJob.service";

export const PROJECTS_AVAILABLES_FETCH_START = "PROJECTS_AVAILABLES_FETCH_START";
export const PROJECTS_AVAILABLES_FETCH_SUCCESS = "PROJECTS_AVAILABLES_FETCH_SUCCESS";
export const PROJECTS_AVAILABLES_FETCH_FAIL = "PROJECTS_AVAILABLES_FETCH_FAIL";

export const VENDORS_FETCH_START = "VENDORS_FETCH_START";
export const VENDORS_FETCH_SUCCESS = "VENDORS_FETCH_SUCCESS";
export const VENDORS_FETCH_FAIL = "VENDORS_FETCH_FAIL";

export const fetchProjectsAvailables = () => {
  return {
    types: [PROJECTS_AVAILABLES_FETCH_START, PROJECTS_AVAILABLES_FETCH_SUCCESS, PROJECTS_AVAILABLES_FETCH_FAIL],
    promise: TranslationJobService.fetchProjects(),
  };
};

export const fetchVendors = () => {
  return {
    types: [VENDORS_FETCH_START, VENDORS_FETCH_SUCCESS, VENDORS_FETCH_FAIL],
    promise: TranslationJobService.fetchVendors(),
  };
};
