export const Actions = {
  Deliver: "Deliver",
  Enable: "Enable",
  Cancel: "Cancel",
  Reopen: "Reopen",
  BulkDeliver: "BulkDeliver",
  BulkCancel: "BulkCancel",
  BulkEnable: "BulkEnable",
};

export const ActionsIcons = {
  Enable: {
    icon: "fa-info-square",
    color: "color-success",
  },
  Cancel: {
    icon: "fa-exclamation-triangle",
    color: "color-danger",
  },
  BulkEnable: {
    icon: "fa-info-square",
    color: "color-success",
  },
  BulkCancel: {
    icon: "fa-exclamation-triangle",
    color: "color-danger",
  },
};

export const ActionModalTopMessage = {
  BulkDeliver: "You are about to complete and submit several LT Review Jobs",
  BulkEnable: "You are about to enable cancelled several LT Review Jobs",
  BulkCancel: "You are about to cancel several LT Review Jobs",
  Deliver: "You are about to complete and submit this LT Review",
  Enable: "You are about to enable this cancelled LT Review",
  Cancel: "You are about to cancel this LT Review",
  Reopen: "You are about to reopen this LT Review",
};

export const ActionModalBottomMessage = {
  BulkDeliver: "Do you want to continue and submit these reviews?",
  BulkEnable: "Do you want to continue and enable these reviews?",
  BulkCancel: "Do you want to continue and cancel these reviews?",
  Deliver: "Do you want to continue and complete this job?",
  Enable: "Do you want to continue and enable this job?",
  Cancel: "Do you want to continue and cancel this job?",
  Reopen: "Do you want to continue and reopen this job?",
};

export const ModalTitle = {
  BulkDeliver: "Complete and Deliver LT Review Jobs",
  BulkEnable: "Enable Cancelled LT Review Jobs",
  BulkCancel: "Cancel LT Review",
  Deliver: "Complete and Deliver LT Review",
  Enable: "Enable Cancelled LT Review",
  Reopen: "Reopen completed LT Review",
  Cancel: "Cancel LT Review",
};
