import {Service} from "../../service";
import {buildStructuresTableRequestData} from "../shared/job.service.helper";

const LtReviewJobStructuresService = {
  fetchStructures: ({jobId, contentGroupId, selectedRow = null, tableState = {}}) => {
    const requestFilters = buildStructuresTableRequestData(tableState);
    const url = `reviews/${jobId}/contentgroup/${contentGroupId}/structures`;

    return Service.get({
      url,
      params: {...requestFilters, selectedRow},
    });
  },

  nextUnreviewedStructure: ({jobId, contentGroupId, selectedRow, tableState = {}}) => {
    const requestFilters = buildStructuresTableRequestData(tableState);
    const url = `reviews/${jobId}/contentgroup/${contentGroupId}/structures/untranslated`;

    return Service.get({
      url,
      params: {...requestFilters, selectedRow},
    });
  },

  goToStructurePageById: ({jobId, contentGroupId, rowId, tableState = {}}) => {
    const requestFilters = buildStructuresTableRequestData(tableState);
    const url = `reviews/${jobId}/contentgroup/${contentGroupId}/structures/position`;

    return Service.get({
      url,
      params: {...requestFilters, searchRowId: rowId},
    });
  },
};

export default LtReviewJobStructuresService;
