export const calculateRemainingColumnsWidth = (columns, totalWidthAvailable) => {
  const usedWidth = columns.reduce((accumulator, currentColumn) => accumulator + (currentColumn.width || 0), 0);
  const availableWidth = totalWidthAvailable - usedWidth;
  let finalColumns = columns;

  const columnsWithoutWidth = columns.reduce((colsWithNoWidth, currentColum) => {
    if (!currentColum.width) {
      colsWithNoWidth.push(currentColum.mapName);
    }
    return colsWithNoWidth;
  }, []);

  if (columnsWithoutWidth.length > 0) {
    const widthPerColumn =
      availableWidth && columnsWithoutWidth.length > 0 ? availableWidth / columnsWithoutWidth.length : 300;

    finalColumns = columns.map((col) => {
      if (columnsWithoutWidth.includes(col.mapName)) {
        col.width = widthPerColumn;
      }
      return col;
    });
  }

  return finalColumns;
};
