import {Map} from "immutable";
import {takeLatest, call, put, delay, select} from "redux-saga/effects";

import TranslationJobService from "services/jobs/translation-job/translationJob.service";

import {
  TRANSLATION_LINES_FETCH,
  TRANSLATION_LINES_GO_TO,
  TRANSLATION_LINES_NEXT_UNTRANSLATED,
  TRANSLATION_LINES_SET_ACTIVE_NEXT,
  TRANSLATION_LINES_SET_ACTIVE_PREVIOUS,
  TRANSLATION_LINE_SAVE_AND_REFRESH,
  TRANSLATION_LINE_SAVE_START,
  TRANSLATION_LINE_SAVE_FINISHED,
  TRANSLATION_SELECTED_LINE_UPDATE,
} from "store/actions/jobs/job-workspace/translation-job-workspace/lines/translationJobWorkspaceLines.actions";
import {
  TRANSLATION_ACTION_FAIL,
  TRANSLATION_ACTION_SUCCESS,
  TRANSLATION_LOADING_START,
  TRANSLATION_STATS_FETCH,
} from "store/actions/jobs/job-workspace/translation-job-workspace/translationJobWorkspace.actions";
import {createErrorToast} from "store/actions/toaster/toaster.actions";
import {buildStructureContentGroupKey, getTranslationWorkspace, selectId} from "store/helpers/jobs.helper";
import {takeLatestPerKey} from "store/sagas/helpers/takeLatestPerKey.helper";
import {getAssetsParameters, updateSelectedRow} from "store/utils/assetsUpdate";

export function* fetchLines({value}) {
  try {
    const {jobId, contentGroupId, structureId, selectedLine = {}, tableState} = value;

    if (structureId) {
      let linesTable = yield call(TranslationJobService.fetchLines, {
        jobId,
        contentGroupId,
        structureId,
        tableState,
        selectedRow: selectedLine.rowId,
      });

      linesTable = {...tableState, ...linesTable};
      const {selectedIndex, selectedIndexPage} = linesTable;

      const selectedRowUpdated = linesTable.rows.find(({rowId}) => rowId === selectedLine.rowId);
      const wordcountActual = selectedRowUpdated ? selectedRowUpdated.wordcountActual : selectedLine.wordcountActual;
      yield put({
        type: TRANSLATION_ACTION_SUCCESS,
        value: {
          linesTable,
          selectedLine: {...selectedLine, wordcountActual, lineIndex: selectedIndex, linePage: selectedIndexPage},
          loading: value.loading || false,
        },
      });
    }
  } catch (error) {
    yield put({type: TRANSLATION_ACTION_FAIL, value: {linesTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

function* setNextUntranslatedAsset({value}) {
  const {assets = []} = value;
  const nextUntranslatedAsset = assets.find(({translated}) => !translated) || assets[0] || {};
  const assetsMap = assets.reduce((acc, item) => acc.set(item.assetId, Map(item)), Map());

  const newValue = {assetsMap, activeAsset: nextUntranslatedAsset};

  yield put({type: TRANSLATION_ACTION_SUCCESS, value: newValue});
}

function* selectLine({value}) {
  const {selectedLine, lineIndex, linePage} = value;
  yield call(setNextUntranslatedAsset, {value: {assets: selectedLine.assets}});
  yield put({type: TRANSLATION_ACTION_SUCCESS, value: {selectedLine: {...selectedLine, lineIndex, linePage}}});
}

export function* fetchNextUntranslatedLine({value}) {
  try {
    yield put({type: TRANSLATION_LOADING_START});
    const {jobId, contentGroupId, structureId, selectedLine = {}, tableState = {}} = value;
    const {rowId = 0} = selectedLine;
    let linesTable = yield call(TranslationJobService.nextUntranslatedLine, {
      jobId,
      contentGroupId,
      structureId,
      selectedRow: rowId,
      tableState,
    });

    const {pageSize, page, totalRows} = linesTable;
    const newSelectedLineIndex = linesTable.rows.findIndex(({isSelected}) => isSelected === true);
    const newSelectedLine = linesTable.rows[newSelectedLineIndex] || {};
    newSelectedLine.parameters = getAssetsParameters(newSelectedLine);

    let newLineIndex = newSelectedLineIndex + pageSize * (page - 1);
    newLineIndex = newLineIndex >= totalRows ? 1 : newLineIndex + 1;

    yield call(selectLine, {value: {selectedLine: newSelectedLine, lineIndex: newLineIndex, linePage: page}});

    linesTable = {...tableState, ...linesTable};

    yield put({type: TRANSLATION_ACTION_SUCCESS, value: {linesTable, loading: value.loading || false}});
  } catch (error) {
    yield put({type: TRANSLATION_ACTION_FAIL, value: {loading: false, linesTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

function* setActiveNextLine({value}) {
  try {
    yield put({type: TRANSLATION_LOADING_START});
    const {tableState, jobId, contentGroupId, structureId, selectedLine} = value;
    const {pageSize, page} = tableState;

    const {lineIndex, linePage, rowId} = selectedLine;
    const activeRowIndex = lineIndex - pageSize * (linePage - 1) - 1;
    if (page !== selectedLine.linePage) {
      yield call(goToLine, {value: {jobId, contentGroupId, structureId, rowId, tableState: selectedLine}});
    } else if (activeRowIndex >= pageSize - 1) {
      yield call(goToNextLine, {value: {jobId, contentGroupId, structureId, selectedLine, tableState}});
    } else {
      const newSelectedLine = tableState.rows[activeRowIndex + 1];
      yield call(selectLine, {
        value: {selectedLine: newSelectedLine, lineIndex: lineIndex + 1, linePage: tableState.page},
      });
      yield put({type: TRANSLATION_ACTION_SUCCESS, value: {loading: false}});
    }
  } catch (error) {
    yield put({type: TRANSLATION_ACTION_FAIL, value: {loading: false, linesTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

function* setActivePreviousLine({value}) {
  try {
    yield put({type: TRANSLATION_LOADING_START});
    const {tableState, jobId, contentGroupId, structureId, selectedLine} = value;
    const {pageSize, page} = tableState;
    const {lineIndex, linePage, rowId} = selectedLine;
    const activeRowIndex = lineIndex - pageSize * (linePage - 1) - 1;
    if (page !== selectedLine.linePage) {
      yield call(goToLine, {value: {jobId, contentGroupId, structureId, rowId, tableState: selectedLine}});
    } else if (activeRowIndex <= 0) {
      yield call(goToPreviousLine, {value: {jobId, contentGroupId, structureId, selectedLine, tableState}});
    } else {
      const newSelectedLine = tableState.rows[activeRowIndex - 1];
      yield call(selectLine, {
        value: {selectedLine: newSelectedLine, lineIndex: lineIndex - 1, linePage: tableState.page},
      });
      yield put({type: TRANSLATION_ACTION_SUCCESS, value: {loading: false}});
    }
  } catch (error) {
    yield put({type: TRANSLATION_ACTION_FAIL, value: {loading: false, linesTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

function* goToNextLine({value}) {
  try {
    const {jobId, contentGroupId, structureId, selectedLine, tableState} = value;

    let linesTable = yield call(TranslationJobService.fetchLines, {
      jobId,
      contentGroupId,
      structureId,
      selectedRow: selectedLine.rowId,
      tableState: {
        ...tableState,
        page: selectedLine.linePage + 1,
      },
    });

    const newSelectedLine = linesTable.rows[0] || {};
    const newSelectedLineIndex = selectedLine.lineIndex + 1;
    yield call(selectLine, {
      value: {selectedLine: newSelectedLine, lineIndex: newSelectedLineIndex, linePage: linesTable.page},
    });

    linesTable = {...tableState, ...linesTable};
    yield put({type: TRANSLATION_ACTION_SUCCESS, value: {linesTable, loading: false}});
  } catch (error) {
    yield put({type: TRANSLATION_ACTION_FAIL, value: {loading: false, linesTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

function* goToPreviousLine({value}) {
  try {
    yield put({type: TRANSLATION_LOADING_START});
    const {jobId, contentGroupId, structureId, selectedLine, tableState} = value;

    let linesTable = yield call(TranslationJobService.fetchLines, {
      jobId,
      contentGroupId,
      structureId,
      selectedRow: selectedLine.rowId,
      tableState: {
        ...tableState,
        page: selectedLine.linePage - 1,
      },
    });

    const newSelectedLine = linesTable.rows[tableState.pageSize - 1] || {};
    const newSelectedLineIndex = selectedLine.lineIndex - 1;
    yield call(selectLine, {
      value: {selectedLine: newSelectedLine, lineIndex: newSelectedLineIndex, linePage: linesTable.page},
    });

    linesTable = {...tableState, ...linesTable};

    yield put({type: TRANSLATION_ACTION_SUCCESS, value: {linesTable, loading: false}});
  } catch (error) {
    yield put({type: TRANSLATION_ACTION_FAIL, value: {loading: false, linesTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

function* goToLine({value}) {
  try {
    yield put({type: TRANSLATION_LOADING_START});
    const {jobId, contentGroupId, structureId, rowId, tableState} = value;

    let linesTable = yield call(TranslationJobService.goToLinePageById, {
      jobId,
      contentGroupId,
      structureId,
      rowId,
      tableState,
    });

    const newSelectedLineIndex = linesTable.rows.findIndex(({isSelected}) => isSelected === true);
    const index = newSelectedLineIndex >= 0 ? newSelectedLineIndex : 0;
    const newSelectedLine = linesTable.rows[index] || {};
    const lineIndex = index + linesTable.pageSize * (linesTable.page - 1) + 1;
    yield call(selectLine, {value: {selectedLine: newSelectedLine, lineIndex, linePage: linesTable.page}});

    // TODO: Review
    // if (clearFilters) {
    //   yield put({type: TRANSLATION_LINES_CLEAR_FILTERS}); // "GoTo" Action should clear the filters
    // }

    linesTable = {...tableState, ...linesTable};
    yield put({type: TRANSLATION_ACTION_SUCCESS, value: {linesTable, loading: false}});
  } catch (error) {
    yield put({type: TRANSLATION_ACTION_FAIL, value: {loading: false, linesTable: value.tableState}});
    yield put(createErrorToast(error || "Error selecting the line"));
  }
}

function* updateLine({newLineStats, assetInfo}) {
  const {linesTable} = yield select(getTranslationWorkspace);
  const [lineRows, updatedAssets] = updateSelectedRow({
    table: linesTable,
    id: newLineStats.lineId,
    idSelector: "lineId",
    stats: newLineStats,
    assetInfo,
  });
  const lineStats = {
    status: newLineStats.status,
    wordcountActual: newLineStats.wordcountActual,
    assets: updatedAssets,
  };
  yield put({type: TRANSLATION_SELECTED_LINE_UPDATE, value: {lineRows, lineStats}});
}

function* saveLineAndRefresh({value: {selectedLine, assetsMap, assetInfo}}) {
  // Update assetsMap, linesTable and lineSaveInProgress values
  yield put({type: TRANSLATION_LINE_SAVE_START, value: {selectedLine, assetsMap, assetInfo}});

  // Delay the call, to avoid multiple calls when writing
  yield delay(3000);

  try {
    const mappedAsset = assetsMap
      .valueSeq()
      .toJS()
      .find(({assetId}) => assetId === assetInfo.assetId);
    const asset = {
      id: mappedAsset.assetId,
      isEmptyString: mappedAsset.isEmptyString,
      translatedText: mappedAsset.translatedText,
      genderId: mappedAsset.genderId,
    };
    const {data: newLineStats} = yield call(TranslationJobService.saveLine, {...selectedLine, asset});
    yield updateLine({newLineStats, assetInfo});
    yield put({type: TRANSLATION_LINE_SAVE_FINISHED});

    const {loadingStats} = yield select(getTranslationWorkspace);
    if (!loadingStats[buildStructureContentGroupKey(selectedLine)])
      yield put({type: TRANSLATION_STATS_FETCH, ...selectedLine});
  } catch (error) {
    yield put({type: TRANSLATION_LINE_SAVE_FINISHED});
    yield put({type: TRANSLATION_ACTION_FAIL, value: {lineSaveInProgress: false, lineSaveError: "Error saving line"}});
  }
}

export const linesSagas = [
  takeLatest(TRANSLATION_LINES_FETCH, fetchLines),
  takeLatest(TRANSLATION_LINES_SET_ACTIVE_NEXT, setActiveNextLine),
  takeLatest(TRANSLATION_LINES_SET_ACTIVE_PREVIOUS, setActivePreviousLine),
  takeLatest(TRANSLATION_LINES_GO_TO, goToLine),
  takeLatest(TRANSLATION_LINES_NEXT_UNTRANSLATED, fetchNextUntranslatedLine),
  takeLatestPerKey(TRANSLATION_LINE_SAVE_AND_REFRESH, saveLineAndRefresh, selectId),
];
