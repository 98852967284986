import React from "react";

import PropTypes from "prop-types";
import {connect} from "react-redux";
import {ProgressBar} from "ui_toolkit";

import ContentNavigator from "features/jobs/job-workspace/components/content-navigator/ContentNavigator";

import {ToggleEnum} from "shared/enums/toggle.enum";

import {handleToggle} from "store/actions/layout/layout.actions";

import StructuresNavigatorContainerWrapper from "../StructuresNavigatorContainerWrapper";

import StitchedTranslationStructuresResources from "./stitchedTranslationStructures.resources";

const StitchedTranslationStructuresContainerPropTypes = {
  translationStructuresCollapsed: PropTypes.bool.isRequired,
  structuresTable: PropTypes.object.isRequired,
  refreshingStats: PropTypes.bool.isRequired,
  selectedStructure: PropTypes.object.isRequired,
  selectedJob: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
  selectedContentBlock: PropTypes.object.isRequired,
  handleToggleAction: PropTypes.func.isRequired,
};

const StitchedTranslationStructuresContainerDefaultProps = {};

class StitchedTranslationStructuresContainer extends React.Component {
  columns = [
    {name: "#", mapName: "rowId", sort: true, width: 5},
    {name: "Completion", mapName: "progress", width: 15},
    {name: "WordCount", mapName: "wordCount", width: 15},
    {name: "Structure", mapName: "name", width: 65},
  ];

  formatRow = (row) => {
    const {
      rowId,
      structureId,
      progress,
      wordcountActual,
      minEstimatedWordcount,
      maxEstimatedWordcount,
      name,
      description,
    } = row;
    const redWordCount = wordcountActual > maxEstimatedWordcount ? "color-danger" : null;
    const wordCount = (
      <span className={redWordCount}>
        {wordcountActual}/{minEstimatedWordcount} - {maxEstimatedWordcount}
      </span>
    );
    const isActive = structureId === this.props.selectedStructure.structureId;
    const progressBar = <ProgressBar color="bg-color-primary" value={progress} fixedWidth={false} />;
    return {
      id: structureId,
      isSelected: isActive,
      data: {
        isActive,
        rowId,
        structureId,
        progress: progressBar,
        wordCount,
        name,
        description,
      },
    };
  };

  formatContentNavigatorProps() {
    const {rows, ...pageData} = this.props.structuresTable;
    const {structureIndex} = this.props.selectedStructure;
    const controlsProps = {
      lineType: StitchedTranslationStructuresResources.LinesType,
      currentLine: structureIndex,
      numberOfLines: pageData.totalRows,
      hasUntranslatedLines: pageData.hasUntranslatedLines,
    };
    const tableProps = {
      rows: rows && rows.map(this.formatRow),
      columns: this.columns,
      pageData,
    };

    return {...controlsProps, ...tableProps};
  }

  buildBoxTitleText = () => {
    const {stats, selectedJob, selectedContentBlock} = this.props;
    return StitchedTranslationStructuresResources.BoxTitle({
      contentBlockId: selectedContentBlock.rowId,
      language: selectedJob && selectedJob.language ? selectedJob.language.name : "",
      contentBlockName: selectedContentBlock.featureName || selectedContentBlock.contentGroupInformation || "",
      translatedGroups: stats.translatedGroups,
      totalGroups: stats.totalGroups,
    });
  };

  handleCollapseToggle = () => {
    this.props.handleToggleAction(ToggleEnum.TranslationStructuresCollapsed);
  };

  render() {
    const {translationStructuresCollapsed, selectedStructure, refreshingStats} = this.props;
    const formattedProps = this.formatContentNavigatorProps();
    const boxTitleText = this.buildBoxTitleText();
    const stitchedStructureDescription = {
      description: selectedStructure.description || "",
      title: StitchedTranslationStructuresResources.InformationTitle(selectedStructure.rowId),
    };

    return (
      <div>
        <StructuresNavigatorContainerWrapper {...this.props} {...formattedProps}>
          {(wrapper) => (
            <ContentNavigator
              elmId="structures-navigator"
              {...formattedProps}
              loading={refreshingStats}
              batchType={wrapper.batchType}
              title={boxTitleText}
              collapsed={translationStructuresCollapsed}
              stitchedStructureDescription={stitchedStructureDescription}
              onNext={wrapper.handleNext}
              onPrevious={wrapper.handlePrevious}
              onNextUntranslatedLine={wrapper.handleNextUntranslatedLine}
              onGoTo={wrapper.handleGoTo}
              onTableChange={wrapper.handleTableChange}
              onTableRowSelect={wrapper.handleTableRowSelect}
              onCollapseToggle={this.handleCollapseToggle}
            />
          )}
        </StructuresNavigatorContainerWrapper>
      </div>
    );
  }
}

StitchedTranslationStructuresContainer.propTypes = StitchedTranslationStructuresContainerPropTypes;
StitchedTranslationStructuresContainer.defaultProps = StitchedTranslationStructuresContainerDefaultProps;

const mapStateToProps = (store) => {
  return {
    translationStructuresCollapsed: store.layout.translationStructuresCollapsed,
    structuresTable: store.jobs.ltReviewWorkspace.structuresTable,
    refreshingStats: store.jobs.ltReviewWorkspace.refreshingStats,
    selectedStructure: store.jobs.ltReviewWorkspace.selectedStructure,
    selectedJob: store.jobs.ltReviewWorkspace.selectedJob,
    selectedContentBlock: store.jobs.ltReviewWorkspace.selectedContentBlock,
    stats: store.jobs.ltReviewWorkspace.stats,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleToggleAction: (key) => dispatch(handleToggle(key)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StitchedTranslationStructuresContainer);
