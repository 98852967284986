import {takeLatest, call, put} from "redux-saga/effects";

import UserService from "services/users/user.service";

import {getLanguages} from "store/actions/master/master.actions";
import {createErrorToast} from "store/actions/toaster/toaster.actions";
import {
  USER_ACTIONS_FETCH,
  USER_ACTIONS_FETCH_START,
  USER_ACTIONS_FETCH_SUCCESS,
  USER_ACTIONS_FETCH_FAIL,
  CURRENT_USER_INFO_FETCH,
  CURRENT_USER_INFO_FETCH_START,
  CURRENT_USER_INFO_FETCH_SUCCESS,
  CURRENT_USER_INFO_FETCH_FAIL,
  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
} from "store/actions/users/user.actions.js";

function* fetchUserActions() {
  try {
    yield put({type: USER_ACTIONS_FETCH_START});
    const actions = yield call(UserService.fetchUserActions);
    yield put({type: USER_ACTIONS_FETCH_SUCCESS, value: {actions}});
  } catch (error) {
    yield put({type: USER_ACTIONS_FETCH_FAIL});
    yield put(createErrorToast(error));
  }
}

function* fetchCurrentUserInfo() {
  try {
    yield put({type: CURRENT_USER_INFO_FETCH_START});
    const userInfo = yield call(UserService.fetchCurrentUserInfo);
    yield put(getLanguages());
    yield put({type: CURRENT_USER_INFO_FETCH_SUCCESS, value: {userInfo}});
  } catch (error) {
    yield put({type: CURRENT_USER_INFO_FETCH_FAIL});
    yield put(createErrorToast(error));
  }
}

function* logOut({refreshTokenKey, navigateToLogin}) {
  try {
    const result = yield call(UserService.logOut, refreshTokenKey);
    yield put({type: USER_LOGOUT_SUCCESS, value: {result}});
    navigateToLogin();
  } catch (error) {
    yield put(createErrorToast(error));
  }
}

export default [
  takeLatest(USER_ACTIONS_FETCH, fetchUserActions),
  takeLatest(CURRENT_USER_INFO_FETCH, fetchCurrentUserInfo),
  takeLatest(USER_LOGOUT, logOut),
];
