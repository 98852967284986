const updateAssets = ({assets, assetId, isEmptyString, translatedText, gender, genderId}) => {
  const updatedAssets = [...assets];
  const selectedIndex = assets.findIndex((row) => row.assetId === assetId);
  updatedAssets[selectedIndex] = {...assets[selectedIndex], isEmptyString, translatedText, gender, genderId};
  return updatedAssets;
};

export const updateAssetsInLinesRows = ({rows = [], assetInfo}) => {
  const {assetId, isEmptyString, translatedText, gender, genderId} = assetInfo;
  return rows.map((row) => {
    const assets = row.assets.map((asset) =>
      asset.assetId === assetId ? {...asset, isEmptyString, translatedText, gender, genderId} : asset,
    );
    return {...row, assets};
  });
};

export const updateSelectedRow = ({table, id, idSelector, stats, assetInfo}) => {
  const rows = table && table.rows ? [...table.rows] : [];
  const selectedIndex = rows.findIndex((row) => row[idSelector] === id);
  let assets;
  // If selected row is rendered, we must update it
  if (selectedIndex >= 0) {
    const selectedContentGroup = rows[selectedIndex] || {};
    rows[selectedIndex] = {...selectedContentGroup, ...stats};
    if (assetInfo) {
      const {assetId, isEmptyString, translatedText, gender, genderId} = assetInfo;
      assets = updateAssets({
        assets: rows[selectedIndex].assets,
        assetId,
        isEmptyString,
        translatedText,
        gender,
        genderId,
      });
      rows[selectedIndex].assets = assets;
    }
  }
  return [rows, assets];
};

export const getAssetsParameters = ({assets}) => {
  let newAssets = [];
  if (assets && assets.length) {
    assets.sort((item1, item2) => item1.phraseOrderId - item2.phraseOrderId);
    assets.forEach((asset) => {
      newAssets = newAssets.concat(asset.parameters);
    });
  }
  return newAssets;
};
