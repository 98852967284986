import {useEffect} from "react";

import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Box} from "ui_toolkit";

import {LineStatusNameEnum} from "shared/enums/line.enum";

import {
  saveAndRefreshLine,
  reviewLineAndRefresh,
  setActiveLtReviewAsset,
} from "store/actions/jobs/job-workspace/lt-review-job-workspace/lines/ltReviewJobWorkspaceLines.actions";

import {VariantTypes} from "src/shared/enums/variant.enum";

import ContentArea from "../../../components/content-area/content-area/ContentArea";

import ContentAreaResources from "./contentArea.resources";

const ContentAreaContainerPropTypes = {
  lineSaveInProgress: PropTypes.bool.isRequired,
  lineSaveError: PropTypes.string.isRequired,
  selectedJob: PropTypes.object.isRequired,
  selectedLine: PropTypes.object.isRequired,
  activeAsset: PropTypes.object.isRequired,
  assetsMap: PropTypes.object.isRequired,
  saveAndRefreshLine: PropTypes.func.isRequired,
  reviewLineAndRefresh: PropTypes.func.isRequired,
  setActiveLtReviewAsset: PropTypes.func.isRequired,
};

const ContentAreaContainerDefaultProps = {};

const ContentAreaContainer = ({
  selectedLine,
  selectedJob,
  lineSaveInProgress,
  lineSaveError,
  activeAsset,
  assetsMap,
  saveAndRefreshLine: saveAndRefreshLineAction,
  setActiveLtReviewAsset: setActiveLtReviewAssetAction,
  reviewLineAndRefresh: reviewLineAndRefreshAction,
}) => {
  useEffect(() => {
    const jobCanBeSaved = (job) => job.isReadonly !== undefined && !job.isReadonly;
    const lineIsInProgress = ({status}) =>
      status === LineStatusNameEnum.Pending.id || status === LineStatusNameEnum.InProgress.id;
    if (jobCanBeSaved(selectedJob) && lineIsInProgress(selectedLine)) {
      const assetInfo = {
        assetId: activeAsset.assetId,
        isEmptyString: activeAsset.isEmptyString,
        translatedText: activeAsset.translatedText,
        genderId: activeAsset.genderId,
      };
      reviewLineAndRefreshAction({selectedLine, assetInfo, id: selectedLine.lineId});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob, selectedLine]);

  const handleSaveLine = ({newAssetsMap, assetId}) => {
    const currentAsset = newAssetsMap.get(assetId).toJS();
    saveAndRefreshLineAction({
      assetsMap: newAssetsMap,
      selectedLine,
      assetInfo: {
        assetId,
        isEmptyString: currentAsset.isEmptyString,
        translatedText: currentAsset.translatedText,
        genderId: currentAsset.genderId,
        gender: currentAsset.gender,
      },
      id: assetId,
    });
  };

  const handleChangeActiveAsset = ({assetId}) => {
    const newActiveAsset = assetsMap.getIn([assetId]).toJS();
    setActiveLtReviewAssetAction(newActiveAsset);
  };

  const handleCheckboxChange = ({assetId, isEmptyString}) => {
    let newAssetsMap = assetsMap.setIn([assetId, "isEmptyString"], isEmptyString);
    if (isEmptyString) {
      newAssetsMap = assetsMap.setIn([assetId, "translatedText"], "");
    }
    handleSaveLine({newAssetsMap, assetId});
  };

  const handleTextChange = ({assetId, isEmptyString, translatedText}) => {
    let newAssetsMap = assetsMap.setIn([assetId, "translatedText"], translatedText);
    if (translatedText !== "" && isEmptyString) {
      newAssetsMap = newAssetsMap.setIn([assetId, "isEmptyString"], false);
    }
    handleSaveLine({newAssetsMap, assetId});
  };

  const handleOnGenderChange = (assetId, genderId) => {
    const newAssetsMap = assetsMap
      .setIn([assetId, "genderId"], genderId)
      .setIn([assetId, "gender"], VariantTypes[genderId].text);
    handleSaveLine({newAssetsMap, assetId});
  };

  return (
    <Box collapsible={false} title={ContentAreaResources.BoxTitle} elmId="content-area">
      <ContentArea
        elmId="content-area"
        lineSaveInProgress={lineSaveInProgress}
        lineSaveError={lineSaveError}
        referenceLineTitle={ContentAreaResources.ReferenceLineTitle}
        contextDescriptionTitle={ContentAreaResources.ContextDescriptionTitle}
        notesTitle={ContentAreaResources.NotesTitle}
        structureLabel={ContentAreaResources.StructureLabel}
        selectedLine={selectedLine}
        assetsMap={assetsMap}
        activeAsset={activeAsset}
        batchTopology={selectedJob?.batchTopologyId}
        language={selectedJob?.language || {id: 0, name: ""}}
        isReadonlyJob={selectedJob.isReadonly}
        onCheckboxChange={handleCheckboxChange}
        onTextChange={handleTextChange}
        onChangeActiveAsset={handleChangeActiveAsset}
        onGenderChange={handleOnGenderChange}
      />
    </Box>
  );
};

ContentAreaContainer.propTypes = ContentAreaContainerPropTypes;
ContentAreaContainer.defaultProps = ContentAreaContainerDefaultProps;

const mapStateToProps = (store) => ({
  lineSaveInProgress: store.jobs.ltReviewWorkspace.lineSaveInProgress,
  lineSaveError: store.jobs.ltReviewWorkspace.lineSaveError,
  selectedJob: store.jobs.ltReviewWorkspace.selectedJob,
  selectedLine: store.jobs.ltReviewWorkspace.selectedLine,
  activeAsset: store.jobs.ltReviewWorkspace.activeAsset,
  assetsMap: store.jobs.ltReviewWorkspace.assetsMap,
});

const mapDispatchToProps = {
  saveAndRefreshLine,
  reviewLineAndRefresh,
  setActiveLtReviewAsset,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentAreaContainer);
