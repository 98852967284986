import PropTypes from "prop-types";
import {connect} from "react-redux";

import BatchType from "shared/enums/batchType.enum.js";

import {
  fetchTranslationStructures,
  fetchNextUntranslatedStructures,
  setActivePreviousTranslationStructure,
  setActiveNextTranslationStructure,
  goToTranslationStructure,
} from "store/actions/jobs/job-workspace/translation-job-workspace/structures/translationJobWorkspaceStructures.actions";

const StructuresNavigatorContainerWrapperPropTypes = {
  children: PropTypes.func.isRequired,
  structuresTable: PropTypes.object.isRequired,
  selectedStructure: PropTypes.object.isRequired,
  selectedJob: PropTypes.object.isRequired,
  selectedContentBlock: PropTypes.object.isRequired,
  fetchTranslationStructuresAction: PropTypes.func.isRequired,
  fetchNextUntranslatedStructuresAction: PropTypes.func.isRequired,
  setActivePreviousTranslationStructureAction: PropTypes.func.isRequired,
  setActiveNextTranslationStructureAction: PropTypes.func.isRequired,
  goToTranslationStructureAction: PropTypes.func.isRequired,
};

const StructuresNavigatorContainerWrapper = (props) => {
  const handleNextUntranslatedLine = () => {
    props.fetchNextUntranslatedStructuresAction({
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      tableState: props.structuresTable,
      selectedStructure: props.selectedStructure,
    });
  };

  const handleNext = () => {
    props.setActiveNextTranslationStructureAction({
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      tableState: props.structuresTable,
      selectedStructure: props.selectedStructure,
    });
  };

  const handlePrevious = () => {
    props.setActivePreviousTranslationStructureAction({
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      tableState: props.structuresTable,
      selectedStructure: props.selectedStructure,
    });
  };

  const handleGoTo = (rowId) => {
    props.goToTranslationStructureAction({
      rowId,
      jobId: props.selectedJob.id,
      tableState: props.structuresTable,
      contentGroupId: props.selectedContentBlock.id,
      clearFilters: true, // TODO: Review
    });
  };

  const handleTableChange = (tableState) => {
    const {selectedJob, selectedContentBlock, selectedStructure} = props;
    const {id: selectedJobId} = selectedJob;
    const {id: selectedContentBlockId} = selectedContentBlock;

    if (selectedJobId && selectedContentBlockId && selectedStructure) {
      props.fetchTranslationStructuresAction({
        jobId: props.selectedJob.id,
        contentGroupId: props.selectedContentBlock.id,
        tableState,
        selectedStructure: props.selectedStructure,
      });
    }
  };

  const handleTableRowSelect = ({rowId}) => {
    props.goToTranslationStructureAction({
      rowId,
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      tableState: props.structuresTable,
    });
  };

  return (
    <>
      {props.children({
        batchType: BatchType.Translation,
        handleNextUntranslatedLine,
        handleNext,
        handlePrevious,
        handleGoTo,
        handleTableChange,
        handleTableRowSelect,
      })}
    </>
  );
};

StructuresNavigatorContainerWrapper.propTypes = StructuresNavigatorContainerWrapperPropTypes;

const mapDispatchToProps = {
  fetchTranslationStructuresAction: fetchTranslationStructures,
  fetchNextUntranslatedStructuresAction: fetchNextUntranslatedStructures,
  setActivePreviousTranslationStructureAction: setActivePreviousTranslationStructure,
  setActiveNextTranslationStructureAction: setActiveNextTranslationStructure,
  goToTranslationStructureAction: goToTranslationStructure,
};

export default connect(null, mapDispatchToProps)(StructuresNavigatorContainerWrapper);
