export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";

export const createSuccessToast = (message) => {
  return {
    type: ADD_TOAST,
    toast: {
      type: "success",
      message,
      msec: 5000,
    },
  };
};

export const createErrorToast = (message) => {
  return {
    type: ADD_TOAST,
    toast: {
      type: "error",
      message,
    },
  };
};

export const removeToast = (toastId) => {
  return {
    type: REMOVE_TOAST,
    toastId,
  };
};
