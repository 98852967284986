import {useDispatch, useSelector} from "react-redux";
import {Modal, Icon} from "ui_toolkit";

import {cancelDeliverLtReviewJob} from "store/actions/jobs/job-workspace/lt-review-job-workspace/ltReviewJobWorkspace.actions";

import {useDeliverLTReviewJob} from "src/services/jobs/lt-review-job/ltReviewJob.query";
import type {$TSFixme} from "src/types/TSFixme";

const conditions = {
  BOTH: "BOTH",
  WORDCOUNT: "WORDCOUNT",
  INCOMPLETE: "INCOMPLETE",
  NONE: "NONE",
} as const;

const DeliverJobContainer = () => {
  const deliverInProgress = useSelector((store: $TSFixme) => store.jobs.ltReviewWorkspace.deliverInProgress);
  const deliverConfirmationModalVisible = useSelector(
    (store: $TSFixme) => store.jobs.ltReviewWorkspace.deliverConfirmationModalVisible,
  );
  const stats = useSelector((store: $TSFixme) => store.jobs.ltReviewWorkspace.stats);
  const selectedJob = useSelector((store: $TSFixme) => store.jobs.ltReviewWorkspace.selectedJob);
  const dispatch = useDispatch();
  const {mutateAsync: deliverLtReviewJob} = useDeliverLTReviewJob();

  const getCondition = () => {
    const isIncomplete = stats.translatedLines < stats.totalLines;
    const excessedWordcount = stats.wordcountActual > stats.maxEstimatedWordcount;
    if (isIncomplete && excessedWordcount) return conditions.BOTH;
    if (isIncomplete) return conditions.INCOMPLETE;
    if (excessedWordcount) return conditions.WORDCOUNT;
    return conditions.NONE;
  };

  const getIcon = () => {
    const actualCondition = getCondition();
    if (actualCondition !== conditions.NONE) return {icon: "fa-exclamation-triangle", colorIcon: "color-danger"};
    return {icon: "fa-trophy", colorIcon: "color-success"};
  };

  const getTopMessage = () => {
    const actualCondition = getCondition();
    if (actualCondition === conditions.INCOMPLETE) {
      return (
        <span>
          You are about to submit an <span className="color-danger">INCOMPLETE</span> LT review job
        </span>
      );
    }
    return <span>You are about to complete and submit this LT Review job</span>;
  };

  const getBottomMessage = () => {
    const actualCondition = getCondition();
    const {wordcountActual, maxEstimatedWordcount} = stats;
    if (actualCondition === conditions.BOTH) {
      return (
        <div>
          <span className="color-danger">The job contains incomplete LT Reviews</span>
          <div className="m-t-sm">
            <span className="color-danger">You have exceeded the estimated wordcount</span>
            <div className="flex-container justify-content-center m-t-sm">
              <span>
                Total Wordcount: <span className="color-danger">{wordcountActual}</span> / {maxEstimatedWordcount}
              </span>
            </div>
          </div>
        </div>
      );
    }
    if (actualCondition === conditions.INCOMPLETE)
      return <span className="color-danger">The job contains incomplete LT Reviews!</span>;
    if (actualCondition === conditions.WORDCOUNT) {
      return (
        <div>
          <span className="color-danger">You have exceeded the estimated wordcount</span>
          <div className="flex-container justify-content-center m-t-sm">
            <span>
              Total Wordcount: <span className="color-danger">{wordcountActual}</span> / {maxEstimatedWordcount}
            </span>
          </div>
        </div>
      );
    }
    return <span className="color-success">Job completed with no wordcount exceeded. Excellent!</span>;
  };

  const handleDeliverModalOk = () => {
    deliverLtReviewJob({jobId: selectedJob.id});
  };

  const handleDeliverModalCancel = () => {
    dispatch(cancelDeliverLtReviewJob());
  };

  const {icon, colorIcon} = getIcon();
  return (
    <div>
      <Modal
        title="Complete and deliver Job"
        visible={deliverConfirmationModalVisible}
        isLoading={deliverInProgress}
        onOk={handleDeliverModalOk}
        onCancel={handleDeliverModalCancel}
      >
        <div className="flex-container flex-direction-column align-items-center">
          <div className="m-b-sm">{getTopMessage()}</div>
          <Icon xlarge className={`m-b-sm ${colorIcon}`} icon={icon} />
          <div className="m-b-md">{getBottomMessage()}</div>
          <div>Do you want to continue and complete this job?</div>
        </div>
      </Modal>
    </div>
  );
};

export default DeliverJobContainer;
