import locale from "src/locale/en-us";

const VariantEnum = {
  None: 0,
  Neutral: 1,
  Male: 2,
  Female: 3,
};

const VariantTypes = {
  0: {value: 0, text: locale.None},
  1: {value: 1, text: locale.Neutral},
  2: {value: 2, text: locale.Male},
  3: {value: 3, text: locale.Female},
};

export {VariantEnum, VariantTypes};
