const StitchedContentBlockResources = {
  BoxTitle: ({jobName = "", deliveryDeadline = "", remainingDays = ""}) => {
    const daysRemainingMessage =
      remainingDays < 0 ? `${Math.abs(parseInt(remainingDays, 10))} days overdue` : `${remainingDays} days remaining`;
    return `Translation Job: ${jobName} / Delivery Deadline: ${deliveryDeadline} (${daysRemainingMessage})`;
  },
  DeliveryDeadlineTitle: "Delivery Deadline",
  ContentBlockTitle: "Content Groups",
  ContentBlockInformationTitle: "Content Block Information",
  StructureLabel: "Structures",
  DeliverButtonText: "Deliver Translation Job",
};

export default StitchedContentBlockResources;
