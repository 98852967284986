export const TRANSLATION_STRUCTURES_FETCH = "TRANSLATION_STRUCTURES_FETCH";
export const TRANSLATION_STRUCTURES_SET_ACTIVE_NEXT = "TRANSLATION_STRUCTURES_SET_ACTIVE_NEXT";
export const TRANSLATION_STRUCTURES_SET_ACTIVE_PREVIOUS = "TRANSLATION_STRUCTURES_SET_ACTIVE_PREVIOUS";
export const TRANSLATION_STRUCTURES_GO_TO = "TRANSLATION_STRUCTURES_GO_TO";
export const TRANSLATION_STRUCTURES_NEXT_UNTRANSLATED = "TRANSLATION_STRUCTURES_NEXT_UNTRANSLATED";
export const TRANSLATION_SELECTED_STRUCTURE_UPDATE = "TRANSLATION_SELECTED_STRUCTURE_UPDATE";

export const fetchTranslationStructures = (requestData) => {
  return {
    type: TRANSLATION_STRUCTURES_FETCH,
    value: requestData,
  };
};

export const fetchNextUntranslatedStructures = (requestData) => {
  return {
    type: TRANSLATION_STRUCTURES_NEXT_UNTRANSLATED,
    value: requestData,
  };
};

export const setActiveNextTranslationStructure = (requestData) => {
  return {
    type: TRANSLATION_STRUCTURES_SET_ACTIVE_NEXT,
    value: requestData,
  };
};

export const setActivePreviousTranslationStructure = (requestData) => {
  return {
    type: TRANSLATION_STRUCTURES_SET_ACTIVE_PREVIOUS,
    value: requestData,
  };
};

export const goToTranslationStructure = (requestData) => {
  return {
    type: TRANSLATION_STRUCTURES_GO_TO,
    value: requestData,
  };
};
