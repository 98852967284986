export const SET_CURRENT_PAGE_TITLE = "SET_CURRENT_PAGE_TITLE";
export const HANDLE_TOGGLE = "HANDLE_TOGGLE";
export const EXPAND_ALL = "EXPAND_ALL";

export const setCurrentPageTitle = (currentPageTitle) => {
  return {
    type: SET_CURRENT_PAGE_TITLE,
    currentPageTitle,
  };
};

export const handleToggle = (key) => {
  return {
    type: HANDLE_TOGGLE,
    value: key,
  };
};
