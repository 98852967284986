import {Actions} from "features/jobs/job-selector/containers/lt-review-job/ltReviewJobSelector.resources";

import {completeLtReview} from "store/actions/jobs/job-selector/lt-review-job-selector/ltReviewJobSelector.actions";

// TODO: The actions: cancelReviewMenuAction, enableCanceledReviewMenuAction and reopenCompletedReviewMenuAction will be disabled for time being US: http://mad-jira-pro.ad.ea.com/browse/ELIT-3436

export function formatAvailableJobs(availableJobs) {
  return availableJobs.map((job) => {
    // TODO: see if this consts are needed
    // const cancelReviewMenuAction = {
    //   key: Actions.Cancel,
    //   onMouseDownCallback: cancelLtReview.bind(this, job.id),
    //   text: "Cancel LT Review",
    //   id: job.id,
    // };

    // const enableCanceledReviewMenuAction = {
    //   key: Actions.Enable,
    //   onMouseDownCallback: enableLtReview.bind(this, job.id),
    //   text: "Enable cancelled LT Review",
    //   id: job.id,
    // };

    const markAsCompletedReviewMenuAction = {
      key: Actions.Deliver,
      onMouseDownCallback: completeLtReview.bind(this, job.id),
      text: "Mark LT Review as completed",
      id: job.id,
    };

    // const reopenCompletedReviewMenuAction = {
    //   key: Actions.Reopen,
    //   onMouseDownCallback: reopenLtReview.bind(this, job.id),
    //   text: "Reopen completed LT Review",
    //   id: job.id,
    // };

    job.contextualActions = [markAsCompletedReviewMenuAction];
    return job;
  });
}
