import {takeLatest, call, put} from "redux-saga/effects";

import JobService from "services/jobs/translation-job/translationJob.service";

import {
  FETCH_JOB_DATA_START,
  FETCH_JOB_DATA_SUCCESS,
  FETCH_JOB_DATA_FAIL,
} from "store/actions/jobs/external-job/externalJob.actions";
import {createErrorToast} from "store/actions/toaster/toaster.actions";

function* getJobUrlFromExternalUrl({value}) {
  try {
    const {externalId, projectId} = value;
    const jobData = yield call(JobService.getJobDataFromExternalData, {externalId, projectId});
    yield put({type: FETCH_JOB_DATA_SUCCESS, value: jobData});
  } catch (error) {
    yield put({type: FETCH_JOB_DATA_FAIL, value: error});
    yield put(createErrorToast(error));
  }
}

export default [takeLatest(FETCH_JOB_DATA_START, getJobUrlFromExternalUrl)];
