export const LT_REVIEW_LINES_FETCH = "LT_REVIEW_LINES_FETCH";
export const LT_REVIEW_LINES_SET_ACTIVE_NEXT = "LT_REVIEW_LINES_SET_ACTIVE_NEXT";
export const LT_REVIEW_LINES_SET_ACTIVE_PREVIOUS = "LT_REVIEW_LINES_SET_ACTIVE_PREVIOUS";
export const LT_REVIEW_LINES_GO_TO = "LT_REVIEW_LINES_GO_TO";
export const LT_REVIEW_LINES_NEXT_UNREVIEWED = "LT_REVIEW_LINES_NEXT_UNREVIEWED";
export const LT_REVIEW_LINES_CLEAR_FILTERS = "LT_REVIEW_LINES_CLEAR_FILTERS";
export const LT_REVIEW_LINES_CLEAR_FILTERS_SUCCESS = "LT_REVIEW_LINES_CLEAR_FILTERS_SUCCESS";
export const LT_REVIEW_LINE_SAVE_AND_REFRESH = "LT_REVIEW_LINE_SAVE_AND_REFRESH";
export const LT_REVIEW_LINE_REVIEW_LINE_AND_REFRESH = "LT_REVIEW_LINE_REVIEW_LINE_AND_REFRESH";

export const LT_REVIEW_LINE_SAVED_START = "LT_REVIEW_LINE_SAVED_START";
export const LT_REVIEW_LINE_SAVED_SUCCESS = "LT_REVIEW_LINE_SAVED_SUCCESS";

export const LT_REVIEW_SET_REVIEWED_START = "LT_REVIEW_SET_REVIEWED_START";
export const LT_REVIEW_SET_REVIEWED_SUCCESS = "LT_REVIEW_SET_REVIEWED_SUCCESS";

export const LT_REVIEW_ACTIVE_LINE_SET = "LT_REVIEW_ACTIVE_LINE_SET";

export const LT_REVIEW_LINE_SAVE_START = "LT_REVIEW_LINE_SAVE_START";
export const LT_REVIEW_LINE_SAVE_FINISHED = "LT_REVIEW_LINE_SAVE_FINISHED";

export const LT_REVIEW_SELECTED_LINE_UPDATE = "LT_REVIEW_SELECTED_LINE_UPDATE";

export const fetchLtReviewLines = (requestData) => {
  return {
    type: LT_REVIEW_LINES_FETCH,
    value: requestData,
  };
};

export const fetchNextUnreviewedLines = (requestData) => {
  return {
    type: LT_REVIEW_LINES_NEXT_UNREVIEWED,
    value: requestData,
  };
};

export const setActiveNextLtReviewLine = (requestData) => {
  return {
    type: LT_REVIEW_LINES_SET_ACTIVE_NEXT,
    value: requestData,
  };
};

export const setActivePreviousLtReviewLine = (requestData) => {
  return {
    type: LT_REVIEW_LINES_SET_ACTIVE_PREVIOUS,
    value: requestData,
  };
};

export const goToLtReviewLine = (requestData) => {
  return {
    type: LT_REVIEW_LINES_GO_TO,
    value: requestData,
  };
};

export const saveAndRefreshLine = ({id, ...value}) => ({
  type: LT_REVIEW_LINE_SAVE_AND_REFRESH,
  id,
  value,
});

export const reviewLineAndRefresh = ({id, ...value}) => ({
  type: LT_REVIEW_LINE_REVIEW_LINE_AND_REFRESH,
  id,
  value,
});

export const setActiveLtReviewAsset = (activeAsset) => ({
  type: LT_REVIEW_ACTIVE_LINE_SET,
  value: activeAsset,
});
