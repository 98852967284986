export const GET_VERSION_DATA = "GET_VERSION_DATA";
export const GET_VERSION_DATA_START = "GET_VERSION_DATA_START";
export const GET_VERSION_DATA_SUCCESS = "GET_VERSION_DATA_SUCCESS";
export const GET_VERSION_DATA_FAIL = "GET_VERSION_DATA_FAIL";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_LANGUAGES_START = "GET_LANGUAGES_START";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_LANGUAGES_FAIL = "GET_LANGUAGES_FAIL";

export const getVersionData = () => ({
  type: GET_VERSION_DATA,
});

export const getLanguages = () => ({
  type: GET_LANGUAGES,
});
