import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Box} from "ui_toolkit";

import {
  saveAndRefreshLine,
  setActiveTranslationAsset,
} from "store/actions/jobs/job-workspace/translation-job-workspace/lines/translationJobWorkspaceLines.actions";

import {VariantTypes} from "src/shared/enums/variant.enum";

import ContentArea from "../../../components/content-area/content-area/ContentArea";

import ContentAreaResources from "./contentArea.resources";

const ContentAreaContainerPropTypes = {
  lineSaveInProgress: PropTypes.bool.isRequired,
  lineSaveError: PropTypes.string.isRequired,
  selectedJob: PropTypes.object.isRequired,
  selectedLine: PropTypes.object.isRequired,
  activeAsset: PropTypes.object.isRequired,
  assetsMap: PropTypes.object.isRequired,
  saveAndRefreshLineAction: PropTypes.func.isRequired,
  setActiveTranslationAssetAction: PropTypes.func.isRequired,
};

const ContentAreaContainerDefaultProps = {};

const ContentAreaContainer = (props) => {
  const handleChangeActiveAsset = ({assetId}) => {
    const activeAsset = props.assetsMap.getIn([assetId]).toJS();
    props.setActiveTranslationAssetAction(activeAsset);
  };

  const handleSaveLineAndRefresh = ({assetsMap, assetId}) => {
    const {selectedLine, saveAndRefreshLineAction} = props;
    const currentAsset = assetsMap.get(assetId).toJS();
    saveAndRefreshLineAction({
      assetsMap,
      selectedLine,
      assetInfo: {
        assetId,
        isEmptyString: currentAsset.isEmptyString,
        translatedText: currentAsset.translatedText,
        genderId: currentAsset.genderId,
        gender: currentAsset.gender,
      },
      id: assetId,
    });
  };

  const handleCheckboxChange = ({assetId, isEmptyString}) => {
    let assetsMap = props.assetsMap.setIn([assetId, "isEmptyString"], isEmptyString);
    if (isEmptyString) {
      assetsMap = assetsMap.setIn([assetId, "translatedText"], "");
    }
    handleSaveLineAndRefresh({assetsMap, assetId});
  };

  const handleTextChange = ({assetId, isEmptyString, translatedText}) => {
    let assetsMap = props.assetsMap.setIn([assetId, "translatedText"], translatedText);
    if (translatedText !== "" && isEmptyString) {
      assetsMap = assetsMap.setIn([assetId, "isEmptyString"], false);
    }
    handleSaveLineAndRefresh({assetsMap, assetId});
  };

  const handleOnGenderChange = (assetId, genderId) => {
    const assetsMap = props.assetsMap
      .setIn([assetId, "genderId"], genderId)
      .setIn([assetId, "gender"], VariantTypes[genderId].text);
    handleSaveLineAndRefresh({assetsMap, assetId});
  };

  return (
    <Box collapsible={false} title={ContentAreaResources.BoxTitle} elmId="content-area">
      <ContentArea
        elmId="content-area"
        lineSaveInProgress={props.lineSaveInProgress}
        lineSaveError={props.lineSaveError}
        referenceLineTitle={ContentAreaResources.ReferenceLineTitle}
        contextDescriptionTitle={ContentAreaResources.ContextDescriptionTitle}
        notesTitle={ContentAreaResources.NotesTitle}
        structureLabel={ContentAreaResources.StructureLabel}
        selectedLine={props.selectedLine}
        assetsMap={props.assetsMap}
        activeAsset={props.activeAsset}
        batchTopology={props.selectedJob?.batchTopologyId}
        language={props.selectedJob?.language || {id: 0, name: ""}}
        isReadonlyJob={props.selectedJob.isReadonly}
        onCheckboxChange={handleCheckboxChange}
        onTextChange={handleTextChange}
        onChangeActiveAsset={handleChangeActiveAsset}
        onGenderChange={handleOnGenderChange}
      />
    </Box>
  );
};

ContentAreaContainer.propTypes = ContentAreaContainerPropTypes;
ContentAreaContainer.defaultProps = ContentAreaContainerDefaultProps;

const mapStateToProps = (store) => ({
  lineSaveInProgress: store.jobs.translationWorkspace.lineSaveInProgress,
  lineSaveError: store.jobs.translationWorkspace.lineSaveError,
  selectedJob: store.jobs.translationWorkspace.selectedJob,
  selectedLine: store.jobs.translationWorkspace.selectedLine,
  activeAsset: store.jobs.translationWorkspace.activeAsset,
  assetsMap: store.jobs.translationWorkspace.assetsMap,
});

const mapDispatchToProps = {
  saveAndRefreshLineAction: saveAndRefreshLine,
  setActiveTranslationAssetAction: setActiveTranslationAsset,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentAreaContainer);
