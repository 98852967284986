import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Badge, Icon, Tooltip} from "ui_toolkit";

import {orderPhrases} from "features/jobs/job-workspace/shared/contentLines.helper.js";

import {LineStatusEnum} from "shared/enums/line.enum";
import {ToggleEnum} from "shared/enums/toggle.enum";
import {useIsRtl} from "shared/hooks/useIsRtl";
import {calculateRemainingColumnsWidth} from "shared/utils/calculateRemainingColumnsWidth";

import {handleToggle} from "store/actions/layout/layout.actions";

import Rtl from "../../../../../../shared/rtl/Rtl";
import LinesNavigatorContainer from "../LinesNavigatorContainer";
import NonStitchedTranslationLinesResources from "../non-stitched-translation-lines/nonStitchedTranslationLines.resources";

import PlayersTranslationLinesResources from "./playersTranslationLines.resources";

const PlayersTranslationLinesContainerPropTypes = {
  selectedJob: PropTypes.object.isRequired,
  selectedContentBlock: PropTypes.object.isRequired,
  selectedStructure: PropTypes.object.isRequired,
  selectedLine: PropTypes.object.isRequired,
  linesTable: PropTypes.object.isRequired,
  refreshingStats: PropTypes.bool.isRequired,
  translationLinesCollapsed: PropTypes.bool.isRequired,
  handleToggleAction: PropTypes.func.isRequired,
};

const PlayersTranslationLinesContainer = (props) => {
  const tableContentRef = React.useRef(null);
  const rightToLeft = useIsRtl(props.selectedJob?.language?.id || 0);

  const formatRow = (row) => {
    const {lineId, rowId, assets = [], isModified, status} = row;
    const StatusBadge = <Badge medium text={LineStatusEnum[status].text} color={LineStatusEnum[status].color} />;
    const getTranslatableIcon = (isTranslatable) => (
      <Tooltip
        text={
          isTranslatable
            ? PlayersTranslationLinesResources.Translatable
            : PlayersTranslationLinesResources.noTranslatable
        }
      >
        <Icon
          icon={
            isTranslatable
              ? PlayersTranslationLinesResources.TranslatableIcon
              : PlayersTranslationLinesResources.NoTranslatableIcon
          }
        />
      </Tooltip>
    );
    const isActive = lineId === props.selectedLine.lineId;
    const assetRows = {};
    assets.forEach(({translatedText, phraseOrderId, isTranslatable}) => {
      assetRows[`phrase_${phraseOrderId}`] = (
        <Rtl elmId={`rtl-phrase-${phraseOrderId}`} languageId={selectedJob?.language?.id || 0}>
          {translatedText}
        </Rtl>
      );
      assetRows[`translatable_${phraseOrderId}`] = getTranslatableIcon(isTranslatable);
    });
    return {
      id: lineId,
      isSelected: isActive,
      className: classnames({"is-modified": isModified}),
      data: {
        isActive,
        lineId,
        rowId,
        isModified: PlayersTranslationLinesResources.IsModified(isModified),
        status: StatusBadge,
        ...assetRows,
      },
    };
  };

  const formatContentNavigatorProps = () => {
    const totalTableWidth = tableContentRef.current?.clientWidth;
    const {linesTable, selectedStructure, selectedLine} = props;
    const {rows, ...pageData} = linesTable;
    const orderedPhrases = orderPhrases(selectedStructure);
    const assetsColumns = orderedPhrases
      ? orderedPhrases.reduce((array, {name, phraseOrderId}) => {
          return [
            ...array,
            {
              name: "",
              rightToLeft,
              mapName: `translatable_${phraseOrderId}`,
              width: 40,
              sort: true,
            },
            {
              name,
              rightToLeft,
              mapName: `phrase_${phraseOrderId}`,
              filter: {type: "text", placeholder: PlayersTranslationLinesResources.FilterPlaceholder},
              sort: true,
            },
          ];
        }, [])
      : [];
    const formattedAssets = rightToLeft ? assetsColumns.reverse() : assetsColumns;
    const statusFilterData = Object.keys(LineStatusEnum).map((key) => {
      const {id: value, text} = LineStatusEnum[key];
      return {value, text};
    });
    const isModifiedValues = [
      {value: true, text: NonStitchedTranslationLinesResources.IsModified(true)},
      {value: false, text: NonStitchedTranslationLinesResources.IsModified(false)},
    ];
    const columns = [
      {name: "Line", mapName: "rowId", sort: true, width: 70},
      {
        name: "Modified",
        mapName: "isModified",
        sort: true,
        filter: {type: "select-single", data: isModifiedValues},
        width: 100,
      },
      {
        name: "Status",
        mapName: "status",
        className: "text-align-center",
        sort: true,
        filter: {type: "select-single", data: statusFilterData},
        width: 100,
      },
      ...formattedAssets,
    ];
    const finalColumns = totalTableWidth ? calculateRemainingColumnsWidth(columns, totalTableWidth) : columns;
    const controlsProps = {
      lineType: PlayersTranslationLinesResources.LinesType,
      currentLine: selectedLine.lineIndex,
      numberOfLines: pageData.totalRows,
      hasUntranslatedLines: pageData.hasUntranslatedLines,
    };
    const tableProps = {
      columns: finalColumns,
      pageData,
      rows: rows && rows.map(formatRow),
    };

    return {...controlsProps, ...tableProps};
  };

  const setTableContentRef = (ref) => {
    tableContentRef.current = ref;
  };

  const handleCollapseToggle = () => {
    props.handleToggleAction(ToggleEnum.TranslationLinesCollapsed);
  };

  const {
    translationLinesCollapsed,
    linesTable,
    refreshingStats,
    selectedJob,
    selectedContentBlock,
    selectedStructure,
    selectedLine,
  } = props;
  const title = PlayersTranslationLinesResources.TranslationLinesBoxTitle(selectedStructure);
  return (
    <LinesNavigatorContainer
      linesTable={linesTable}
      loading={refreshingStats}
      selectedJob={selectedJob}
      selectedContentBlock={selectedContentBlock}
      selectedStructure={selectedStructure}
      selectedLine={selectedLine}
      title={title}
      collapsed={translationLinesCollapsed}
      setTableContentRef={setTableContentRef}
      onCollapseToggle={handleCollapseToggle}
      {...formatContentNavigatorProps()}
    />
  );
};

PlayersTranslationLinesContainer.propTypes = PlayersTranslationLinesContainerPropTypes;

const mapStateToProps = (store) => {
  return {
    selectedJob: store.jobs.ltReviewWorkspace.selectedJob,
    selectedContentBlock: store.jobs.ltReviewWorkspace.selectedContentBlock,
    selectedStructure: store.jobs.ltReviewWorkspace.selectedStructure,
    selectedLine: store.jobs.ltReviewWorkspace.selectedLine,
    linesTable: store.jobs.ltReviewWorkspace.linesTable,
    refreshingStats: store.jobs.ltReviewWorkspace.refreshingStats,
    translationLinesCollapsed: store.layout.translationLinesCollapsed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleToggleAction: (key) => dispatch(handleToggle(key)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayersTranslationLinesContainer);
