import {useState} from "react";

import PropTypes from "prop-types";

import ContentNavigatorControls from "../components/ContentNavigatorControls";

const ContentNavigatorControlsContainerPropTypes = {
  elmId: PropTypes.string.isRequired,
  batchType: PropTypes.object.isRequired,
  lineType: PropTypes.string,
  currentLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  numberOfLines: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasUntranslatedLines: PropTypes.bool,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNextUntranslatedLine: PropTypes.func.isRequired,
  onGoTo: PropTypes.func.isRequired,
  keyboardShortcuts: PropTypes.object,
};

const ContentNavigatorControlsContainerDefaultProps = {
  lineType: "",
  currentLine: 0,
  numberOfLines: 0,
  hasUntranslatedLines: false,
  keyboardShortcuts: {},
};

const MAX_VALUE = 99999;

const ContentNavigatorControlsContainer = (props) => {
  const [goToValue, setGoToValue] = useState({value: "", disable: true});

  const handleGoToInputChange = (inputValue) => {
    if (/\d|^$/.test(inputValue) && Number(inputValue) <= MAX_VALUE) {
      setGoToValue({value: inputValue, disable: Number(inputValue) === 0});
    }
  };

  const handleGoTo = () => {
    props.onGoTo(goToValue.value);
  };

  return (
    <ContentNavigatorControls
      {...props}
      goToValue={goToValue.value}
      goToDisabled={goToValue.disable}
      onGoToInputChange={handleGoToInputChange}
      onGoTo={handleGoTo}
    />
  );
};

ContentNavigatorControlsContainer.propTypes = ContentNavigatorControlsContainerPropTypes;
ContentNavigatorControlsContainer.defaultProps = ContentNavigatorControlsContainerDefaultProps;

export default ContentNavigatorControlsContainer;
