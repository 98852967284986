import {
  PROJECTS_AVAILABLES_FETCH_START,
  PROJECTS_AVAILABLES_FETCH_SUCCESS,
  PROJECTS_AVAILABLES_FETCH_FAIL,
  VENDORS_FETCH_START,
  VENDORS_FETCH_SUCCESS,
  VENDORS_FETCH_FAIL,
} from "store/actions/jobs/job-selector/translation-job-selector/translationJobSelector.actions";

const initialState = {
  fetchProjectsAvailables: {
    loading: false,
    errors: null,
  },
  fetchVendors: {
    loading: false,
    errors: null,
  },
  projects: [],
  vendors: [],
};

const translationSelector = (state = initialState, action) => {
  switch (action.type) {
    case PROJECTS_AVAILABLES_FETCH_START:
      return {
        ...state,
        fetchProjectsAvailables: {
          loading: true,
          errors: null,
        },
      };
    case PROJECTS_AVAILABLES_FETCH_SUCCESS:
      return {
        ...state,
        projects: action.value,
        fetchProjectsAvailables: {
          loading: false,
          errors: null,
        },
      };
    case PROJECTS_AVAILABLES_FETCH_FAIL:
      return {
        ...state,
        fetchProjectsAvailables: {
          loading: false,
          errors: action.value,
        },
      };
    case VENDORS_FETCH_START:
      return {
        ...state,
        fetchVendors: {
          loading: true,
          errors: null,
        },
      };
    case VENDORS_FETCH_SUCCESS:
      return {
        ...state,
        vendors: action.value,
        fetchVendors: {
          loading: false,
          errors: null,
        },
      };
    case VENDORS_FETCH_FAIL:
      return {
        ...state,
        fetchVendors: {
          loading: false,
          errors: action.value,
        },
      };
    default:
      return state;
  }
};

export default translationSelector;
