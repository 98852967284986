import {lazy} from "react";

import {AuthenticatedTemplate} from "@azure/msal-react";
import {Route, Routes} from "react-router-dom";
import {RoutesEnum} from "router/routes/routes.enum";
import {Loading} from "ui_toolkit";

import {useHasPermissions} from "app/useHasPermissions";

const HomeContainer = lazy(() => import("features/home/HomeContainer"));

export const AppLayout = () => {
  const hasPermissions = useHasPermissions();

  if (!hasPermissions) {
    return <Loading large overlay visible />;
  }
  return (
    <AuthenticatedTemplate>
      <Routes>
        <Route path={`${RoutesEnum.Base}*`} element={<HomeContainer />} />
      </Routes>
    </AuthenticatedTemplate>
  );
};
