export const USER_ACTIONS_FETCH = "USER_ACTIONS_FETCH";
export const USER_ACTIONS_FETCH_START = "USER_ACTIONS_FETCH_START";
export const USER_ACTIONS_FETCH_SUCCESS = "USER_ACTIONS_FETCH_SUCCESS";
export const USER_ACTIONS_FETCH_FAIL = "USER_ACTIONS_FETCH_FAIL";
export const CURRENT_USER_INFO_FETCH = "CURRENT_USER_INFO_FETCH";
export const CURRENT_USER_INFO_FETCH_START = "CURRENT_USER_INFO_FETCH_START";
export const CURRENT_USER_INFO_FETCH_SUCCESS = "CURRENT_USER_INFO_FETCH_SUCCESS";
export const CURRENT_USER_INFO_FETCH_FAIL = "CURRENT_USER_INFO_FETCH_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";

export const fetchUserActions = () => {
  return {
    type: USER_ACTIONS_FETCH,
  };
};

export const fetchCurrentUserInfo = () => {
  return {
    type: CURRENT_USER_INFO_FETCH,
  };
};

export const logOut = ({refreshTokenKey, navigateToLogin}) => {
  return {
    type: USER_LOGOUT,
    refreshTokenKey,
    navigateToLogin,
  };
};
