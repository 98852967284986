import "./content-info.css";

import PropTypes from "prop-types";
import {Field, Textarea} from "ui_toolkit";

const ContextInfoPropTypes = {
  contextDescriptionTitle: PropTypes.string.isRequired,
  description: PropTypes.string,
  notesTitle: PropTypes.string.isRequired,
  notes: PropTypes.string,
};

const ContextInfoDefaultProps = {
  description: "",
  notes: "",
};

const ContextInfo = ({contextDescriptionTitle, description, notesTitle, notes}) => {
  return (
    <div className="content-info flex-container flex-nowrap white-space-nowrap">
      <Field className="flex-grow-1" text={contextDescriptionTitle}>
        {description}
      </Field>
      {notes && (
        <>
          <div className="separator" />
          <Field className="flex-basis-20" text={notesTitle}>
            <Textarea disabled value={notes} placeholder="Notes" />
          </Field>
        </>
      )}
    </div>
  );
};

ContextInfo.propTypes = ContextInfoPropTypes;
ContextInfo.defaultProps = ContextInfoDefaultProps;

export default ContextInfo;
