/* eslint-disable no-console, prefer-rest-params, no-inner-declarations */
import "styles/app.css";

import {lazy, Suspense, useMemo} from "react";

import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {RoutesEnum} from "router/routes/routes.enum";
import {Loading, useLocalStorage} from "ui_toolkit";

import {LocalStorageEnum} from "shared/enums/localStorage.enum";
import {LoginTypeEnum} from "shared/enums/loginType.enum";

import {AppLayout} from "app/AppLayout";

import {tokenIsValid} from "src/shared/helpers/tokens.helper";

import Layout from "./Layout";

const HomeContainer = lazy(() => import("features/home/HomeContainer"));
const LoginContainer = lazy(() => import("features/login/LoginContainer"));
const UnhandledException = lazy(() => import("features/UnhandledException"));

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

const loadAnalyticsScript = (analyticsScriptId) => {
  try {
    const idForScriptTag = "googleAnalytics";
    if (document.querySelectorAll(`[id='${idForScriptTag}']`).length === 0) {
      const script = document.createElement("script");
      script.id = idForScriptTag;
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsScriptId}`;
      document.body.appendChild(script);
    }

    window.dataLayer = window.dataLayer || [];
    // noinspection JSAnnotator
    // @ts-expect-error google analytics fn
    function gtag() {
      // @ts-expect-error datalayer
      dataLayer.push(arguments);
    }
    // @ts-expect-error google analytics fn
    gtag("js", new Date());
    // @ts-expect-error google analytics fn
    gtag("config", analyticsScriptId);
  } catch (error) {
    console.error(`%cError in Analytics function: ${error}`, "background: red; color: yellow;");
  }
};

const App = () => {
  const location = useLocation();
  const [loginType] = useLocalStorage(LocalStorageEnum.LOGIN_TYPE, LoginTypeEnum.Regular);
  if (process.env.APP_GA_ID) {
    loadAnalyticsScript(process.env.APP_GA_ID);
  }

  const PrivateApp = useMemo(
    () => () => {
      if (tokenIsValid()) return <HomeContainer />;
      if (loginType === LoginTypeEnum.Msal || location.pathname === RoutesEnum.LoginSSO) return <AppLayout />;
      return <Navigate to={RoutesEnum.Login} />;
    },
    [location.pathname, loginType],
  );

  return (
    <Suspense fallback={<Loading large visible />}>
      <Layout key="layout" />
      <Routes key="routes">
        <Route path={`${RoutesEnum.Login}`} element={<LoginContainer />} />
        <Route path={RoutesEnum.Error.UnhandledException} element={<UnhandledException />} />
        <Route path={`${RoutesEnum.Base}*`} element={<PrivateApp />} />
      </Routes>
    </Suspense>
  );
};

export default App;
