export const LT_REVIEW_LOADING_START = "LT_REVIEW_LOADING_START";
export const LT_REVIEW_ACTION_SUCCESS = "LT_REVIEW_ACTION_SUCCESS";
export const LT_REVIEW_ACTION_FAIL = "LT_REVIEW_ACTION_FAIL";
export const LT_REVIEW_CLEAR_JOB_DATA = "LT_REVIEW_CLEAR_JOB_DATA";
export const LT_REVIEW_JOB_FETCH = "LT_REVIEW_JOB_FETCH";

export const LT_REVIEW_STATS_FETCH = "LT_REVIEW_STATS_FETCH";
export const LT_REVIEW_STATS_SET = "LT_REVIEW_STATS_SET";
export const LT_REVIEW_STATS_START = "LT_REVIEW_STATS_START";
export const LT_REVIEW_STATS_FINISH = "LT_REVIEW_STATS_FINISH";

export const LT_REVIEW_CONTENT_BLOCKS_FETCH = "LT_REVIEW_CONTENT_BLOCKS_FETCH";
export const LT_REVIEW_CONTENT_BLOCK_SELECT = "LT_REVIEW_CONTENT_BLOCK_SELECT";

export const LT_REVIEW_JOB_DELIVER = "LT_REVIEW_JOB_DELIVER";
export const LT_REVIEW_JOB_DELIVER_START = "LT_REVIEW_JOB_DELIVER_START";
export const LT_REVIEW_JOB_DELIVER_IN_PROGRESS = "LT_REVIEW_JOB_DELIVER_IN_PROGRESS";
export const LT_REVIEW_JOB_DELIVER_CANCEL = "LT_REVIEW_JOB_DELIVER_CANCEL";

export const LT_REVIEW_CLEAR_WORKSPACE = "LT_REVIEW_CLEAR_WORKSPACE";

export const LT_REVIEW_SELECTED_CONTENT_GROUP_INFO_UPDATE = "LT_REVIEW_SELECTED_CONTENT_GROUP_INFO_UPDATE";

export const fetchLtReviewJob = (requestData) => {
  return {
    type: LT_REVIEW_JOB_FETCH,
    value: requestData,
  };
};

export const fetchLtReviewContentBlocks = (requestData) => {
  return {
    type: LT_REVIEW_CONTENT_BLOCKS_FETCH,
    value: requestData,
  };
};

export const selectLtReviewContentBlock = (requestData) => {
  return {
    type: LT_REVIEW_CONTENT_BLOCK_SELECT,
    value: requestData,
  };
};

export const cancelDeliverLtReviewJob = () => {
  return {
    type: LT_REVIEW_JOB_DELIVER_CANCEL,
  };
};

export const startDeliverLtReviewJob = () => {
  return {
    type: LT_REVIEW_JOB_DELIVER_START,
  };
};

export const clearLtReviewWorkspace = () => {
  return {
    type: LT_REVIEW_CLEAR_WORKSPACE,
  };
};
