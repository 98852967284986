export const FETCH_JOB_DATA_START = "FETCH_JOB_DATA_START";
export const FETCH_JOB_DATA_SUCCESS = "FETCH_JOB_DATA_SUCCESS";
export const FETCH_JOB_DATA_FAIL = "FETCH_JOB_DATA_FAIL";

export const fetchExternalJobData = (value) => {
  return {
    type: FETCH_JOB_DATA_START,
    value,
  };
};
