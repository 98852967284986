import PropTypes from "prop-types";
import {Table} from "ui_toolkit";

const ContentNavigatorTablePropTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  pageData: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  setTableContentRef: PropTypes.func,
  fixedWidth: PropTypes.bool,
};

const ContentNavigatorTableDefaultProps = {
  rows: [],
  columns: [],
  pageData: {},
  loading: false,
  setTableContentRef: null,
  fixedWidth: false,
};

const ContentNavigatorTable = (props) => (
  <Table
    selectableRows
    fixedWidth={props.fixedWidth}
    setTableContentRef={props.setTableContentRef}
    loadingVisible={props.loading}
    {...props}
  />
);

ContentNavigatorTable.propTypes = ContentNavigatorTablePropTypes;
ContentNavigatorTable.defaultProps = ContentNavigatorTableDefaultProps;

export default ContentNavigatorTable;
