import {useSelector} from "react-redux";
import {Table} from "ui_toolkit";

import {LocalStorageEnum} from "shared/enums/localStorage.enum";

const TableContainer = (props) => {
  const {RowsPerPage, DefaultNumberRowPerPage} = useSelector((store) => store.master.projectSettings);
  const handleRowsPerPageChanged = (page) => localStorage.setItem(LocalStorageEnum.DEFAULT_NUMBER_ROW_PER_PAGE, page);

  return (
    <Table
      {...props}
      defaultNumberRowPerPage={DefaultNumberRowPerPage.value}
      rowsPerPage={RowsPerPage.value}
      onRowsPerPageChanged={handleRowsPerPageChanged}
    />
  );
};

export default TableContainer;
