import "./content-block.css";

import PropTypes from "prop-types";
import {Field, Textarea} from "ui_toolkit";

import ContentBlockStats from "../content-block-stats/ContentBlockStats";
import ContentBlockTable from "../content-block-table/ContentBlockTable";

import ContentBlockResources from "./contentBlock.resources";

const ContentBlockPropTypes = {
  elmId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  informationTitle: PropTypes.string.isRequired,
  structureLabel: PropTypes.string.isRequired,
  deliverButtonText: PropTypes.string.isRequired,
  selectedJob: PropTypes.object.isRequired,
  selectedContentBlock: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  onDeliverJob: PropTypes.func.isRequired,
  onFetchContentBlocks: PropTypes.func.isRequired,
  onSelectContentBlock: PropTypes.func.isRequired,
};

const ContentBlockDefaultProps = {};

const ContentBlock = ({
  elmId,
  title,
  informationTitle,
  structureLabel,
  deliverButtonText,
  selectedJob,
  stats,
  rows,
  columns,
  selectedContentBlock,
  loading,
  onFetchContentBlocks,
  onSelectContentBlock,
  onDeliverJob,
}) => {
  const handleChange = (tableState) => {
    onFetchContentBlocks({jobId: selectedJob.id, tableState});
  };
  const handleSelectContentBlock = (contentBlock) => {
    onSelectContentBlock({jobId: selectedJob.id, selectedContentBlock: contentBlock});
  };

  return (
    <div className="flex-container content-block">
      <Field text={title} className="flex-grow-1 height-100">
        {loading && (
          <div className="position-absolute">
            <span className="refresh-icon far fa-spinner-third fa-spin" />
            {ContentBlockResources.refreshingMessage}
          </div>
        )}
        <ContentBlockTable
          elmId={`${elmId}-table`}
          columns={columns}
          rows={rows}
          onChange={handleChange}
          onLinkClick={handleSelectContentBlock}
        />
        <ContentBlockStats
          elmId={`${elmId}-stats`}
          stats={stats}
          structureLabel={structureLabel}
          deliverButtonText={deliverButtonText}
          isReadonlyJob={selectedJob.isReadonly}
          onDeliverJob={onDeliverJob}
        />
      </Field>
      <div className="separator" />
      <Field text={informationTitle} className="flex-grow-1">
        <Textarea disabled elmId={`${elmId}-textarea`} value={selectedContentBlock.contentGroupInformation || ""} />
      </Field>
    </div>
  );
};

ContentBlock.propTypes = ContentBlockPropTypes;
ContentBlock.defaultProps = ContentBlockDefaultProps;

export default ContentBlock;
