import "./phrase-card.css";

import {useEffect} from "react";

import classnames from "classnames";
import PropTypes from "prop-types";
import {Textarea, Field, Checkbox, Tooltip, useLocalStorage, useResizeObserver, Select} from "ui_toolkit";

import {JobsTopologyMapEnum} from "shared/enums/job.enum";

import locale from "src/locale/en-us";
import {VariantEnum, VariantTypes} from "src/shared/enums/variant.enum";

const GenderOptions = [
  VariantTypes[VariantEnum.Neutral],
  VariantTypes[VariantEnum.Male],
  VariantTypes[VariantEnum.Female],
];

const PhraseCardPropTypes = {
  rightToLeft: PropTypes.bool.isRequired,
  isReadonlyJob: PropTypes.bool,
  activeAsset: PropTypes.object,
  parametersColorMap: PropTypes.object,
  batchTopology: PropTypes.number,
  asset: PropTypes.object,
  onCheckboxChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onGenderChange: PropTypes.func.isRequired,
};

const PhraseCardDefaultProps = {
  isReadonlyJob: true,
  parametersColorMap: {},
  asset: {},
  activeAsset: {},
  batchTopology: 0,
};

const renderParameterIcon = (parameter, parametersColorsMap) => {
  const color = parametersColorsMap[parameter.name];
  return (
    <Tooltip key={parameter.id} text={parameter.name}>
      <i className={classnames("parameter-bullet", color)} />
    </Tooltip>
  );
};

const PhraseCard = ({
  asset,
  rightToLeft,
  parametersColorMap,
  isReadonlyJob,
  activeAsset,
  batchTopology,
  onCheckboxChange,
  onTextChange,
  onFocus,
  onGenderChange,
}) => {
  const {
    midWord,
    assetId,
    isTranslatable,
    bankName,
    parameters,
    genderId,
    translatedText,
    character,
    isEmptyString,
    notAvailable,
    sampleId,
  } = asset;
  const value = notAvailable ? "NOT AVAILABLE" : translatedText;
  const disabled = isReadonlyJob || !isTranslatable || notAvailable;
  const isActive = assetId === activeAsset.assetId;
  const colorClasses = {
    male: genderId === VariantEnum.Male,
    female: genderId === VariantEnum.Female,
    translatable: isTranslatable,
  };

  const [textareaSize, setTextareaSize] = useLocalStorage("phraseCardTextAreaSize", {height: "auto", width: "auto"});

  const {node, setNode, size} = useResizeObserver(true, 400);

  useEffect(() => {
    if (node) {
      const currentHeight = node.style.height;
      const currentWidth = node.style.width;
      const cacheWidth = `${textareaSize.width}px`;
      const cacheHeight = `${textareaSize.height}px`;
      if (cacheWidth !== currentWidth && cacheHeight !== currentHeight) {
        node.style.height = cacheHeight;
        node.style.width = cacheWidth;
      }
    }
  }, [node, textareaSize.width, textareaSize.height]);

  useEffect(() => {
    const {contentRect} = size;
    const {height: textareaHeight, width: textareaWidth} = contentRect || {};
    const hasChangedHeight = !!textareaHeight && textareaHeight !== textareaSize.height;
    const hasChangedWidth = !!textareaWidth && textareaWidth !== textareaSize.width;
    if (hasChangedHeight && hasChangedWidth) {
      setTextareaSize({height: textareaHeight, width: textareaWidth});
    }
  }, [setTextareaSize, size, textareaSize.height, textareaSize.width]);

  const isStitched = batchTopology === JobsTopologyMapEnum.Stitched.id;

  return (
    <>
      {rightToLeft && isStitched && (
        <span className="p-sm box-mid-word rtl" dir="rtl">
          {midWord || "MIDWORD"}
        </span>
      )}
      <div className={classnames("phrase-card", "m-b-sm", {disabled, active: isActive})}>
        <div className={classnames("phrase-card-header", colorClasses)}>
          <p dir="auto">{bankName}</p>
          <p dir="auto" className="m-t-xs">
            SAMPLE ID: {sampleId}
          </p>
        </div>
        <div className={classnames("p-sm", "phrase-card-body", colorClasses)}>
          <div className="flex-container p-b-sm p-t-sm">
            <div className="flex-container">
              <strong className="m-r-sm">Parameters: </strong>
              <div className="parameters-container">
                {parameters?.map((parameter) => renderParameterIcon(parameter, parametersColorMap))}
              </div>
            </div>
            {!isTranslatable && <p className="no-translatable">Non translatable field</p>}
          </div>
          <div className="phrase-card-area box-module p-t-sm">
            <div className="flex-container flex-basis-100">
              <Field text="Translation Area" />
              <Checkbox
                className="push-right"
                label="Empty String"
                elmId="adlib-phrase-card-empty-string-check"
                checked={isEmptyString}
                disabled={disabled}
                onChange={() => onCheckboxChange({assetId, translatedText}, !isEmptyString)}
              />
            </div>
            <Textarea
              elmId="adlib-translation-job-area-phrase-card-area"
              disabled={disabled || isEmptyString}
              value={value}
              rtl={rightToLeft}
              placeholder="Enter translation here"
              rows={6}
              textAreaRef={setNode}
              onChange={(newTranslatedText) =>
                onTextChange({assetId, isEmptyString, translatedText: newTranslatedText})
              }
              onFocus={() => onFocus({assetId})}
            />
          </div>
          <div className="flex-container justify-content-flex-start gap-md p-t-sm">
            <Field inline elmId="adlib-translation-job-area-phrase-card-area-character" text={locale.Character}>
              {!!character > 0 ? character : "-"}
            </Field>
            <Field
              inline
              elmId="adlib-translation-job-area-phrase-card-area-gender"
              className="flex-grow-1"
              text={locale.Gender}
            >
              <Select
                inline
                elmId={`phase-card-${asset.id}-gender`}
                value={VariantTypes[genderId]}
                options={GenderOptions}
                isClearable={false}
                isDisabled={disabled}
                onChange={({value: gender}) => onGenderChange(assetId, gender)}
              />
            </Field>
          </div>
        </div>
      </div>
      {!rightToLeft && isStitched && <span className="p-sm box-mid-word">{midWord || "MIDWORD"}</span>}
    </>
  );
};

PhraseCard.propTypes = PhraseCardPropTypes;
PhraseCard.defaultProps = PhraseCardDefaultProps;

export default PhraseCard;
