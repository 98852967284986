import {MsalProvider} from "@azure/msal-react";
import {createRoot} from "react-dom/client";
import {QueryClientProvider} from "react-query";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";

import {msalInstance} from "../configs/auth.config";
import {queryClient} from "../configs/react-query.config";

import App from "./app/App";
import configureStore from "./store/store";

const store = configureStore();
const container = document.getElementById("app");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  </MsalProvider>,
);

// @ts-expect-error webpack module
if (module.hot) {
  // @ts-expect-error webpack module
  module.hot.accept();
}
