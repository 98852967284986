import TranslationAreaContainer from "features/jobs/job-workspace/containers/translation-job-workspace/content-area/ContentAreaContainer";
import NonStitchedContentBlockContainer from "features/jobs/job-workspace/containers/translation-job-workspace/content-block/non-stitched-content-block/NonStitchedContentBlockContainer";
import PlayersContentBlockContainer from "features/jobs/job-workspace/containers/translation-job-workspace/content-block/players-content-block/PlayersContentBlockContainer";
import StitchedContentBlockContainer from "features/jobs/job-workspace/containers/translation-job-workspace/content-block/stitched-content-block/StitchedContentBlockContainer";
import NonStitchedTranslationLinesContainer from "features/jobs/job-workspace/containers/translation-job-workspace/content-lines/non-stitched-translation-lines/NonStitchedTranslationLinesContainer";
import PlayersTranslationLinesContainer from "features/jobs/job-workspace/containers/translation-job-workspace/content-lines/players-translation-lines/PlayersTranslationLinesContainer";
import StitchedTranslationLinesContainer from "features/jobs/job-workspace/containers/translation-job-workspace/content-lines/stitched-translation-lines/StitchedTranslationLinesContainer";
import NonStitchedTranslationStructuresContainer from "features/jobs/job-workspace/containers/translation-job-workspace/content-structures/non-stitched-translation-structures/NonStitchedTranslationStructuresContainer";
import PlayersTranslationStructuresContainer from "features/jobs/job-workspace/containers/translation-job-workspace/content-structures/players-translation-structures/PlayersTranslationStructuresContainer";
import StitchedTranslationStructuresContainer from "features/jobs/job-workspace/containers/translation-job-workspace/content-structures/stitched-translation-structures/StitchedTranslationStructuresContainer";

import {JobTopologyEnum} from "shared/enums/job.enum";

const containersMap = {
  NonStitched: [
    {
      id: "ContentBlockContainer",
      container: NonStitchedContentBlockContainer,
    },
    {
      id: "TranslationStructuresContainer",
      container: NonStitchedTranslationStructuresContainer,
    },
    {
      id: "TranslationAreaContainer",
      container: TranslationAreaContainer,
    },
    {
      id: "TranslationLinesContainer",
      container: NonStitchedTranslationLinesContainer,
    },
  ],
  PlayerNames: [
    {
      id: "ContentBlockContainer",
      container: PlayersContentBlockContainer,
    },
    {
      id: "TranslationStructuresContainer",
      container: PlayersTranslationStructuresContainer,
    },
    {
      id: "TranslationAreaContainer",
      container: TranslationAreaContainer,
    },
    {
      id: "TranslationLinesContainer",
      container: PlayersTranslationLinesContainer,
    },
  ],
  Stitched: [
    {
      id: "ContentBlockContainer",
      container: StitchedContentBlockContainer,
    },
    {
      id: "TranslationStructuresContainer",
      container: StitchedTranslationStructuresContainer,
    },
    {
      id: "TranslationAreaContainer",
      container: TranslationAreaContainer,
    },
    {
      id: "TranslationLinesContainer",
      container: StitchedTranslationLinesContainer,
    },
  ],
};

export const getTranslationWorkspaceContainers = (topologyId) => {
  return containersMap[JobTopologyEnum[topologyId].text];
};
