import type {$TSFixme} from "src/types/TSFixme";

import {Service} from "../../service";
import {buildJobsTableRequestData, buildContentBlockTableRequestData} from "../shared/job.service.helper";

import LtReviewJobLinesService from "./ltReviewJobLines.service";
import LtReviewJobStructuresService from "./ltReviewJobStructures.service";

const LtReviewService = {
  fetchJobsTableData: (tableState) => {
    const requestFiltersData = buildJobsTableRequestData(tableState);
    return Service.get({
      url: "reviews",
      params: requestFiltersData,
    });
  },

  fetchJob: (jobId) =>
    Service.get({
      url: `reviews/${jobId}/summary`,
    }),

  fetchContentBlocks: ({jobId, tableState = {} as $TSFixme}) => {
    const requestFiltersData = buildContentBlockTableRequestData(tableState);
    const url = `reviews/${jobId}/contentgroups`;

    return Service.get({
      url,
      params: requestFiltersData,
    });
  },

  fetchStats: (jobId) =>
    Service.get({
      url: `reviews/${jobId}/stats`,
    }),

  fetchStatsAfterUpdate: ({jobId, contentGroupId, structureId}) =>
    Service.get({
      url: `reviews/${jobId}/contentgroup/${contentGroupId}/structure/${structureId}/stats`,
    }),

  deliverJob: (reviewId) => {
    return Service.post({
      url: `reviews/${reviewId}/delivery`,
      data: true,
    });
  },

  reopenJob: (reviewId) =>
    Service.put({
      url: `reviews/${reviewId}/reopen`,
      data: true,
    }),
  cancelJob: (reviewId) =>
    Service.put({
      url: `reviews/${reviewId}/cancel`,
      data: true,
    }),

  enableJob: (reviewId) =>
    Service.put({
      url: `reviews/${reviewId}/enable`,
      data: true,
    }),
  bulkDeliverJobs: (searchId) =>
    Service.post({
      url: "reviews/delivery",
      data: {searchId},
    }),
  bulkCancelJobs: (searchId) =>
    Service.put({
      url: "reviews/cancel",
      data: {searchId},
    }),
  bulkEnableJobs: (searchId) =>
    Service.put({
      url: "reviews/enable",
      data: {searchId},
    }),
};

export default {...LtReviewService, ...LtReviewJobStructuresService, ...LtReviewJobLinesService};
