import "./content-navigator.css";

import PropTypes from "prop-types";
import {Textarea, Field, Box, Tooltip, Title} from "ui_toolkit";

import ContentNavigatorResources from "./contentNavigator.resources";
import ContentNavigatorControlsContainer from "./controls/containers/ContentNavigatorControlsContainer";
import ContentNavigatorMessage from "./message/ContentNavigatorMessage";
import ContentNavigatorTable from "./table/ContentNavigatorTable";

const ContentNavigatorPropTypes = {
  elmId: PropTypes.string.isRequired,
  batchType: PropTypes.object.isRequired,
  messageText: PropTypes.string,
  messageIcon: PropTypes.string,
  messageColor: PropTypes.string,
  lineType: PropTypes.string,
  currentLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  numberOfLines: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasUntranslatedLines: PropTypes.bool,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNextUntranslatedLine: PropTypes.func.isRequired,
  onGoTo: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  pageData: PropTypes.object,
  loading: PropTypes.bool,
  onTableChange: PropTypes.func.isRequired,
  onTableRowSelect: PropTypes.func.isRequired,
  keyboardShortcuts: PropTypes.object,
  title: PropTypes.string.isRequired,
  collapsed: PropTypes.bool,
  onCollapseToggle: PropTypes.func,
  stitchedStructureDescription: PropTypes.shape({
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  setTableContentRef: PropTypes.func,
  fixedWidth: PropTypes.bool,
};

const ContentNavigatorDefaultProps = {
  elmId: "content-navigator",
  messageText: "",
  messageIcon: "",
  messageColor: "",
  lineType: "",
  currentLine: 0,
  numberOfLines: 0,
  hasUntranslatedLines: false,
  rows: [],
  columns: [],
  pageData: {},
  loading: false,
  keyboardShortcuts: {},
  title: "",
  collapsed: false,
  onCollapseToggle: () => null,
  stitchedStructureDescription: null,
  setTableContentRef: null,
  fixedWidth: false,
};

const ContentNavigator = ({
  elmId,
  batchType,
  messageText,
  messageIcon,
  messageColor,
  lineType,
  currentLine,
  numberOfLines,
  hasUntranslatedLines,
  rows,
  columns,
  pageData,
  loading,
  onTableChange,
  onTableRowSelect,
  onNext,
  onPrevious,
  onNextUntranslatedLine,
  onGoTo,
  keyboardShortcuts,
  title,
  collapsed,
  onCollapseToggle,
  stitchedStructureDescription,
  setTableContentRef,
  fixedWidth,
}) => {
  const renderTitleWithControls = () => (
    <div className="flex-container width-95 content-navigator-box-header">
      <Tooltip text={title}>
        <Title h4 className="box-title width-100" text={title} elmId={`${elmId}-box-title`} />
      </Tooltip>
      <ContentNavigatorControlsContainer
        elmId={elmId}
        batchType={batchType}
        lineType={lineType}
        currentLine={currentLine}
        numberOfLines={numberOfLines}
        hasUntranslatedLines={hasUntranslatedLines}
        keyboardShortcuts={keyboardShortcuts}
        onGoTo={onGoTo}
        onNextUntranslatedLine={onNextUntranslatedLine}
        onPrevious={onPrevious}
        onNext={onNext}
      />
    </div>
  );

  return (
    <div className="content-navigator">
      <Box collapsible title={renderTitleWithControls()} collapsed={collapsed} onCollapseToggle={onCollapseToggle}>
        <div>
          {loading && (
            <div className="position-absolute">
              <span className="refresh-icon far fa-spinner-third fa-spin" />
              {ContentNavigatorResources.refreshingMessage}
            </div>
          )}
          <div className="content-navigator-header">
            {messageText && <ContentNavigatorMessage message={messageText} icon={messageIcon} color={messageColor} />}
          </div>
          <ContentNavigatorTable
            fixedWidth={fixedWidth}
            elmId={elmId}
            rows={rows}
            columns={columns}
            pageData={pageData}
            setTableContentRef={setTableContentRef}
            onChange={onTableChange}
            onRowSelected={onTableRowSelect}
          />
        </div>
        {stitchedStructureDescription && (
          <Field text={stitchedStructureDescription.title} className="m-t-lg">
            <Textarea disabled value={stitchedStructureDescription.description} />
          </Field>
        )}
      </Box>
    </div>
  );
};

ContentNavigator.propTypes = ContentNavigatorPropTypes;
ContentNavigator.defaultProps = ContentNavigatorDefaultProps;

export default ContentNavigator;
