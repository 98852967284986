import {Service} from "../../service";
import {buildLinesTableRequestData} from "../shared/job.service.helper";

const TranslationJobLinesService = {
  fetchLines: ({jobId, contentGroupId, structureId, selectedRow = null, tableState = {}}) => {
    const requestFilters = buildLinesTableRequestData(tableState);
    const url = `jobs/${jobId}/contentgroup/${contentGroupId}/structure/${structureId}/lines`;

    return Service.get({
      url: `${url}`,
      params: {...requestFilters, selectedRow},
    });
  },

  nextUntranslatedLine: ({jobId, contentGroupId, structureId, selectedRow, tableState = {}}) => {
    const requestFilters = buildLinesTableRequestData(tableState);
    const url = `jobs/${jobId}/contentgroup/${contentGroupId}/structure/${structureId}/untranslated`;

    return Service.get({
      url: `${url}`,
      params: {...requestFilters, selectedRow},
    });
  },

  goToLinePageById: ({jobId, contentGroupId, structureId, rowId, tableState = {}}) => {
    const requestFilters = buildLinesTableRequestData(tableState);
    const url = `jobs/${jobId}/contentgroup/${contentGroupId}/structure/${structureId}/position`;

    return Service.get({
      url,
      params: {...requestFilters, searchRowId: rowId},
    });
  },

  saveLine: ({jobId, contentGroupId, structureId, lineId, asset}) =>
    Service.patch({
      url: `jobs/${jobId}/contentgroup/${contentGroupId}/structure/${structureId}/lines/${lineId}/asset/${asset.id}`,
      data: {asset},
    }),
};

export default TranslationJobLinesService;
