import {combineReducers} from "redux";

import externalJobReducer from "./external-job/externalJob.reducer";
import ltReviewJobSelectorReducer from "./job-selector/lt-review-job-selector/ltReviewJobSelector.reducer";
import translationJobSelectorReducer from "./job-selector/translation-job-selector/translationJobSelector.reducer";
import ltReviewWorkspaceReducer from "./job-workspace/lt-review-job-workspace/ltReviewJobWorkspace.reducer";
import translationJobWorkspaceReducer from "./job-workspace/translation-job-workspace/translationJobWorkspace.reducer";

const jobReducers = combineReducers({
  translationSelector: translationJobSelectorReducer,
  ltReviewSelector: ltReviewJobSelectorReducer,
  translationWorkspace: translationJobWorkspaceReducer,
  ltReviewWorkspace: ltReviewWorkspaceReducer,
  externalJob: externalJobReducer,
});

export default jobReducers;
