import PropTypes from "prop-types";
import {Icon} from "ui_toolkit";

const ContentNavigatorMessagePropTypes = {
  message: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
};
const ContentNavigatorMessageDefaultProps = {
  message: "",
  icon: "fa-exclamation-triangle",
  color: "bg-color-danger",
};

const ContentNavigatorMessage = ({message, icon, color}) => [
  <Icon key="icon" className={color} icon={icon} />,
  <span key="message" className={color}>
    {message}
  </span>,
];

ContentNavigatorMessage.propTypes = ContentNavigatorMessagePropTypes;
ContentNavigatorMessage.defaultProps = ContentNavigatorMessageDefaultProps;

export default ContentNavigatorMessage;
