/* eslint sort-keys: ['error', 'asc', {caseSensitive: false}] */

const locale = {
  Character: "Character",
  CopyrightElectronicArtsInc: `© ${new Date().getFullYear()} Electronic Arts Inc.`,
  Female: "Female",
  Gender: "Gender",
  Male: "Male",
  Neutral: "Neutral",
  Password: "Password",
  PleaseContactTheAdministrators: "Please contact the administrators. It will be fixed as soon as possible",
  SignIn: "Sign In",
  SignInWithSSO: "Sign In with SSO",
  SomethingWentWrong: "Something went wrong",
  SorryForTheInconveniences: "Sorry for the inconveniences",
  TheEagleTeam: "The EAgle Team",
  ThisPortalIsAuthorizedForEaEmployeesAndContractors:
    "This portal is authorized for EA employees & approved contractors only.",
  User: "User Name",
  UserNameLowercase: "user name",
};

export default locale;
