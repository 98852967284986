export const RoutesEnum = {
  Base: "/",
  Login: "/login",
  LoginSSO: "/login/sso",
  Error: {
    Base: "/error",
    ContentForbidden: "/error/content-forbidden",
    ContentNotFound: "/error/content-not-found",
    UnhandledException: "/error/unhandled-exception",
  },
  Jobs: {
    Base: "/jobs",
    Translation: "translations",
    LtReview: "reviews",
    TranslationSpecificJob: "translations/:jobId",
    LtReviewSpecificJob: "reviews/:jobId",
  },
  Redirection: {
    ExternalLtReview: "/jobs/reviews/loccms/:projectId/:reviewLanguageId",
  },
} as const;
