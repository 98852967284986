import {combineReducers} from "redux";

import type {$TSFixme} from "src/types/TSFixme";

import jobsReducers from "./jobs/jobs.reducers";
import layout from "./layout/layout.reducer";
import loginReducers from "./login/login.reducers";
import master from "./master/master.reducer";
import toaster from "./toaster/toaster.reducer";
import user from "./users/user.reducer";

const commonReducers = {layout, master, toaster, user};

const appReducers = combineReducers({
  tokens: loginReducers,
  jobs: jobsReducers,
  ...commonReducers,
});

export default appReducers;

export interface Store {
  tokens: $TSFixme;
  jobs: $TSFixme;
  layout: $TSFixme;
  master: $TSFixme;
  toaster: $TSFixme;
  user: $TSFixme;
}
