const createMapFromObject = (obj) =>
  Object.values(obj).reduce((acc, current) => {
    return {...acc, [current.text]: current};
  }, {});

export const JobTopologyEnum = {
  2: {id: 2, text: "Stitched", color: "black-color"},
  3: {id: 3, text: "NonStitched", color: "black-color"},
  4: {id: 4, text: "PlayerNames", color: "black-color"},
};

export const JobsTopologyMapEnum = createMapFromObject(JobTopologyEnum);

export const JobStatusEnum = {
  1: {id: 1, text: "Not Assigned", icon: "fa-dot-circle", color: "color-info", tooltip: "Not Assigned"},
  2: {id: 2, text: "Not Started", icon: "fa-dot-circle", color: "color-basic", tooltip: "Not Started"},
  3: {id: 3, text: "In Progress", icon: "fa-clock", color: "color-warning", tooltip: "In Progress"},
  4: {id: 4, text: "Completed", icon: "fa-check", color: "color-success", tooltip: "Completed"},
  5: {id: 5, text: "Inactive", icon: "fa-ban", color: "color-danger", tooltip: "Inactive"},
  6: {id: 6, text: "Cancelled", icon: "fa-ban", color: "color-danger", tooltip: "Cancelled"},
  7: {id: 7, text: "Archived", icon: "fa-archive", color: "color-warning", tooltip: "Archived"},
};

export const JobDeadlineStatusEnum = {
  1: {id: 1, text: "On Track", icon: "fa-info-circle", color: "color-success"},
  2: {id: 2, text: "Close to Deadline", icon: "fa-exclamation-triangle", color: "color-warning"},
  3: {id: 3, text: "Outdated", icon: "fa-exclamation-triangle", color: "color-danger"},
};
