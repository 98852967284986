import {useRef} from "react";

import type {$TSFixMe} from "@loc-engineering/ui-toolkit";
import {useQuery} from "react-query";
import {useDispatch} from "react-redux";

import {createErrorToast} from "store/actions/toaster/toaster.actions";

import type {IQueryError} from "src/types/GenericQueryTypes";

export const buildTableQueryKey = (queryKey, tableState: $TSFixMe = {}, ...args) => {
  return [
    queryKey,
    tableState.sorting,
    tableState.page,
    tableState.pageSize,
    tableState.filters,
    tableState.metadataFilters,
    tableState.temporaryFilters,
    ...args,
  ];
};

/**
 * Builds a React Query with some default parameters useful for tables in EAgle:
 * renders a toast in case of error
 * handles query cancellation (you need to manually add the signal param to the used fn
 * handles searchId automatically, storing the returned value and using it for next request
 * keepPreviousData is enabled to keep data when changing page, filters, etc (old usePaginatedQuery in v2)
 * is not enabled by default, and it is expecting to receive options.enabled (for Custom View purposes)
 * @param key
 * @param fn
 * @param options
 */
export function useTableQuery<T>(key, fn, options) {
  const currentSearchId = useRef();
  const dispatch = useDispatch();
  const controller = new AbortController();
  const signal = controller.signal;

  const {onError, onSuccess, enabled, ...rest} = options;
  return useQuery<T, IQueryError>(
    key,
    () => {
      const promise = fn(currentSearchId.current, signal);
      promise.cancel = () => controller.abort();
      return promise;
    },
    {
      onError: (error) => {
        dispatch(createErrorToast(error.errorMessage));
        if (onError) onError(error);
      },
      onSuccess: (resolvedData) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        currentSearchId.current = resolvedData.searchId;
        if (onSuccess) onSuccess(resolvedData);
      },
      enabled: Boolean(enabled),
      keepPreviousData: true,
      ...rest,
    },
  );
}
