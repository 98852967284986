import React from "react";

import PropTypes from "prop-types";
import {connect} from "react-redux";
import {ProgressBar} from "ui_toolkit";

import ContentNavigator from "features/jobs/job-workspace/components/content-navigator/ContentNavigator";

import {ToggleEnum} from "shared/enums/toggle.enum";

import {handleToggle} from "store/actions/layout/layout.actions";

import StructuresNavigatorContainerWrapper from "../StructuresNavigatorContainerWrapper";

import NonStitchedTranslationStructuresResources from "./nonStitchedTranslationStructures.resources";

const NonStitchedTranslationStructuresContainerPropTypes = {
  selectedJob: PropTypes.object.isRequired,
  selectedContentBlock: PropTypes.object.isRequired,
  selectedStructure: PropTypes.object.isRequired,
  structuresTable: PropTypes.object.isRequired,
  lineSaveInProgress: PropTypes.bool.isRequired,
  refreshingStats: PropTypes.bool.isRequired,
  stats: PropTypes.object.isRequired,
  translationStructuresCollapsed: PropTypes.bool.isRequired,
  handleToggleAction: PropTypes.func.isRequired,
};

const NonStitchedTranslationStructuresContainerDefaultProps = {};

class NonStitchedTranslationStructuresContainer extends React.Component {
  columns = [
    {name: "#", mapName: "rowId", sort: true, width: 5},
    {name: "Completion", mapName: "progress", width: 15},
    {name: "WordCount", mapName: "wordCount", width: 15},
    {
      name: "Bank",
      mapName: "name",
      filter: {type: "text", placeholder: NonStitchedTranslationStructuresResources.FilterPlaceholder},
      width: 65,
    },
  ];

  formatRow = (row) => {
    const {rowId, structureId, progress, wordcountActual, minEstimatedWordcount, maxEstimatedWordcount, name} = row;
    const redWordCount = wordcountActual > maxEstimatedWordcount ? "color-danger" : null;
    const wordCount = (
      <span className={redWordCount}>
        {wordcountActual}/{minEstimatedWordcount} - {maxEstimatedWordcount}
      </span>
    );
    const isActive = structureId === this.props.selectedStructure.structureId;
    const progressBar = <ProgressBar color="bg-color-primary" value={progress} fixedWidth={false} />;
    return {
      id: structureId,
      isSelected: isActive,
      data: {
        isActive,
        rowId,
        structureId,
        progress: progressBar,
        wordCount,
        name,
      },
    };
  };

  formatContentNavigatorProps() {
    const {rows, ...pageData} = this.props.structuresTable;
    const {structureIndex} = this.props.selectedStructure;
    const controlsProps = {
      lineType: NonStitchedTranslationStructuresResources.LinesType,
      currentLine: structureIndex,
      numberOfLines: pageData.totalRows,
      hasUntranslatedLines: pageData.hasUntranslatedLines,
    };
    const tableProps = {
      rows: rows && rows.map(this.formatRow),
      columns: this.columns,
      pageData,
    };

    return {...controlsProps, ...tableProps};
  }

  buildBoxTitleText = () => {
    const {stats, selectedJob, selectedContentBlock} = this.props;
    return NonStitchedTranslationStructuresResources.BoxTitle({
      contentBlockId: selectedContentBlock.rowId,
      language: selectedJob && selectedJob.language ? selectedJob.language.name : "",
      contentBlockName: selectedContentBlock.featureName || selectedContentBlock.contentGroupInformation || "",
      translatedGroups: stats.translatedGroups,
      totalGroups: stats.totalGroups,
    });
  };

  handleCollapseToggle = () => {
    this.props.handleToggleAction(ToggleEnum.TranslationStructuresCollapsed);
  };

  render() {
    const {translationStructuresCollapsed, refreshingStats} = this.props;
    const formattedProps = this.formatContentNavigatorProps();
    const boxTitleText = this.buildBoxTitleText();

    return (
      <StructuresNavigatorContainerWrapper {...this.props} {...formattedProps}>
        {(wrapper) => (
          <ContentNavigator
            elmId="structures-navigator"
            {...formattedProps}
            loading={refreshingStats}
            batchType={wrapper.batchType}
            collapsed={translationStructuresCollapsed}
            title={boxTitleText}
            onNext={wrapper.handleNext}
            onPrevious={wrapper.handlePrevious}
            onNextUntranslatedLine={wrapper.handleNextUntranslatedLine}
            onGoTo={wrapper.handleGoTo}
            onTableChange={wrapper.handleTableChange}
            onTableRowSelect={wrapper.handleTableRowSelect}
            onCollapseToggle={this.handleCollapseToggle}
          />
        )}
      </StructuresNavigatorContainerWrapper>
    );
  }
}

NonStitchedTranslationStructuresContainer.propTypes = NonStitchedTranslationStructuresContainerPropTypes;
NonStitchedTranslationStructuresContainer.defaultProps = NonStitchedTranslationStructuresContainerDefaultProps;

const mapStateToProps = (store) => ({
  translationStructuresCollapsed: store.layout.translationStructuresCollapsed,
  structuresTable: store.jobs.translationWorkspace.structuresTable,
  lineSaveInProgress: store.jobs.translationWorkspace.lineSaveInProgress,
  refreshingStats: store.jobs.translationWorkspace.refreshingStats,
  selectedStructure: store.jobs.translationWorkspace.selectedStructure,
  selectedJob: store.jobs.translationWorkspace.selectedJob,
  selectedContentBlock: store.jobs.translationWorkspace.selectedContentBlock,
  stats: store.jobs.translationWorkspace.stats,
});

const mapDispatchToProps = {
  handleToggleAction: handleToggle,
};

export default connect(mapStateToProps, mapDispatchToProps)(NonStitchedTranslationStructuresContainer);
