import {takeLatest, call, put, select} from "redux-saga/effects";

import LtReviewService from "services/jobs/lt-review-job/ltReviewJob.service";

import {Queries} from "shared/enums/queries";

import {
  CANCEL_LT_REVIEW,
  ENABLE_LT_REVIEW,
  COMPLETE_LT_REVIEW,
  REOPEN_LT_REVIEW,
  CANCEL_BULK_LT_REVIEW,
  ENABLE_BULK_LT_REVIEW,
  COMPLETE_BULK_LT_REVIEW,
  ACTION_START_LT_REVIEW,
  ACTION_SUCCESS_LT_REVIEW,
  ACTION_FAIL_LT_REVIEW,
  SET_SIMPLE_ACTION_EXECUTION,
  SET_SIMPLE_ACTION_EXECUTION_FINISHED,
} from "store/actions/jobs/job-selector/lt-review-job-selector/ltReviewJobSelector.actions";
import {createErrorToast} from "store/actions/toaster/toaster.actions";

import {queryClient} from "../../../../../../configs/react-query.config";

const getLtReviewJobSelectorState = (state) => state.jobs.ltReviewSelector;

function* executeActionFlow(action, actionData) {
  try {
    yield put({type: ACTION_START_LT_REVIEW});
    yield action(actionData);
    yield put({type: ACTION_SUCCESS_LT_REVIEW});
  } catch (error) {
    yield put({type: ACTION_FAIL_LT_REVIEW, value: error});
    yield put(createErrorToast(error));
  }
}

function* cancelReview({value}) {
  yield call(LtReviewService.cancelJob, value);
  yield call([queryClient, queryClient.invalidateQueries], Queries.LtReviewsJobTable);
}

function* completeReview({value}) {
  yield call(LtReviewService.deliverJob, value);
  yield call([queryClient, queryClient.invalidateQueries], Queries.LtReviewsJobTable);
}

function* reopenReview({value}) {
  yield call(LtReviewService.reopenJob, value);
  yield call([queryClient, queryClient.invalidateQueries], Queries.LtReviewsJobTable);
}

function* enableReview({value}) {
  yield call(LtReviewService.enableJob, value);
  yield call([queryClient, queryClient.invalidateQueries], Queries.LtReviewsJobTable);
}

function* cancelBulkReviews() {
  const {availableJobs} = yield select(getLtReviewJobSelectorState);
  yield call(LtReviewService.bulkCancelJobs, availableJobs.searchId);
  yield call([queryClient, queryClient.invalidateQueries], Queries.LtReviewsJobTable);
}

function* completeBulkReviews() {
  const {availableJobs} = yield select(getLtReviewJobSelectorState);
  yield call(LtReviewService.bulkDeliverJobs, availableJobs.searchId);
  yield call([queryClient, queryClient.invalidateQueries], Queries.LtReviewsJobTable);
}

function* enableBulkReviews() {
  const {availableJobs} = yield select(getLtReviewJobSelectorState);
  yield call(LtReviewService.bulkEnableJobs, availableJobs.searchId);
  yield call([queryClient, queryClient.invalidateQueries], Queries.LtReviewsJobTable);
}

function* getStats({value}) {
  try {
    const stats = yield call(LtReviewService.fetchStats, value.jobId);
    yield put({
      type: SET_SIMPLE_ACTION_EXECUTION_FINISHED,
      value: {
        actionInProgress: false,
        actionConfirmationModalVisible: true,
        actionIdExecuted: value.idAction,
        actionToExecute: value.action,
        stats,
      },
    });
  } catch (error) {
    yield put({type: ACTION_FAIL_LT_REVIEW, value: error});
    yield put(createErrorToast(error));
  }
}

export default [
  takeLatest(CANCEL_LT_REVIEW, executeActionFlow, cancelReview),
  takeLatest(ENABLE_LT_REVIEW, executeActionFlow, enableReview),
  takeLatest(COMPLETE_LT_REVIEW, executeActionFlow, completeReview),
  takeLatest(REOPEN_LT_REVIEW, executeActionFlow, reopenReview),
  takeLatest(CANCEL_BULK_LT_REVIEW, executeActionFlow, cancelBulkReviews),
  takeLatest(ENABLE_BULK_LT_REVIEW, executeActionFlow, enableBulkReviews),
  takeLatest(COMPLETE_BULK_LT_REVIEW, executeActionFlow, completeBulkReviews),
  takeLatest(SET_SIMPLE_ACTION_EXECUTION, getStats),
];
