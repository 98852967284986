import {
  USER_ACTIONS_FETCH_START,
  USER_ACTIONS_FETCH_SUCCESS,
  USER_ACTIONS_FETCH_FAIL,
  CURRENT_USER_INFO_FETCH_START,
  CURRENT_USER_INFO_FETCH_SUCCESS,
  CURRENT_USER_INFO_FETCH_FAIL,
  USER_LOGOUT_SUCCESS,
} from "../../actions/users/user.actions";

const initialState = {
  menuActions: [],
  fetchActionsProgress: {
    loading: false,
    errors: null,
  },
  userInfo: {},
  fetchUserInfoProgress: {
    loading: false,
    errors: null,
  },
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTIONS_FETCH_START:
      return {
        ...state,
        fetchActionsProgress: {
          loading: true,
          errors: null,
        },
      };
    case USER_ACTIONS_FETCH_SUCCESS:
      return {
        ...state,
        fetchActionsProgress: {
          loading: false,
          errors: null,
        },
        menuActions: action.value.actions,
      };
    case USER_ACTIONS_FETCH_FAIL:
      return {
        ...state,
        fetchActionsProgress: {
          loading: false,
          errors: action.value,
        },
      };
    case CURRENT_USER_INFO_FETCH_START:
      return {
        ...state,
        fetchActionsProgress: {
          loading: true,
          errors: null,
        },
      };
    case CURRENT_USER_INFO_FETCH_SUCCESS:
      return {
        ...state,
        fetchActionsProgress: {
          loading: false,
          errors: null,
        },
        userInfo: action.value.userInfo,
      };
    case CURRENT_USER_INFO_FETCH_FAIL:
      return {
        ...state,
        fetchActionsProgress: {
          loading: false,
          errors: action.value,
        },
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        userInfo: {},
      };
    default:
      return state;
  }
};

export default user;
