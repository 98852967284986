import classnames from "classnames";
import PropTypes from "prop-types";
import {Button} from "ui_toolkit";

const ContentBlockStatsPropTypes = {
  elmId: PropTypes.string.isRequired,
  stats: PropTypes.object.isRequired,
  structureLabel: PropTypes.string.isRequired,
  deliverButtonText: PropTypes.string.isRequired,
  isReadonlyJob: PropTypes.bool,
  onDeliverJob: PropTypes.func.isRequired,
};

const ContentBlockStatsDefaultProps = {
  isReadonlyJob: true,
};

const ContentBlockStats = ({elmId, stats, structureLabel, deliverButtonText, isReadonlyJob, onDeliverJob}) => {
  const {
    translatedContentGroups,
    totalContentGroups,
    translatedGroups,
    totalGroups,
    translatedLines,
    totalLines,
    wordcountActual,
    maxEstimatedWordcount,
    minEstimatedWordcount,
  } = stats;
  const redWordCount = wordcountActual > maxEstimatedWordcount ? "color-danger" : null;
  const wordCount = (
    <span className={classnames("m-l-xs", redWordCount)} data-elm-id={`${elmId}-word-count`}>
      {wordcountActual}/{minEstimatedWordcount} - {maxEstimatedWordcount}
    </span>
  );
  return (
    <div className="m-t-md flex-container align-items-center">
      Stats: Content blocks {translatedContentGroups}/{totalContentGroups} | {structureLabel} {translatedGroups}/
      {totalGroups} | Lines {translatedLines}/{totalLines} | Wordcount {wordCount}
      {!isReadonlyJob && (
        <Button
          outlined
          elmId={`${elmId}-deliver-button`}
          icon="fa-paper-plane"
          className="push-right"
          text={deliverButtonText}
          onClick={onDeliverJob}
        />
      )}
    </div>
  );
};

ContentBlockStats.propTypes = ContentBlockStatsPropTypes;
ContentBlockStats.defaultProps = ContentBlockStatsDefaultProps;

export default ContentBlockStats;
