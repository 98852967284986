import {Service} from "../../service";
import {buildLtReviewLinesTableRequestData} from "../shared/job.service.helper";

const LtReviewJobLinesService = {
  fetchLines: ({jobId, contentGroupId, structureId, selectedRow = null, tableState = {}}) => {
    const requestFilters = buildLtReviewLinesTableRequestData(tableState);
    const url = `reviews/${jobId}/contentgroup/${contentGroupId}/structure/${structureId}/lines`;
    return Service.get({
      url: `${url}`,
      params: {...requestFilters, selectedRow},
    });
  },

  nextUnreviewedLine: ({jobId, contentGroupId, structureId, selectedRow, tableState = {}}) => {
    const requestFilters = buildLtReviewLinesTableRequestData(tableState);
    const url = `reviews/${jobId}/contentgroup/${contentGroupId}/structure/${structureId}/untranslated`;

    return Service.get({
      url: `${url}`,
      params: {...requestFilters, selectedRow},
    });
  },

  goToLinePageById: ({jobId, contentGroupId, structureId, rowId, tableState = {}}) => {
    const requestFilters = buildLtReviewLinesTableRequestData(tableState);
    const url = `reviews/${jobId}/contentgroup/${contentGroupId}/structure/${structureId}/position`;

    return Service.get({
      url,
      params: {...requestFilters, searchRowId: rowId},
    });
  },

  saveLine: ({jobId, contentGroupId, structureId, lineId, asset}) => {
    return Service.patch({
      url: `reviews/${jobId}/contentgroup/${contentGroupId}/structure/${structureId}/lines/${lineId}/asset/${asset.id}`,
      data: {asset},
    });
  },

  reviewLine: ({jobId, contentGroupId, structureId, lineId}) => {
    return Service.patch({
      url: `reviews/${jobId}/contentgroup/${contentGroupId}/structure/${structureId}/lines/${lineId}`,
    });
  },
};

export default LtReviewJobLinesService;
