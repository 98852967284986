const LtReviewJobsResources = {
  BoxTitleSelector: "Available Jobs",
  SelectorTitle: "Reviews Job Selector",
  WorkspaceTitle: "Reviews Workspace",
  SelectorActions: {
    CancelLtReview: "Cancel LT Reviews",
    EnableLtReview: "Enable cancelled LT Reviews",
    CompleteLtReview: "Mark LT Reviews as completed",
  },
};

const TranslationJobsResources = {
  BoxTitle: "Available Jobs",
  SelectorTitle: "Translation Job Selector",
  WorkspaceTitle: "Translation Workspace",
};

export {LtReviewJobsResources, TranslationJobsResources};
