const NonStitchedTranslationLinesResources = {
  TranslationLinesBoxTitle: ({rowId = 0, progress = 0}) =>
    `Lines to review for: Structure #${rowId} - (${progress}% lines reviewed)`,
  IsModified: (isModified) => (isModified ? "Yes" : "No"),
  LineType: "Line",
  FilterPlaceholder: "Filter by Asset",
  Translatable: "Translatable",
  noTranslatable: "Not Translatable",
  TranslatableIcon: "fa-file-edit far color-success",
  NoTranslatableIcon: "fa-ban far color-basic",
};

export default NonStitchedTranslationLinesResources;
