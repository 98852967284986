const PlayersTranslationLinesResources = {
  TranslationLinesBoxTitle: ({name = "Selected Player", progress = 0}) =>
    `Lines to translate for: ${name} - (${progress}% lines translated)`,
  LineType: "Line",
  FilterPlaceholder: "Filter by Asset",
  Translatable: "Translatable",
  noTranslatable: "Not Translatable",
  TranslatableIcon: "fa-file-edit far color-success",
  NoTranslatableIcon: "fa-ban far color-basic",
};

export default PlayersTranslationLinesResources;
