import React from "react";

import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Badge, Icon, Tooltip} from "ui_toolkit";

import {orderPhrases} from "features/jobs/job-workspace/shared/contentLines.helper.js";

import {LineStatusEnum} from "shared/enums/line.enum";
import {ToggleEnum} from "shared/enums/toggle.enum";
import {useIsRtl} from "shared/hooks/useIsRtl";
import {calculateRemainingColumnsWidth} from "shared/utils/calculateRemainingColumnsWidth";

import {handleToggle} from "store/actions/layout/layout.actions";

import Rtl from "../../../../../../shared/rtl/Rtl";
import LinesNavigatorContainer from "../LinesNavigatorContainer";

import NonStitchedTranslationLinesResources from "./nonStitchedTranslationLines.resources";

const NonStitchedTranslationLinesContainerPropTypes = {
  selectedJob: PropTypes.object.isRequired,
  selectedContentBlock: PropTypes.object.isRequired,
  selectedStructure: PropTypes.object.isRequired,
  selectedLine: PropTypes.object.isRequired,
  linesTable: PropTypes.object.isRequired,
  refreshingStats: PropTypes.bool.isRequired,
  translationLinesCollapsed: PropTypes.bool.isRequired,
  handleToggleAction: PropTypes.func.isRequired,
};

const NonStitchedTranslationLinesContainerDefaultProps = {};

const NonStitchedTranslationLinesContainer = ({
  selectedJob,
  selectedContentBlock,
  selectedStructure,
  selectedLine,
  linesTable,
  refreshingStats,
  translationLinesCollapsed,
  handleToggleAction,
}) => {
  const tableContentRef = React.useRef(null);
  const rightToLeft = useIsRtl(selectedJob?.language?.id || 0);

  const formatRow = (row) => {
    const {lineId, rowId, assets = [], status} = row;
    const StatusBadge = <Badge medium text={LineStatusEnum[status].text} color={LineStatusEnum[status].color} />;
    const getTranslatableIcon = (isTranslatable) => (
      <Tooltip
        text={
          isTranslatable
            ? NonStitchedTranslationLinesResources.Translatable
            : NonStitchedTranslationLinesResources.noTranslatable
        }
      >
        <Icon
          icon={
            isTranslatable
              ? NonStitchedTranslationLinesResources.TranslatableIcon
              : NonStitchedTranslationLinesResources.NoTranslatableIcon
          }
        />
      </Tooltip>
    );
    const isActive = lineId === selectedLine.lineId;
    const assetRows = {};
    assets.forEach(({translatedText, phraseOrderId, isTranslatable}) => {
      assetRows[`phrase_${phraseOrderId}`] = (
        <Rtl elmId={`rtl-phrase-${phraseOrderId}`} languageId={selectedJob?.language?.id || 0}>
          {translatedText}
        </Rtl>
      );
      assetRows[`translatable_${phraseOrderId}`] = getTranslatableIcon(isTranslatable);
    });
    return {
      id: lineId,
      isSelected: isActive,
      data: {
        isActive,
        lineId,
        rowId,
        status: StatusBadge,
        ...assetRows,
      },
    };
  };

  const formatContentNavigatorProps = () => {
    const totalTableWidth = tableContentRef.current?.clientWidth;
    const {rows, ...pageData} = linesTable;
    const orderedPhrases = orderPhrases(selectedStructure);
    const assetsColumns = orderedPhrases
      ? orderedPhrases.reduce((array, {name, phraseOrderId}) => {
          return [
            ...array,
            {
              name: "",
              rightToLeft,
              mapName: `translatable_${phraseOrderId}`,
              width: 40,
              sort: true,
            },
            {
              name,
              rightToLeft,
              mapName: `phrase_${phraseOrderId}`,
              filter: {type: "text", placeholder: NonStitchedTranslationLinesResources.FilterPlaceholder},
              sort: true,
            },
          ];
        }, [])
      : [];
    const formattedAssets = rightToLeft ? assetsColumns.reverse() : assetsColumns;
    const statusFilterData = Object.keys(LineStatusEnum).map((key) => {
      const {id: value, text} = LineStatusEnum[key];
      return {value, text};
    });
    const columns = [
      {name: "Line", mapName: "rowId", sort: true, width: 70},
      {
        name: "Status",
        mapName: "status",
        className: "text-align-center",
        sort: true,
        filter: {type: "select-single", data: statusFilterData},
        width: 100,
      },
      ...formattedAssets,
    ];
    const finalColumns = totalTableWidth ? calculateRemainingColumnsWidth(columns, totalTableWidth) : columns;
    const controlsProps = {
      lineType: NonStitchedTranslationLinesResources.LinesType,
      currentLine: selectedLine.lineIndex,
      numberOfLines: pageData.totalRows,
      hasUntranslatedLines: pageData.hasUntranslatedLines,
    };
    const tableProps = {
      columns: finalColumns,
      pageData,
      rows: rows && rows.map(formatRow),
    };

    return {...controlsProps, ...tableProps};
  };

  const setTableContentRef = (ref) => {
    tableContentRef.current = ref;
  };

  const handleCollapseToggle = () => {
    handleToggleAction(ToggleEnum.TranslationLinesCollapsed);
  };

  const title = NonStitchedTranslationLinesResources.TranslationLinesBoxTitle(selectedStructure);
  return (
    <LinesNavigatorContainer
      linesTable={linesTable}
      loading={refreshingStats}
      selectedJob={selectedJob}
      selectedContentBlock={selectedContentBlock}
      selectedStructure={selectedStructure}
      selectedLine={selectedLine}
      title={title}
      collapsed={translationLinesCollapsed}
      setTableContentRef={setTableContentRef}
      onCollapseToggle={handleCollapseToggle}
      {...formatContentNavigatorProps()}
    />
  );
};

NonStitchedTranslationLinesContainer.propTypes = NonStitchedTranslationLinesContainerPropTypes;
NonStitchedTranslationLinesContainer.defaultProps = NonStitchedTranslationLinesContainerDefaultProps;

const mapStateToProps = (store) => {
  return {
    selectedJob: store.jobs.translationWorkspace.selectedJob,
    selectedContentBlock: store.jobs.translationWorkspace.selectedContentBlock,
    selectedStructure: store.jobs.translationWorkspace.selectedStructure,
    linesTable: store.jobs.translationWorkspace.linesTable,
    refreshingStats: store.jobs.translationWorkspace.refreshingStats,
    selectedLine: store.jobs.translationWorkspace.selectedLine,
    translationLinesCollapsed: store.layout.translationLinesCollapsed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleToggleAction: (key) => dispatch(handleToggle(key)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NonStitchedTranslationLinesContainer);
