import axios from "axios";
import httpAdapter from "axios/lib/adapters/http";
import axiosRetry from "axios-retry";

import {ApiConfigEnum} from "services/enums/apiConfig.enum";

const instance = axios.create({
  baseURL: ApiConfigEnum.API_URL_BASE,
  timeout: ApiConfigEnum.DEFAULT_OPTIONS.timeout,
  headers: {"Content-Type": "application/json", "api-version": "1.0"},
  adapter: httpAdapter, // When calling Axios in node environments, we should use the the axios httpAdapter
});

axiosRetry(instance, {retries: ApiConfigEnum.DEFAULT_OPTIONS.retries});

export default instance;
