import type {ReactElement} from "react";

import {useIsRtl} from "shared/hooks/useIsRtl";

interface RtlProps {
  elmId?: string;
  children?: string | number | ReactElement;
  languageId: string | number;
}

const Rtl = ({elmId = "", languageId, children = ""}: RtlProps) => {
  const isRtl = useIsRtl(Number(languageId));

  return (
    <div dir={isRtl ? "rtl" : "ltr"} data-elm-id={elmId}>
      {children}
    </div>
  );
};

export default Rtl;
