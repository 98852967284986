import {useMutation} from "react-query";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import TranslationJobService from "services/jobs/translation-job/translationJob.service";

import {Queries} from "shared/enums/queries";

import {setCurrentPageTitle} from "store/actions/layout/layout.actions";
import {buildTableQueryKey, useTableQuery} from "store/utils/react-query.utils";

import {TranslationJobsResources} from "src/features/jobs/jobs.resources";
import {RoutesEnum} from "src/router/routes/routes.enum";
import {
  TRANSLATION_ACTION_FAIL,
  TRANSLATION_ACTION_SUCCESS,
  TRANSLATION_JOB_DELIVER_CANCEL,
  TRANSLATION_JOB_DELIVER_IN_PROGRESS,
} from "src/store/actions/jobs/job-workspace/translation-job-workspace/translationJobWorkspace.actions";
import {createErrorToast} from "src/store/actions/toaster/toaster.actions";
import type {$TSFixme} from "src/types/TSFixme";

export const useFetchJobsTableData = ({tableState}) => {
  return useTableQuery<$TSFixme>(
    buildTableQueryKey(Queries.TranslationJobsTable, tableState),
    () => TranslationJobService.fetchJobsTableData(tableState),
    {
      enabled: true,
    },
  );
};

export function useDeliverTranslationJob() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return useMutation<void, string, {jobId}>(({jobId}) => TranslationJobService.deliverJob(jobId), {
    onMutate: () => {
      dispatch({type: TRANSLATION_JOB_DELIVER_IN_PROGRESS, value: true});
    },
    onSuccess: () => {
      dispatch({type: TRANSLATION_ACTION_SUCCESS, value: {deliverConfirmationModalVisible: false}});
      dispatch(setCurrentPageTitle(TranslationJobsResources.SelectorTitle));
      dispatch({type: TRANSLATION_JOB_DELIVER_IN_PROGRESS, value: false});
      navigate(`${RoutesEnum.Jobs.Base}/${RoutesEnum.Jobs.Translation}`);
    },
    onError: (error) => {
      dispatch({type: TRANSLATION_ACTION_FAIL});
      dispatch({type: TRANSLATION_JOB_DELIVER_CANCEL});
      dispatch({type: TRANSLATION_JOB_DELIVER_IN_PROGRESS, value: false});
      dispatch(createErrorToast(error || "Error delivering job"));
    },
  });
}

// export function useGetTranslationJob(jobId) {
//   const dispatch = useDispatch();
//   const query = useQuery(
//     Queries.GetTranslationJob,
//     () => TranslationJobService.fetchJob(jobId),
//     {
//       onSuccess: (selectedJob) => {
//         dispatch({type: TRANSLATION_ACTION_SUCCESS, value: {selectedJob}});
//         const workspaceContainers = getTranslationWorkspaceContainers(selectedJob.batchTopologyId);
//         if (workspaceContainers) {
//           dispatch({type: TRANSLATION_ACTION_SUCCESS, value: {workspaceContainers}});
//         } else {
//           throw `"${selectedJob.name}" has an unknown topology`;
//         }
//       }
//     }
//   );

//   // Start
//   useMemo(() => query.isFetching && dispatch({type: TRANSLATION_LOADING_START}), [dispatch, query.isFetching]); // TODO: WORKS?
//   return query;
// }

// export function useGetTranslationStats(jobId) {
//   const dispatch = useDispatch();
//   return useQuery(
//     Queries.GetTranslationStats,
//     () => TranslationJobService.fetchStats(jobId),
//     {
//       enabled: !!jobId, // TODO: NEED IT?
//       onSuccess: (stats) => dispatch({type: TRANSLATION_STATS_SET, value: stats}),
//       onError: (error) => {
//         dispatch({type: TRANSLATION_ACTION_FAIL});
//         dispatch(createErrorToast(error || 'Error getting stats information'));
//       }
//     }
//   );
// }

// export function useGetTranslationContentBlocks(jobId, tableState = {}) {
//   return useQuery(
//     [Queries.GetTranslationStats, jobId, tableState],
//     () => TranslationJobService.fetchContentBlocks({jobId, tableState})
//   );
// }

// export function useGetNextUntranslatedCOntentBlocks(jobId) {
//   const dispatch = useDispatch();
//   return useQuery(
//     Queries.GetNextUntranslatedContentBlocks,
//     () => TranslationJobService.fetchContentBlocks({jobId}),
//     {
//       enabled: !!jobId,
//       onSuccess: (contentBlocks) => {
//         const selectedContentBlock = contentBlocks.find(({isSelected}) => isSelected === true) || contentBlocks[0] || {};
//         dispatch

//       }
//     }
//   );
// }
