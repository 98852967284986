import {
  GET_VERSION_DATA_START,
  GET_VERSION_DATA_SUCCESS,
  GET_VERSION_DATA_FAIL,
  GET_LANGUAGES_START,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAIL,
} from "store/actions/master/master.actions";

const initialState = {
  projectSettings: {
    // TODO: Get projectSettings from backend
    RowsPerPage: {
      text: "RowsPerPage",
      value: [
        {
          value: "5",
          text: "5",
        },
        {
          value: "10",
          text: "10",
        },
        {
          value: "25",
          text: "25",
        },
        {
          value: "50",
          text: "50",
        },
        {
          value: "100",
          text: "100",
        },
      ],
    },
    TableColumnsFiltersExpanded: {
      text: "TableColumnsFiltersExpanded",
      value: true,
    },
    DefaultNumberRowPerPage: {
      text: "DefaultNumberRowPerPage",
      value: "25",
    },
  },
  versionData: {},
  projectLanguages: [],
  fetchMasterDataProgress: {
    loading: false,
    errors: null,
  },
  fetchLanguagesProgress: {
    loading: false,
    languages: null,
  },
};

const master = (state = initialState, action) => {
  switch (action.type) {
    case GET_VERSION_DATA_START:
      return {
        ...state,
        fetchMasterDataProgress: {
          loading: true,
          errors: null,
        },
      };
    case GET_VERSION_DATA_SUCCESS:
      return {
        ...state,
        fetchMasterDataProgress: {
          loading: false,
          errors: null,
        },
        versionData: action.value,
      };
    case GET_VERSION_DATA_FAIL:
      return {
        ...state,
        fetchMasterDataProgress: {
          loading: false,
          errors: action.value,
        },
      };
    case GET_LANGUAGES_START:
      return {
        ...state,
        fetchLanguagesProgress: {
          loading: true,
          errors: null,
        },
      };
    case GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        fetchLanguagesProgress: {
          loading: false,
          errors: null,
        },
        projectLanguages: action.value,
      };
    case GET_LANGUAGES_FAIL:
      return {
        ...state,
        fetchLanguagesProgress: {
          loading: false,
          errors: action.value,
        },
      };
    default:
      return state;
  }
};

export default master;
