import {Service} from "../service";

const MasterService = {
  getVersionData: () =>
    Service.get({
      url: "version",
    }),
  getProjectLanguages: () =>
    Service.get({
      url: "project/languages/localizable",
    }),
};

export default MasterService;
