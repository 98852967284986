import PropTypes from "prop-types";
import {connect} from "react-redux";

import BatchType from "shared/enums/batchType.enum.js";

import {
  fetchLtReviewStructures,
  fetchNextUnreviewedStructures,
  setActivePreviousLtReviewStructure,
  setActiveNextLtReviewStructure,
  goToLtReviewStructure,
} from "store/actions/jobs/job-workspace/lt-review-job-workspace/structures/ltReviewJobWorkspaceStructures.actions";

const StructuresNavigatorContainerWrapperPropTypes = {
  children: PropTypes.func.isRequired,
  structuresTable: PropTypes.object.isRequired,
  selectedStructure: PropTypes.object.isRequired,
  selectedJob: PropTypes.object.isRequired,
  selectedContentBlock: PropTypes.object.isRequired,
  fetchLtReviewStructuresAction: PropTypes.func.isRequired,
  fetchNextUnreviewedStructuresAction: PropTypes.func.isRequired,
  setActivePreviousLtReviewStructureAction: PropTypes.func.isRequired,
  setActiveNextLtReviewStructureAction: PropTypes.func.isRequired,
  goToLtReviewStructureAction: PropTypes.func.isRequired,
};

const StructuresNavigatorContainerWrapper = (props) => {
  const handleNextUntranslatedLine = () => {
    props.fetchNextUnreviewedStructuresAction({
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      tableState: props.structuresTable,
      selectedStructure: props.selectedStructure,
    });
  };

  const handleNext = () => {
    props.setActiveNextLtReviewStructureAction({
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      tableState: props.structuresTable,
      selectedStructure: props.selectedStructure,
    });
  };

  const handlePrevious = () => {
    props.setActivePreviousLtReviewStructureAction({
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      tableState: props.structuresTable,
      selectedStructure: props.selectedStructure,
    });
  };

  const handleGoTo = (rowId) => {
    props.goToLtReviewStructureAction({
      rowId,
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      tableState: props.structuresTable,
      clearFilters: true, // TODO: Review
    });
  };

  const handleTableChange = (tableState) => {
    const {selectedJob, selectedContentBlock, selectedStructure} = props;
    const {id: selectedJobId} = selectedJob;
    const {id: selectedContentBlockId} = selectedContentBlock;

    if (selectedJobId && selectedContentBlockId && selectedStructure) {
      props.fetchLtReviewStructuresAction({
        jobId: selectedJobId,
        contentGroupId: selectedContentBlockId,
        tableState,
        selectedStructure,
      });
    }
  };

  const handleTableRowSelect = ({rowId}) => {
    props.goToLtReviewStructureAction({
      rowId,
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      tableState: props.structuresTable,
    });
  };

  return (
    <>
      {props.children({
        batchType: BatchType.Review,
        handleNext,
        handlePrevious,
        handleNextUntranslatedLine,
        handleGoTo,
        handleTableChange,
        handleTableRowSelect,
      })}
    </>
  );
};

StructuresNavigatorContainerWrapper.propTypes = StructuresNavigatorContainerWrapperPropTypes;

const mapDispatchToProps = {
  fetchLtReviewStructuresAction: fetchLtReviewStructures,
  fetchNextUnreviewedStructuresAction: fetchNextUnreviewedStructures,
  setActivePreviousLtReviewStructureAction: setActivePreviousLtReviewStructure,
  setActiveNextLtReviewStructureAction: setActiveNextLtReviewStructure,
  goToLtReviewStructureAction: goToLtReviewStructure,
};

export default connect(null, mapDispatchToProps)(StructuresNavigatorContainerWrapper);
