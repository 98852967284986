import {
  FETCH_JOB_DATA_START,
  FETCH_JOB_DATA_SUCCESS,
  FETCH_JOB_DATA_FAIL,
} from "store/actions/jobs/external-job/externalJob.actions";

const initialState = {
  externalJobProgress: {
    loading: false,
    error: null,
  },
  jobData: {},
};

const externalJob = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOB_DATA_START: {
      return {
        ...state,
        externalJobProgress: {
          loading: true,
        },
      };
    }
    case FETCH_JOB_DATA_SUCCESS:
      return {
        ...state,
        externalJobProgress: {
          loading: false,
          error: null,
        },
        jobData: action.value,
      };
    case FETCH_JOB_DATA_FAIL:
      return {
        ...state,
        externalJobProgress: {
          loading: false,
          error: action.value,
        },
        jobData: {},
      };
    default:
      return state;
  }
};

export default externalJob;
