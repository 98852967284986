import {useMutation} from "react-query";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import LtReviewService from "services/jobs/lt-review-job/ltReviewJob.service";

import {Queries} from "shared/enums/queries";

import {setAvailableLtReviewJobsTableData} from "store/actions/jobs/job-selector/lt-review-job-selector/ltReviewJobSelector.actions";
import {formatAvailableJobs} from "store/sagas/jobs/job-selector/lt-review-job-selector/ltReviewJobSelector.sagas.helper";
import {buildTableQueryKey, useTableQuery} from "store/utils/react-query.utils";

import {TranslationJobsResources} from "src/features/jobs/jobs.resources";
import {RoutesEnum} from "src/router/routes/routes.enum";
import {
  LT_REVIEW_ACTION_FAIL,
  LT_REVIEW_ACTION_SUCCESS,
  LT_REVIEW_JOB_DELIVER_CANCEL,
  LT_REVIEW_JOB_DELIVER_IN_PROGRESS,
} from "src/store/actions/jobs/job-workspace/lt-review-job-workspace/ltReviewJobWorkspace.actions";
import {setCurrentPageTitle} from "src/store/actions/layout/layout.actions";
import {createErrorToast} from "src/store/actions/toaster/toaster.actions";

export const useFetchReviewJobsTableData = ({tableState}) => {
  const dispatch = useDispatch();
  return useTableQuery(
    buildTableQueryKey(Queries.LtReviewsJobTable, tableState),
    () => LtReviewService.fetchJobsTableData(tableState),
    {
      enabled: true,
      select: (data) => ({...data, rows: formatAvailableJobs(data.rows)}),
      onSuccess: (data) => dispatch(setAvailableLtReviewJobsTableData(data)),
    },
  );
};

export function useDeliverLTReviewJob() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return useMutation<void, string, {jobId}>(({jobId}) => LtReviewService.deliverJob(jobId), {
    onMutate: () => {
      dispatch({type: LT_REVIEW_JOB_DELIVER_IN_PROGRESS, value: true});
    },
    onSuccess: () => {
      dispatch({type: LT_REVIEW_ACTION_SUCCESS, value: {deliverConfirmationModalVisible: false}});
      dispatch(setCurrentPageTitle(TranslationJobsResources.SelectorTitle));
      dispatch({type: LT_REVIEW_JOB_DELIVER_IN_PROGRESS, value: false});
      navigate(`${RoutesEnum.Jobs.Base}/${RoutesEnum.Jobs.LtReview}`);
    },
    onError: (error) => {
      dispatch({type: LT_REVIEW_ACTION_FAIL});
      dispatch({type: LT_REVIEW_JOB_DELIVER_CANCEL});
      dispatch({type: LT_REVIEW_JOB_DELIVER_IN_PROGRESS, value: false});
      dispatch(createErrorToast(error));
    },
  });
}
