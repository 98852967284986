import BatchType from "shared/enums/batchType.enum.js";

const ContentNavigatorContainerResources = {
  CurrentLineVsTotal: ({currentLine, numberOfLines}) => `${currentLine} of ${numberOfLines}`,
  NextUnprocessedLineButton: ({batchType, lineType}) =>
    batchType === BatchType.Translation ? `Next UNTRANSLATED ${lineType}` : `Next UNREVIEWED ${lineType}`,
  GoTo: "Go To",
  GoToPlaceholder: "line number",
};

export default ContentNavigatorContainerResources;
