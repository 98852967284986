import classNames from "classnames";
import PropTypes from "prop-types";
import {Field} from "ui_toolkit";

const ReferenceLinePropTypes = {
  title: PropTypes.string,
  activeAssetId: PropTypes.number,
  assets: PropTypes.arrayOf(PropTypes.object),
};

const ReferenceLineDefaultProps = {
  title: "Reference Line",
  activeAssetId: 0,
  assets: [],
};

const ReferenceLine = ({title, assets, activeAssetId}) => {
  return (
    <Field text={title} className="flex-grow-1">
      {assets ? (
        assets.map(({assetId, referenceLine}) => (
          <span key={assetId} className={classNames("m-r-xs", {"color-primary": activeAssetId === assetId})}>
            {referenceLine}
          </span>
        ))
      ) : (
        <span>No Reference Line</span>
      )}
    </Field>
  );
};

ReferenceLine.propTypes = ReferenceLinePropTypes;
ReferenceLine.defaultProps = ReferenceLineDefaultProps;

export default ReferenceLine;
