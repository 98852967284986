export const LT_REVIEW_STRUCTURES_FETCH = "LT_REVIEW_STRUCTURES_FETCH";
export const LT_REVIEW_STRUCTURES_SET_ACTIVE_NEXT = "LT_REVIEW_STRUCTURES_SET_ACTIVE_NEXT";
export const LT_REVIEW_STRUCTURES_SET_ACTIVE_PREVIOUS = "LT_REVIEW_STRUCTURES_SET_ACTIVE_PREVIOUS";
export const LT_REVIEW_STRUCTURES_GO_TO = "LT_REVIEW_STRUCTURES_GO_TO";
export const LT_REVIEW_STRUCTURES_NEXT_UNREVIEWED = "LT_REVIEW_STRUCTURES_NEXT_UNREVIEWED";

export const LT_REVIEW_SELECTED_STRUCTURE_UPDATE = "LT_REVIEW_SELECTED_STRUCTURE_UPDATE";

export const fetchLtReviewStructures = (requestData) => {
  return {
    type: LT_REVIEW_STRUCTURES_FETCH,
    value: requestData,
  };
};

export const fetchNextUnreviewedStructures = (requestData) => {
  return {
    type: LT_REVIEW_STRUCTURES_NEXT_UNREVIEWED,
    value: requestData,
  };
};

export const setActiveNextLtReviewStructure = (requestData) => {
  return {
    type: LT_REVIEW_STRUCTURES_SET_ACTIVE_NEXT,
    value: requestData,
  };
};

export const setActivePreviousLtReviewStructure = (requestData) => {
  return {
    type: LT_REVIEW_STRUCTURES_SET_ACTIVE_PREVIOUS,
    value: requestData,
  };
};

export const goToLtReviewStructure = (requestData) => {
  return {
    type: LT_REVIEW_STRUCTURES_GO_TO,
    value: requestData,
  };
};
