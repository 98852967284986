export const generateColorsMapById = (arr) => {
  let colors = [];
  const colorsMap = {};
  arr.forEach(({name}) => {
    if (!colors.length) {
      colors = [
        "red-darken-2",
        "bg-color-light-green-darken-2",
        "yellow-darken-3",
        "bg-color-cyan-darken-1",
        "pink-lighten-1",
        "bg-color-lime-darken-3",
        "bg-color-light-blue-darken-1",
        "bg-color-teal-darken-1",
        "bg-color-indigo",
        "bg-color-purple",
        "bg-color-green-darken-1",
        "bg-color-deep-purple",
      ];
    }
    if (!colorsMap[name]) {
      colorsMap[name] = colors.shift();
    }
  });
  return colorsMap;
};
