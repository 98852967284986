// this is really cool, if you don't already know how it works, read up on it here:
// https://github.com/reactjs/redux/issues/99#issuecomment-112198579
export default function promiseMiddleware() {
  return (next) => (action) => {
    const {promise, types, ...rest} = action;
    if (!promise) {
      return next(action);
    }

    const [REQUEST, SUCCESS, FAILURE] = types.length > 2 ? types : [null, ...types];
    if (REQUEST) {
      next({...rest, type: REQUEST});
    }
    return promise.then(
      (value) => next({...rest, value, type: SUCCESS}),
      (error) => {
        next({...rest, error, type: FAILURE});
        throw error;
      },
    );
  };
}
