import "./line-status.css";

import classnames from "classnames";
import PropTypes from "prop-types";

import LineStatusResources from "./lineStatus.resources";

const LineStatusPropTypes = {
  lineSaveInProgress: PropTypes.bool,
  lineSaveError: PropTypes.string,
};

const ContextInfoDefaultProps = {
  lineSaveInProgress: false,
  lineSaveError: "",
};

function LineStatus({lineSaveInProgress, lineSaveError}) {
  const message = lineSaveInProgress ? LineStatusResources.savingMessage : LineStatusResources.changesSaved;
  return (
    <span className={classnames("line-status-message", {"color-danger": lineSaveError})}>
      {lineSaveError || message}
    </span>
  );
}

LineStatus.propTypes = LineStatusPropTypes;
LineStatus.defaultProps = ContextInfoDefaultProps;

export default LineStatus;
