export const LineStatusEnum = {
  1: {id: 1, text: "Done", icon: "fa-clock", color: "bg-color-success", tooltip: "Done"},
  2: {id: 2, text: "Pending", icon: "fa-clock", color: "bg-color-basic", tooltip: "Pending"},
  3: {id: 3, text: "In Progress", icon: "fa-clock", color: "bg-color-warning", tooltip: "In Progress"},
};

export const LineStatusNameEnum = {
  Done: {id: 1, text: "Done", icon: "fa-clock", color: "bg-color-success", tooltip: "Done"},
  Pending: {id: 2, text: "Pending", icon: "fa-clock", color: "bg-color-basic", tooltip: "Pending"},
  InProgress: {id: 3, text: "In Progress", icon: "fa-clock", color: "bg-color-warning", tooltip: "In Progress"},
};
