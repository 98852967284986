const orderPhrases = ({phrases = []}) => {
  const phraseCompare = (previousPhrase, nextPhrase) => {
    if (previousPhrase.phraseOrderId < nextPhrase.phraseOrderId) return -1;
    if (nextPhrase.phraseOrderId > previousPhrase.phraseOrderId) return 1;
    return 0;
  };
  return phrases.sort(phraseCompare);
};

export {orderPhrases};
