import React from "react";

import PropTypes from "prop-types";
import {Modal, Icon} from "ui_toolkit";

import PhraseCard from "../phrase-card/PhraseCard";

const LineEditorPropTypes = {
  activeAsset: PropTypes.object,
  assetsMap: PropTypes.object.isRequired,
  rightToLeft: PropTypes.bool,
  batchTopology: PropTypes.number,
  parametersColorMap: PropTypes.object,
  isReadonlyJob: PropTypes.bool,
  onCheckboxChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onChangeActiveAsset: PropTypes.func.isRequired,
  lineId: PropTypes.number,
  onGenderChange: PropTypes.func.isRequired,
};

const LineEditorDefaultProps = {
  rightToLeft: false,
  isReadonlyJob: true,
  parametersColorMap: {},
  activeAsset: {},
  lineId: 0,
  batchTopology: 0,
};

class LineEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      currentAssetId: 0,
    };
  }

  sortByOrderedPhases = () => {
    return this.props.assetsMap
      .valueSeq()
      .toJS()
      .sort((assetA, assetB) => assetA.phraseOrderId - assetB.phraseOrderId);
  };

  handleModalClose = () => {
    this.setState({modalVisible: false, currentAssetId: 0});
  };

  handleModalOk = () => {
    this.props.onCheckboxChange({assetId: this.state.currentAssetId, isEmptyString: true});
    this.handleModalClose();
  };

  handleTextChange = ({assetId, isEmptyString, translatedText}) => {
    this.props.onTextChange({assetId, isEmptyString, translatedText});
  };

  handleCheckboxChange = ({assetId, translatedText}, isEmptyString) => {
    if (isEmptyString && translatedText !== "") {
      this.setState({modalVisible: true, currentAssetId: assetId});
    } else {
      this.props.onCheckboxChange({assetId, isEmptyString});
    }
  };

  handleOnFocus = ({assetId}) => {
    if (assetId !== this.props.activeAsset.assetId) {
      this.props.onChangeActiveAsset({assetId});
    }
  };

  render() {
    const {rightToLeft, activeAsset, parametersColorMap, lineId, batchTopology} = this.props;
    const {modalVisible} = this.state;
    const assetArr = this.sortByOrderedPhases();
    const formattedAssets = rightToLeft ? assetArr.reverse() : assetArr;
    return (
      <>
        <div className="m-t-sm">
          {formattedAssets &&
            formattedAssets.map((asset) => (
              <PhraseCard
                key={`phrase-card-${lineId}-${asset.assetId}`}
                asset={asset}
                activeAsset={activeAsset}
                rightToLeft={rightToLeft}
                batchTopology={batchTopology}
                parametersColorMap={parametersColorMap}
                isReadonlyJob={this.props.isReadonlyJob}
                onFocus={this.handleOnFocus}
                onCheckboxChange={this.handleCheckboxChange}
                onTextChange={this.handleTextChange}
                onGenderChange={this.props.onGenderChange}
              />
            ))}
        </div>
        <Modal
          title="Translation will be cleared"
          visible={modalVisible}
          onOk={this.handleModalOk}
          onCancel={this.handleModalClose}
        >
          <div className="flex-container flex-direction-column align-items-center">
            <Icon xlarge className="color-warning m-b-md" icon="fa-exclamation-triangle" />
            <div className="text-align-center">
              This field contains text that has already been translated. Are you sure you want to use the Empty String
              function and mark this line as translated?
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

LineEditor.propTypes = LineEditorPropTypes;
LineEditor.defaultProps = LineEditorDefaultProps;

export default LineEditor;
