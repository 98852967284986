import React from "react";

import classNames from "classnames";
import moment from "moment/moment";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Box, ProgressBar, Button, Tooltip, Title} from "ui_toolkit";

import {ToggleEnum} from "shared/enums/toggle.enum";

import {
  fetchLtReviewContentBlocks,
  selectLtReviewContentBlock,
  startDeliverLtReviewJob,
} from "store/actions/jobs/job-workspace/lt-review-job-workspace/ltReviewJobWorkspace.actions";
import {handleToggle} from "store/actions/layout/layout.actions";

import ContentBlock from "../../../../components/content-block/content-block/ContentBlock";
import DeliverJobContainer from "../DeliverJobContainer";

import NonStitchedContentBlockResources from "./nonStitchedContentBlock.resources";

const NonStitchedContentBlockContainerPropTypes = {
  selectedJob: PropTypes.object.isRequired,
  contentBlocksTable: PropTypes.object.isRequired,
  refreshingStats: PropTypes.bool.isRequired,
  stats: PropTypes.object.isRequired,
  contentBlocksCollapsed: PropTypes.bool.isRequired,
  selectedContentBlock: PropTypes.object.isRequired,
  fetchLtReviewContentBlocksAction: PropTypes.func.isRequired,
  selectLtReviewContentBlockAction: PropTypes.func.isRequired,
  startDeliverLtReviewJobAction: PropTypes.func.isRequired,
  handleToggleAction: PropTypes.func.isRequired,
};

const NonStitchedContentBlockContainerDefaultProps = {};

const columns = [
  {name: "#", mapName: "rowId", toggleable: false, sort: true, width: 5},
  {name: "Completion", mapName: "completion", width: 15},
  {name: "WordCount", mapName: "wordCount", width: 15},
  {
    name: "Priority",
    mapName: "priority",
    toggleable: false,
    sort: true,
    filter: {type: "text", placeholder: "Filter by Priority", validationRegex: "^[0-9]{1,10}$"},
    width: 5,
  },
  {
    name: "Order",
    mapName: "order",
    toggleable: false,
    sort: true,
    filter: {type: "text", placeholder: "Filter by Order", validationRegex: "^[0-9]{1,10}$"},
    width: 5,
  },
  {name: "Banks", mapName: "group", toggleable: false, width: 15},
  {
    name: "Feature",
    mapName: "featureName",
    toggleable: false,
    sort: true,
    filter: {type: "text", placeholder: "Filter by Feature"},
    width: 25,
  },
];

class NonStitchedContentBlockContainer extends React.Component {
  formatRow({
    id,
    rowId,
    featureName,
    wordCountActual,
    maxEstimatedWordCount,
    minEstimatedWordCount,
    priority,
    order,
    translatedGroups,
    totalGroups,
    translatedAssets,
    totalAssets,
    contentGroupInformation,
    selectedContentBlock,
  }) {
    const wordCount = (
      <span className={classNames({"color-danger": wordCountActual > maxEstimatedWordCount})}>
        {wordCountActual}/{minEstimatedWordCount} - {maxEstimatedWordCount}
      </span>
    );
    const isActive = id === selectedContentBlock.id;
    const progress = Math.round((translatedAssets * 100) / totalAssets);
    const progressBar = <ProgressBar color="bg-color-primary" value={progress} fixedWidth={false} />;
    return {
      id,
      isSelected: isActive,
      data: {
        id,
        rowId,
        isActive,
        completion: progressBar,
        wordCount,
        priority,
        order,
        group: `${translatedGroups}/${totalGroups}`,
        featureName,
        contentGroupInformation,
      },
    };
  }

  handleCollapseToggle = () => {
    this.props.handleToggleAction(ToggleEnum.ContentBlocksCollapsed);
  };

  render() {
    const {
      selectedJob,
      contentBlocksTable,
      refreshingStats,
      selectedContentBlock,
      contentBlocksCollapsed,
      stats,
      fetchLtReviewContentBlocksAction,
      selectLtReviewContentBlockAction,
      startDeliverLtReviewJobAction,
    } = this.props;
    const momentDate = moment(selectedJob.dueDate).format("DD/MM/Y");
    const boxTitleText = NonStitchedContentBlockResources.BoxTitle({
      jobName: selectedJob.name,
      deliveryDeadline: momentDate,
      remainingDays: selectedJob.remainingDays,
    });
    const boxTitle = contentBlocksCollapsed ? (
      <div className="flex-container width-95 content-navigator-box-header">
        <Tooltip className="width-60" text={boxTitleText}>
          <Title h4 className="box-title width-100" text={boxTitleText} />
        </Tooltip>
        {!selectedJob.isReadonly && (
          <Button
            outlined
            key={"deliver-button-box"}
            icon="fa-paper-plane"
            className={"m-t-xs m-b-xs"}
            text={NonStitchedContentBlockResources.DeliverButtonText}
            onClick={this.props.startDeliverLtReviewJobAction.bind(this)}
          />
        )}
      </div>
    ) : (
      boxTitleText
    );

    const rowsData = contentBlocksTable.rows
      ? contentBlocksTable.rows.map((contentBlock) => this.formatRow({...contentBlock, selectedContentBlock}))
      : [];
    return (
      <div>
        <Box
          collapsible
          title={boxTitle}
          collapsed={contentBlocksCollapsed}
          elmId="content-block"
          onCollapseToggle={this.handleCollapseToggle}
        >
          {!contentBlocksCollapsed && (
            <ContentBlock
              elmId="content-block"
              title={NonStitchedContentBlockResources.ContentBlockTitle}
              informationTitle={NonStitchedContentBlockResources.ContentBlockInformationTitle}
              structureLabel={NonStitchedContentBlockResources.StructureLabel}
              deliverButtonText={NonStitchedContentBlockResources.DeliverButtonText}
              selectedJob={selectedJob}
              selectedContentBlock={selectedContentBlock}
              stats={stats}
              columns={columns}
              rows={rowsData}
              loading={refreshingStats}
              contentBlocksCollapsed={contentBlocksCollapsed}
              onDeliverJob={startDeliverLtReviewJobAction}
              onFetchContentBlocks={fetchLtReviewContentBlocksAction}
              onSelectContentBlock={selectLtReviewContentBlockAction}
            />
          )}
        </Box>
        <DeliverJobContainer />
      </div>
    );
  }
}

NonStitchedContentBlockContainer.propTypes = NonStitchedContentBlockContainerPropTypes;
NonStitchedContentBlockContainer.defaultProps = NonStitchedContentBlockContainerDefaultProps;

const mapStateToProps = (store) => {
  return {
    selectedJob: store.jobs.ltReviewWorkspace.selectedJob,
    contentBlocksTable: store.jobs.ltReviewWorkspace.contentBlocksTable,
    refreshingStats: store.jobs.ltReviewWorkspace.refreshingStats,
    contentBlocksCollapsed: store.layout.contentBlocksCollapsed,
    selectedContentBlock: store.jobs.ltReviewWorkspace.selectedContentBlock,
    stats: store.jobs.ltReviewWorkspace.stats,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLtReviewContentBlocksAction: ({jobId, tableState}) =>
      dispatch(fetchLtReviewContentBlocks({jobId, tableState})),
    selectLtReviewContentBlockAction: ({jobId, selectedContentBlock}) =>
      dispatch(selectLtReviewContentBlock({jobId, selectedContentBlock})),
    startDeliverLtReviewJobAction: () => dispatch(startDeliverLtReviewJob()),
    handleToggleAction: (key) => dispatch(handleToggle(key)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NonStitchedContentBlockContainer);
