import {SET_CURRENT_PAGE_TITLE, HANDLE_TOGGLE, EXPAND_ALL} from "store/actions/layout/layout.actions";

const initialState = {
  currentPageTitle: "",
  // Toggle
  translationStructuresCollapsed: false,
  translationLinesCollapsed: false,
  contentBlocksCollapsed: false,
};

const layout = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE_TITLE:
      return {...state, currentPageTitle: action.currentPageTitle};
    case HANDLE_TOGGLE:
      return {
        ...state,
        [action.value]: !state[action.value],
      };
    case EXPAND_ALL:
      return {
        ...state,
        translationLinesCollapsed: false,
        translationStructuresCollapsed: false,
        contentBlocksCollapsed: false,
      };
    default:
      return state;
  }
};

export default layout;
