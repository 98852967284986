export const AVAILABLE_LT_REVIEW_JOBS = "AVAILABLE_LT_REVIEW_JOBS";
export const CANCEL_LT_REVIEW = "CANCEL_LT_REVIEW";
export const ENABLE_LT_REVIEW = "ENABLE_LT_REVIEW";
export const COMPLETE_LT_REVIEW = "COMPLETE_LT_REVIEW";
export const REOPEN_LT_REVIEW = "REOPEN_LT_REVIEW";

export const CANCEL_BULK_LT_REVIEW = "CANCEL_BULK_LT_REVIEW";
export const ENABLE_BULK_LT_REVIEW = "ENABLE_BULK_LT_REVIEW";
export const COMPLETE_BULK_LT_REVIEW = "COMPLETE_BULK_LT_REVIEW";

export const ACTION_FAIL_LT_REVIEW = "ACTION_FAIL_LT_REVIEW";
export const ACTION_START_LT_REVIEW = "ACTION_START_LT_REVIEW";
export const ACTION_SUCCESS_LT_REVIEW = "ACTION_SUCCESS_LT_REVIEW";

export const SET_BULK_ACTION_EXECUTION = "SET_BULK_ACTION_EXECUTION";
export const SET_SIMPLE_ACTION_EXECUTION = "SET_SIMPLE_ACTION_EXECUTION";
export const SET_SIMPLE_ACTION_EXECUTION_FINISHED = "SET_SIMPLE_ACTION_EXECUTION_FINISHED";
export const CANCEL_EXECUTION_ACTION = "CANCEL_EXECUTION_ACTION";

/**
 * Deprecated, use React Query instead.
 * @param data availableJobs
 * @returns {{type: *, value}}
 */
export const setAvailableLtReviewJobsTableData = (data) => {
  return {
    type: AVAILABLE_LT_REVIEW_JOBS,
    value: data,
  };
};

export const cancelLtReview = (reviewId) => {
  return {
    type: CANCEL_LT_REVIEW,
    value: reviewId,
  };
};

export const enableLtReview = (reviewId) => {
  return {
    type: ENABLE_LT_REVIEW,
    value: reviewId,
  };
};

export const completeLtReview = (reviewId) => {
  return {
    type: COMPLETE_LT_REVIEW,
    value: reviewId,
  };
};

export const reopenLtReview = (reviewId) => {
  return {
    type: REOPEN_LT_REVIEW,
    value: reviewId,
  };
};

export const bulkCancelReviews = (reviewId) => {
  return {
    type: CANCEL_BULK_LT_REVIEW,
    value: reviewId,
  };
};

export const bulkEnableReviews = (reviewId) => {
  return {
    type: ENABLE_BULK_LT_REVIEW,
    value: reviewId,
  };
};

export const bulkCompleteReviews = (reviewId) => {
  return {
    type: COMPLETE_BULK_LT_REVIEW,
    value: reviewId,
  };
};

export const setBulkExecution = (action, idAction) => {
  return {
    type: SET_BULK_ACTION_EXECUTION,
    value: {action, idAction},
  };
};

export const setSimpleExecution = (action, idAction, jobId) => {
  return {
    type: SET_SIMPLE_ACTION_EXECUTION,
    value: {action, idAction, jobId},
  };
};

export const cancelExecution = () => {
  return {
    type: CANCEL_EXECUTION_ACTION,
    value: null,
  };
};
