import {createStore, applyMiddleware, compose} from "redux";
import createSagaMiddleware from "redux-saga";

import promiseMiddleware from "store/middlewares/promise.middleware";
import thunk from "store/middlewares/thunk.middleware";
import appReducers from "store/reducers";
import rootSaga from "store/sagas";

export default function configureStore(initialState?) {
  const composeEnhancers =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line no-underscore-dangle
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        // eslint-disable-next-line no-underscore-dangle
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  const sagaMiddleware =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    typeof createSagaMiddleware === "function" ? createSagaMiddleware() : createSagaMiddleware.default();
  const enhancer = composeEnhancers(applyMiddleware(promiseMiddleware, thunk, sagaMiddleware));

  const store = createStore(appReducers, initialState, enhancer);
  sagaMiddleware.run(rootSaga);

  return store;
}
