export const ApiConfigEnum = {
  API_URL_BASE: process.env.APP_API_URL_BASE,
  DEFAULT_OPTIONS: {
    retries: 3,
    timeout: 60000,
  },
  HTTP_STATUSES: {
    unauthorized: 401,
    forbidden: 403,
    internalError: 500,
    unprocessable: 422,
    notFound: 404,
  },
} as const;
