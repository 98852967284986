import {deleteTokensData} from "../../shared/helpers/tokens.helper";
import {Service} from "../service";

function logOutRequestHandler(refreshTokenKey) {
  return new Promise((resolve, reject) => {
    Service.post({url: "users/logout", data: {refreshTokenKey}})
      .then(() => {
        deleteTokensData();
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const UserService = {
  fetchUserActions: () => {
    return Service.get({url: "actions/menu"});
  },
  fetchCurrentUserInfo: () => {
    return Service.get({url: "users/current"});
  },
  logOut: (refreshTokenKey) => {
    return logOutRequestHandler(refreshTokenKey);
  },
};

export default UserService;
