import {
  AVAILABLE_LT_REVIEW_JOBS, // deprecated, use React Query instead
  ACTION_START_LT_REVIEW,
  ACTION_SUCCESS_LT_REVIEW,
  ACTION_FAIL_LT_REVIEW,
  SET_BULK_ACTION_EXECUTION,
  CANCEL_EXECUTION_ACTION,
  SET_SIMPLE_ACTION_EXECUTION_FINISHED,
} from "store/actions/jobs/job-selector/lt-review-job-selector/ltReviewJobSelector.actions";

const initialState = {
  availableJobs: {}, // deprecated
  actionInProgress: false,
  actionConfirmationModalVisible: false,
  actionIdExecuted: "",
  actionToExecute: null,
  stats: {},
};

const ltReviewSelector = (state = initialState, action) => {
  switch (action.type) {
    case AVAILABLE_LT_REVIEW_JOBS:
      return {
        ...state,
        availableJobs: action.value,
      };
    case SET_SIMPLE_ACTION_EXECUTION_FINISHED:
      return {
        ...state,
        ...action.value,
      };
    case SET_BULK_ACTION_EXECUTION:
      return {
        ...state,
        actionInProgress: false,
        actionConfirmationModalVisible: true,
        actionIdExecuted: action.value.idAction,
        actionToExecute: action.value.action,
      };
    case ACTION_START_LT_REVIEW:
      return {
        ...state,
        actionInProgress: true,
      };
    case ACTION_SUCCESS_LT_REVIEW:
    case ACTION_FAIL_LT_REVIEW:
      return {
        ...state,
        actionConfirmationModalVisible: false,
        actionInProgress: false,
      };
    case CANCEL_EXECUTION_ACTION:
      return {
        ...state,
        actionInProgress: false,
        actionConfirmationModalVisible: false,
        actionIdExecuted: "",
        actionToExecute: null,
      };
    default:
      return state;
  }
};

export default ltReviewSelector;
