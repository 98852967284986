import {takeLatest, call, put} from "redux-saga/effects";

import MasterService from "services/master/master.service";

import {
  GET_VERSION_DATA,
  GET_VERSION_DATA_START,
  GET_VERSION_DATA_SUCCESS,
  GET_VERSION_DATA_FAIL,
  GET_LANGUAGES,
  GET_LANGUAGES_START,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAIL,
} from "store/actions/master/master.actions";

function* getVersion() {
  try {
    yield put({type: GET_VERSION_DATA_START});
    const versionData = yield call(MasterService.getVersionData);
    yield put({type: GET_VERSION_DATA_SUCCESS, value: versionData});
  } catch (error) {
    yield put({type: GET_VERSION_DATA_FAIL, value: error});
  }
}

function* getLanguages() {
  try {
    yield put({type: GET_LANGUAGES_START});
    const languages = yield call(MasterService.getProjectLanguages);
    yield put({type: GET_LANGUAGES_SUCCESS, value: languages});
  } catch (error) {
    yield put({type: GET_LANGUAGES_FAIL, value: error});
  }
}

export default [takeLatest(GET_VERSION_DATA, getVersion), takeLatest(GET_LANGUAGES, getLanguages)];
