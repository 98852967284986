export const GET_TOKEN = "GET_TOKEN";
export const GET_TOKEN_START = "GET_TOKEN_START";
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS";
export const GET_TOKEN_FAIL = "GET_TOKEN_FAIL";

type GetTokenParams =
  | {userName: string; password: string}
  | {refreshToken: string}
  | {
      userName: string;
      password: string;
      refreshToken: string;
    };

export const getToken = (value: GetTokenParams) => ({
  type: GET_TOKEN,
  value,
});
