import {call, fork, take, cancel} from "redux-saga/effects";

// TODO: There is an open merge request with this proposal, when it's merge we can import this helper directly from the redux-saga/effects
// You can check the merge request here: https://github.com/redux-saga/redux-saga/pull/1744 (If you do it and it's merge, please delete this message and use the helper directly)

export const takeLatestPerKey = (patternOrChannel, worker, keySelector, ...args) => {
  function* takeLatestPerKeyGenerator() {
    const tasks = {};

    while (true) {
      const action = yield take(patternOrChannel);
      const key = yield call(keySelector, action);

      if (tasks[key]) {
        yield cancel(tasks[key]);
      }

      tasks[key] = yield fork(worker, ...args, action);
    }
  }
  return fork(takeLatestPerKeyGenerator);
};
