import classnames from "classnames";
import PropTypes from "prop-types";
import {Field, Button} from "ui_toolkit";

const LineInfoPropTypes = {
  parameters: PropTypes.arrayOf(PropTypes.object),
  minEstimatedWordcount: PropTypes.number,
  maxEstimatedWordcount: PropTypes.number,
  wordcountActual: PropTypes.number,
  parametersColorMap: PropTypes.object,
};

const LineInfoDefaultProps = {
  parameters: [],
  minEstimatedWordcount: 0,
  maxEstimatedWordcount: 0,
  wordcountActual: 0,
  parametersColorMap: {},
};

function LineInfo({parameters, wordcountActual, maxEstimatedWordcount, minEstimatedWordcount, parametersColorMap}) {
  const renderParameterBadge = (id, name, index) => {
    const color = parametersColorMap[name];
    return (
      <Button
        small
        basic
        key={`${id}${index}`}
        className="m-b-sm m-r-xxs"
        backgroundColor={color}
        clickable={false}
        textColor="shades-text-white"
        text={name}
      />
    );
  };
  return (
    <div className="flex-container justify-content-flex-start flex-nowrap white-space-nowrap">
      <Field className="m-r-md" text="Wordcount">
        <span className={classnames("flex-grow-1", {"color-danger": wordcountActual > maxEstimatedWordcount})}>
          {wordcountActual}/{minEstimatedWordcount}-{maxEstimatedWordcount}
        </span>
      </Field>
      <div className="separator" />
      <Field text="Line Params">
        {parameters.length ? (
          <div>{parameters.map(({id, name}, index) => renderParameterBadge(id, name, index))}</div>
        ) : (
          <p>No Parameters</p>
        )}
      </Field>
    </div>
  );
}

LineInfo.propTypes = LineInfoPropTypes;
LineInfo.defaultProps = LineInfoDefaultProps;

export default LineInfo;
