import {RoutesEnum} from "router/routes/routes.enum";

import {acquireAccessToken} from "../../configs/auth.config";

import {ApiConfigEnum} from "./enums/apiConfig.enum";
import {LocalStorageServiceKeysEnum} from "./enums/localStorageServiceKeys.enum";
import {errorMessageFormatter} from "./helpers/errorMessage.helper";
import {LoginService} from "./login/login.service";
import axios from "./service.config";

async function makeRequest({method, url, data, params, authentication = true}) {
  const msalToken = await acquireAccessToken();
  const regularToken = sessionStorage.getItem(LocalStorageServiceKeysEnum.BEARER_TOKEN_KEY);

  return new Promise((resolve, reject) => {
    axios({
      method,
      url,
      params,
      data,
      headers: {
        // For the regularToken we don't need to add Bearer because it is already returned with it from the server
        authorization: msalToken ? `Bearer ${msalToken}` : regularToken || null,
      },
    })
      .then((response) => resolve(response.data || response))
      .catch((error) => {
        if (!error.response) {
          reject(error.message);
        } else if (authentication && error.response.status === ApiConfigEnum.HTTP_STATUSES.unauthorized) {
          const refreshToken = localStorage.getItem(LocalStorageServiceKeysEnum.REFRESH_TOKEN_KEY);
          LoginService.getTokens({refreshToken}).then(() => makeRequest({method, url, data, authentication}));
        } else if (authentication && error.response.status === ApiConfigEnum.HTTP_STATUSES.forbidden) {
          window.location = RoutesEnum.Error.ContentForbidden;
        } else if (
          error.response.data &&
          error.response.data.ErrorMessages &&
          error.response.data.ErrorMessages.length > 0
        ) {
          reject(error.response.data.ErrorMessages.reduce((message, acc) => acc.concat(` ${message}`), ""));
        } else if (error.response.data && error.response.data.message) {
          reject(error.response.data.message);
        } else {
          reject(errorMessageFormatter(error.response.status));
        }
      });
  });
}

export const Service = {
  post: async (params) => await makeRequest({method: "post", ...params}),
  put: async (params) => await makeRequest({method: "put", ...params}),
  get: async (params) => await makeRequest({method: "get", ...params}),
  patch: async (params) => await makeRequest({method: "patch", ...params}),
};
