import "./lt-review-job-content-lines.css";

import PropTypes from "prop-types";
import {connect} from "react-redux";

import ContentNavigator from "features/jobs/job-workspace/components/content-navigator/ContentNavigator";

import BatchType from "shared/enums/batchType.enum.js";

import {
  fetchLtReviewLines,
  fetchNextUnreviewedLines,
  setActivePreviousLtReviewLine,
  setActiveNextLtReviewLine,
  goToLtReviewLine,
} from "store/actions/jobs/job-workspace/lt-review-job-workspace/lines/ltReviewJobWorkspaceLines.actions";

import {KeysEnum} from "../../../../../../shared/enums/keys.enum";

const LinesNavigatorContainerPropTypes = {
  linesTable: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedJob: PropTypes.object.isRequired,
  selectedContentBlock: PropTypes.object.isRequired,
  selectedStructure: PropTypes.object.isRequired,
  selectedLine: PropTypes.object.isRequired,
  fetchLtReviewLinesAction: PropTypes.func.isRequired,
  fetchNextUnreviewedLinesAction: PropTypes.func.isRequired,
  setActivePreviousLtReviewLineAction: PropTypes.func.isRequired,
  setActiveNextLtReviewLineAction: PropTypes.func.isRequired,
  goToLtReviewLineAction: PropTypes.func.isRequired,
  numberOfLines: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  collapsed: PropTypes.bool.isRequired,
  onCollapseToggle: PropTypes.func.isRequired,
};

const LinesNavigatorContainerDefaultProps = {
  numberOfLines: 0,
};

const LinesNavigatorContainer = (props) => {
  const handleNextUntranslatedLine = () => {
    props.fetchNextUnreviewedLinesAction({
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      structureId: props.selectedStructure.structureId,
      tableState: props.linesTable,
      selectedLine: props.selectedLine,
    });
  };

  const handleNext = () => {
    props.setActiveNextLtReviewLineAction({
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      structureId: props.selectedStructure.structureId,
      tableState: props.linesTable,
      selectedLine: props.selectedLine,
    });
  };

  const handlePrevious = () => {
    props.setActivePreviousLtReviewLineAction({
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      structureId: props.selectedStructure.structureId,
      tableState: props.linesTable,
      selectedLine: props.selectedLine,
    });
  };

  const handleGoTo = (rowId) => {
    props.goToLtReviewLineAction({
      rowId,
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      structureId: props.selectedStructure.structureId,
      tableState: props.linesTable,
      clearFilters: true, // TODO: Review
    });
  };

  const handleTableChange = (tableState) => {
    props.fetchLtReviewLinesAction({
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      structureId: props.selectedStructure.structureId,
      tableState,
      selectedLine: props.selectedLine,
    });
  };

  const handleTableRowSelect = ({rowId}) => {
    props.goToLtReviewLineAction({
      rowId,
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      structureId: props.selectedStructure.structureId,
      tableState: props.linesTable,
    });
  };

  const keyboardShortcuts = {
    next: {
      key: KeysEnum.ArrowRight,
      callback: handleNext,
      condition: props.selectedLine.rowId < Number(props.numberOfLines),
      tooltip: "Ctrl + Alt + Arrow Right",
    },
    previous: {
      key: KeysEnum.ArrowLeft,
      callback: handlePrevious,
      condition: props.selectedLine.rowId > 1,
      tooltip: "Ctrl + Alt + Arrow Left",
    },
  };

  return (
    <ContentNavigator
      elmId="lines-navigator"
      batchType={BatchType.Review}
      {...props}
      currentLine={props.selectedLine.rowId}
      keyboardShortcuts={keyboardShortcuts}
      fixedWidth={true}
      onNext={handleNext}
      onPrevious={handlePrevious}
      onNextUntranslatedLine={handleNextUntranslatedLine}
      onGoTo={handleGoTo}
      onTableChange={handleTableChange}
      onTableRowSelect={handleTableRowSelect}
    />
  );
};

LinesNavigatorContainer.propTypes = LinesNavigatorContainerPropTypes;
LinesNavigatorContainer.defaultProps = LinesNavigatorContainerDefaultProps;

const mapDispatchToProps = {
  fetchLtReviewLinesAction: fetchLtReviewLines,
  fetchNextUnreviewedLinesAction: fetchNextUnreviewedLines,
  setActivePreviousLtReviewLineAction: setActivePreviousLtReviewLine,
  setActiveNextLtReviewLineAction: setActiveNextLtReviewLine,
  goToLtReviewLineAction: goToLtReviewLine,
};

export default connect(null, mapDispatchToProps)(LinesNavigatorContainer);
