import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Toaster} from "ui_toolkit";

import {removeToast} from "store/actions/toaster/toaster.actions";

const LayoutPropTypes = {
  toasts: PropTypes.object.isRequired,
  removeToastAction: PropTypes.func.isRequired,
};

const Layout = ({toasts, removeToastAction}) => {
  return <Toaster key="toaster" toasts={toasts.toJS()} onRemoveToast={removeToastAction} />;
};

Layout.propTypes = LayoutPropTypes;

const mapStateToProps = (store) => ({
  toasts: store.toaster.toasts,
});

const mapDispatchToProps = {
  removeToastAction: removeToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
