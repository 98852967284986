import {useEffect, useState} from "react";

import {InteractionType} from "@azure/msal-browser";
import {useIsAuthenticated, useMsalAuthentication} from "@azure/msal-react";
import {useNavigate} from "react-router-dom";
import {RoutesEnum} from "router/routes/routes.enum";

import {Service} from "services/service";

export const useHasPermissions = () => {
  const isAuthenticated = useIsAuthenticated();
  const [hasPermissions, setHasPermissions] = useState(false);
  const navigate = useNavigate();

  /**
   * UseMsalAuthentication Hook
   * Will check if a user is signed in and then attempt to sign a user in if there are no users signed in.
   * It will return the result of the login operation, any error that occurred and the login function you can use if you need to retry.
   * More info: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md#usemsalauthentication-hook
   */
  useMsalAuthentication(InteractionType.Redirect);
  useEffect(() => {
    /**
     * If the user is not registered we need to register him/her in our API, by calling any endpoint
     * This URL should be replaced by a specific one, or BE should accept parallel calls and avoid creating several users
     */
    if (isAuthenticated) {
      Service.get({url: "/users/current"})
        .then(() => {
          setHasPermissions(true);
        })
        .catch(() => navigate(RoutesEnum.Login));
    }
  }, [isAuthenticated, navigate]);

  return hasPermissions;
};
