import {TableFilterBuilder, TablePaginationBuilder} from "ui_toolkit";

const buildJobsTableRequestData = ({filters, page, pageSize, sorting}) => {
  const requestPaginationData = TablePaginationBuilder({page, pageSize, sorting});
  const requestFiltersData = {};
  if (filters && Object.keys(filters).length) {
    requestFiltersData.filters = new TableFilterBuilder()
      .anAndOperationThat()
      .hasEqual({name: "projectId", value: filters.project})
      .contains({name: "name", value: filters.name})
      .hasEqual({name: "language", value: filters.language})
      .hasEqual({name: "type", value: filters.type})
      .in({name: "status", value: filters.status})
      .hasEqual({name: "wordCount", value: filters.wordCount})
      .hasEqual({name: "estimatedWordCount", value: filters.estimatedWordCount})
      .hasEqual({name: "lines", value: filters.lines})
      .greaterOrEqual({name: "creationDate", value: filters.creationDate})
      .greaterOrEqual({name: "deadline", value: filters.deadline})
      .hasEqual({name: "deadLineStatus", value: filters.deadLineStatus})
      .hasEqual({name: "user", value: filters.user})
      .hasEqual({name: "type", value: filters.type})
      .build();
  }

  return {filters: requestFiltersData, ...requestPaginationData};
};

const buildContentBlockTableRequestData = ({filters, sorting}) => {
  const requestPaginationData = TablePaginationBuilder({sorting});
  const requestFiltersData = {};
  if (filters && Object.keys(filters).length) {
    requestFiltersData.filters = new TableFilterBuilder()
      .anAndOperationThat()
      .contains({name: "featureName", value: filters.featureName})
      .hasEqual({name: "order", value: filters.order})
      .hasEqual({name: "priority", value: filters.priority})
      .contains({name: "rating", value: filters.rating})
      .build();
  }

  return {filters: requestFiltersData, ...requestPaginationData};
};

const buildStructuresTableRequestData = ({filters, ...paginationData}) => {
  const requestPaginationData = TablePaginationBuilder(paginationData);
  const requestFiltersData = {};
  if (filters && Object.keys(filters).length) {
    requestFiltersData.filters = new TableFilterBuilder()
      .anAndOperationThat()
      .contains({name: "name", value: filters.name})
      .build();
  }
  return {filters: requestFiltersData, ...requestPaginationData};
};

const buildLinesTableRequestData = ({filters, ...paginationData}) => {
  const requestPaginationData = TablePaginationBuilder(paginationData);
  const requestFiltersData = {};
  if (filters && Object.keys(filters).length) {
    requestFiltersData.filters = new TableFilterBuilder().anAndOperationThat();

    Object.keys(filters).forEach((key) => {
      requestFiltersData.filters = requestFiltersData.filters.contains({name: key, value: filters[key]});
    });

    requestFiltersData.filters = requestFiltersData.filters.build();
  }
  return {filters: requestFiltersData, ...requestPaginationData};
};

const buildLtReviewLinesTableRequestData = ({filters, ...paginationData}) => {
  const requestPaginationData = TablePaginationBuilder(paginationData);
  const requestFiltersData = {};
  if (filters && Object.keys(filters).length) {
    requestFiltersData.filters = new TableFilterBuilder().anAndOperationThat();
    Object.keys(filters).forEach((key) => {
      if (key === "isModified") {
        requestFiltersData.filters = requestFiltersData.filters.hasEqual({name: "isModified", value: filters[key]});
      } else {
        requestFiltersData.filters = requestFiltersData.filters.contains({name: key, value: filters[key]});
      }
    });
    requestFiltersData.filters = requestFiltersData.filters.build();
  }
  return {filters: requestFiltersData, ...requestPaginationData};
};

export {
  buildContentBlockTableRequestData,
  buildJobsTableRequestData,
  buildStructuresTableRequestData,
  buildLinesTableRequestData,
  buildLtReviewLinesTableRequestData,
};
