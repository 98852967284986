import type {$TSFixme} from "src/types/TSFixme";

import {Service} from "../../service";
import {buildJobsTableRequestData, buildContentBlockTableRequestData} from "../shared/job.service.helper";

import TranslationJobLinesService from "./translationJobLines.service";
import TranslationJobStructuresService from "./translationJobStructures.service";

const TranslationJobService = {
  fetchJobsTableData: (tableState) => {
    const requestFiltersData = buildJobsTableRequestData(tableState);
    return Service.get({
      url: "jobs",
      params: requestFiltersData,
    });
  },

  fetchJob: (jobId) =>
    Service.get({
      url: `jobs/${jobId}/summary`,
    }),

  fetchContentBlocks: ({jobId, tableState = {} as $TSFixme}) => {
    const requestFiltersData = buildContentBlockTableRequestData(tableState);
    const url = `jobs/${jobId}/contentgroups`;

    return Service.get({
      url,
      params: requestFiltersData,
    });
  },

  fetchStats: (jobId) =>
    Service.get({
      url: `jobs/${jobId}/stats`,
    }),

  fetchStatsAfterUpdate: ({jobId, contentGroupId, structureId}) =>
    Service.get({
      url: `jobs/${jobId}/contentgroup/${contentGroupId}/structure/${structureId}/stats`,
    }),

  deliverJob: (jobId) =>
    Service.post({
      url: `jobs/${jobId}/delivery`,
    }),

  getJobDataFromExternalData: ({externalId, projectId}) =>
    Service.get({
      url: `reviews/search?externalId=${externalId}&projectId=${projectId}`,
    }),

  fetchProjects: () =>
    Service.get({
      url: "project",
    }),

  fetchVendors: () =>
    Service.get({
      url: "vendors",
    }),
};

export default {...TranslationJobService, ...TranslationJobStructuresService, ...TranslationJobLinesService};
