import {GET_TOKEN_START, GET_TOKEN_SUCCESS, GET_TOKEN_FAIL} from "store/actions/login/login.actions";

const initialState = {
  fetchTokenProgress: {
    loading: false,
    errors: null,
  },
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOKEN_START:
      return {
        fetchTokenProgress: {
          loading: true,
          errors: null,
        },
      };
    case GET_TOKEN_SUCCESS:
      return {
        fetchTokenProgress: {
          loading: false,
          errors: null,
        },
      };
    case GET_TOKEN_FAIL:
      return {
        fetchTokenProgress: {
          loading: false,
          errors: action.value,
        },
      };
    default:
      return state;
  }
};

export default login;
