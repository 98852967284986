const NonStitchedTranslationLinesResources = {
  TranslationLinesBoxTitle: ({rowId = 0, progress = 0}) =>
    `Lines to translate for: Structure #${rowId} - (${progress}% lines translated)`,
  LineType: "Line",
  FilterPlaceholder: "Filter by Asset",
  Translatable: "Translatable",
  noTranslatable: "Not Translatable",
  TranslatableIcon: "fa-file-edit far color-success",
  NoTranslatableIcon: "fa-ban far color-basic",
};

export default NonStitchedTranslationLinesResources;
