export const TRANSLATION_LINES_FETCH = "TRANSLATION_LINES_FETCH";
export const TRANSLATION_LINES_SET_ACTIVE_NEXT = "TRANSLATION_LINES_SET_ACTIVE_NEXT";
export const TRANSLATION_LINES_SET_ACTIVE_PREVIOUS = "TRANSLATION_LINES_SET_ACTIVE_PREVIOUS";
export const TRANSLATION_LINES_GO_TO = "TRANSLATION_LINES_GO_TO";
export const TRANSLATION_LINES_NEXT_UNTRANSLATED = "TRANSLATION_LINES_NEXT_UNTRANSLATED";
export const TRANSLATION_LINE_SAVE_AND_REFRESH = "TRANSLATION_LINE_SAVE_AND_REFRESH";

export const TRANSLATION_LINE_SAVE_START = "TRANSLATION_LINE_SAVE_START";
export const TRANSLATION_LINE_SAVE_FINISHED = "TRANSLATION_LINE_SAVE_FINISHED";

export const TRANSLATION_ACTIVE_LINE_SET = "TRANSLATION_ACTIVE_LINE_SET";
export const TRANSLATION_SELECTED_LINE_UPDATE = "TRANSLATION_SELECTED_LINE_UPDATE";

export const fetchTranslationLines = (requestData) => {
  return {
    type: TRANSLATION_LINES_FETCH,
    value: requestData,
  };
};

export const fetchNextUntranslatedLine = (requestData) => {
  return {
    type: TRANSLATION_LINES_NEXT_UNTRANSLATED,
    value: requestData,
  };
};

export const setActiveNextTranslationLine = (requestData) => {
  return {
    type: TRANSLATION_LINES_SET_ACTIVE_NEXT,
    value: requestData,
  };
};

export const setActivePreviousTranslationLine = (requestData) => {
  return {
    type: TRANSLATION_LINES_SET_ACTIVE_PREVIOUS,
    value: requestData,
  };
};

export const goToTranslationLine = (requestData) => {
  return {
    type: TRANSLATION_LINES_GO_TO,
    value: requestData,
  };
};

export const saveAndRefreshLine = ({id, ...value}) => ({
  type: TRANSLATION_LINE_SAVE_AND_REFRESH,
  id,
  value,
});

export const setActiveTranslationAsset = (activeAsset) => ({
  type: TRANSLATION_ACTIVE_LINE_SET,
  value: activeAsset,
});
