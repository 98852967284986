import PropTypes from "prop-types";
import {connect} from "react-redux";

import ContentNavigator from "features/jobs/job-workspace/components/content-navigator/ContentNavigator";

import BatchType from "shared/enums/batchType.enum.js";
import {KeysEnum} from "shared/enums/keys.enum";

import {
  fetchTranslationLines,
  fetchNextUntranslatedLine,
  setActivePreviousTranslationLine,
  setActiveNextTranslationLine,
  goToTranslationLine,
} from "store/actions/jobs/job-workspace/translation-job-workspace/lines/translationJobWorkspaceLines.actions";

const LinesNavigatorContainerPropTypes = {
  linesTable: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedJob: PropTypes.object.isRequired,
  selectedContentBlock: PropTypes.object.isRequired,
  selectedStructure: PropTypes.object.isRequired,
  selectedLine: PropTypes.object.isRequired,
  fetchTranslationLinesAction: PropTypes.func.isRequired,
  fetchNextUntranslatedLineAction: PropTypes.func.isRequired,
  setActivePreviousTranslationLineAction: PropTypes.func.isRequired,
  setActiveNextTranslationLineAction: PropTypes.func.isRequired,
  goToTranslationLineAction: PropTypes.func.isRequired,
  numberOfLines: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  collapsed: PropTypes.bool.isRequired,
  onCollapseToggle: PropTypes.func.isRequired,
};

const LinesNavigatorContainerDefaultProps = {
  numberOfLines: 0,
};

const LinesNavigatorContainer = (props) => {
  const handleNextUntranslatedLine = () => {
    props.fetchNextUntranslatedLineAction({
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      structureId: props.selectedStructure.structureId,
      tableState: props.linesTable,
      selectedLine: props.selectedLine,
    });
  };

  const handleNext = () => {
    props.setActiveNextTranslationLineAction({
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      structureId: props.selectedStructure.structureId,
      tableState: props.linesTable,
      selectedLine: props.selectedLine,
    });
  };

  const handlePrevious = () => {
    props.setActivePreviousTranslationLineAction({
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      structureId: props.selectedStructure.structureId,
      tableState: props.linesTable,
      selectedLine: props.selectedLine,
    });
  };

  const handleGoTo = (rowId) => {
    props.goToTranslationLineAction({
      rowId,
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      structureId: props.selectedStructure.structureId,
      tableState: props.linesTable,
      clearFilters: true, // TODO: Review
    });
  };

  const handleTableChange = (tableState) => {
    props.fetchTranslationLinesAction({
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      structureId: props.selectedStructure.structureId,
      tableState,
      selectedLine: props.selectedLine,
    });
  };

  const handleTableRowSelect = ({rowId}) => {
    props.goToTranslationLineAction({
      rowId,
      jobId: props.selectedJob.id,
      contentGroupId: props.selectedContentBlock.id,
      structureId: props.selectedStructure.structureId,
      tableState: props.linesTable,
    });
  };

  const keyboardShortcuts = {
    next: {
      key: KeysEnum.ArrowRight,
      callback: handleNext,
      condition: props.selectedLine.rowId < Number(props.numberOfLines),
      tooltip: "Ctrl + Alt + Arrow Right",
    },
    previous: {
      key: KeysEnum.ArrowLeft,
      callback: handlePrevious,
      condition: props.selectedLine.rowId > 1,
      tooltip: "Ctrl + Alt + Arrow Left",
    },
  };

  return (
    <ContentNavigator
      elmId="lines-navigator"
      batchType={BatchType.Translation}
      {...props}
      fixedWidth
      currentLine={props.selectedLine.rowId}
      keyboardShortcuts={keyboardShortcuts}
      onNext={handleNext}
      onPrevious={handlePrevious}
      onNextUntranslatedLine={handleNextUntranslatedLine}
      onGoTo={handleGoTo}
      onTableChange={handleTableChange}
      onTableRowSelect={handleTableRowSelect}
    />
  );
};

LinesNavigatorContainer.propTypes = LinesNavigatorContainerPropTypes;
LinesNavigatorContainer.defaultProps = LinesNavigatorContainerDefaultProps;

const mapDispatchToProps = {
  fetchTranslationLinesAction: fetchTranslationLines,
  fetchNextUntranslatedLineAction: fetchNextUntranslatedLine,
  setActivePreviousTranslationLineAction: setActivePreviousTranslationLine,
  setActiveNextTranslationLineAction: setActiveNextTranslationLine,
  goToTranslationLineAction: goToTranslationLine,
};

export default connect(null, mapDispatchToProps)(LinesNavigatorContainer);
