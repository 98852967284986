import {PublicClientApplication} from "@azure/msal-browser";

const AUTH = {
  clientId: "38ca08f3-f4b2-4b4b-8571-44ad9ddb3b65",
  scopes: ["api://38ca08f3-f4b2-4b4b-8571-44ad9ddb3b65/Adlib.ReadWrite"],
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: AUTH.clientId,
    authority: "https://login.microsoftonline.com/cc74fc12-4142-400e-a653-f98bfa4b03ba", // https://{uri}/{tenantId}
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: AUTH.scopes,
};

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
export const msalInstance = new PublicClientApplication(msalConfig);

/**
 * AcquireTokenSilent method of instance:
 * Request object passed by user to retrieve tokens from the cache
 * Renew an expired token with a refresh token
 * More info: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md#acquiring-an-access-token
 *
 * About Refresh Tokens
 * The expiration time for an Access Token is 1hr.
 * The refresh token has a 24 hour, non-sliding expiration time.
 * If you renew a refresh token before it expires, the new refresh token will have the same expiration time as the previous one.
 * More info: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
 */
export const acquireAccessToken = async () => {
  /**
   * We are setting this variable in the test.config because we couldn't mock the MSAL library.
   * TODO: Configure MSAL testing configuration and mocks to fully test it and remove this
   */
  // @ts-expect-error explained above
  if (window.testingEnviroment) {
    return "access_token";
  }

  const accounts = msalInstance.getAllAccounts();

  if (accounts.length > 0) {
    return msalInstance
      .acquireTokenSilent({account: accounts[0], ...loginRequest})
      .then((res) => res.accessToken)
      .catch(() => msalInstance.acquireTokenRedirect({account: accounts[0], ...loginRequest}));
  }

  return null;
};
