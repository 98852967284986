import moment from "moment";

import {LocalStorageServiceKeysEnum} from "../../services/enums/localStorageServiceKeys.enum";

function getRefreshTokenKey() {
  return localStorage.getItem(LocalStorageServiceKeysEnum.REFRESH_TOKEN_KEY);
}

function tokenIsValid() {
  const bearerToken = sessionStorage.getItem(LocalStorageServiceKeysEnum.BEARER_TOKEN_KEY);
  const bearerTokenExpiresIn = sessionStorage.getItem(LocalStorageServiceKeysEnum.BEARER_TOKEN_EXPIRES_IN_KEY);
  return bearerToken && moment(bearerTokenExpiresIn).isAfter(moment());
}

function saveTokensData(loginData) {
  sessionStorage.setItem(LocalStorageServiceKeysEnum.BEARER_TOKEN_KEY, `Bearer ${loginData.access_token}`);
  sessionStorage.setItem(LocalStorageServiceKeysEnum.BEARER_TOKEN_EXPIRES_IN_KEY, loginData.expires_in);
  localStorage.setItem(LocalStorageServiceKeysEnum.REFRESH_TOKEN_KEY, loginData.refresh_token);
}

function deleteTokensData() {
  sessionStorage.removeItem(LocalStorageServiceKeysEnum.BEARER_TOKEN_KEY);
  sessionStorage.removeItem(LocalStorageServiceKeysEnum.BEARER_TOKEN_EXPIRES_IN_KEY);
  localStorage.removeItem(LocalStorageServiceKeysEnum.REFRESH_TOKEN_KEY);
}

export {getRefreshTokenKey, tokenIsValid, saveTokensData, deleteTokensData};
