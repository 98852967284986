import {saveTokensData, deleteTokensData} from "shared/helpers/tokens.helper";

import {Service} from "../service";

export type GetTokensParams =
  | {
      userName: string;
      password: string;
      refreshToken?: undefined;
    }
  | {
      refreshToken: string;
      userName?: undefined;
      password?: undefined;
    };

type GrantType = "Password" | "refreshToken";

function tokensRequestHandler({params, grantType}: {params: GetTokensParams; grantType: GrantType}) {
  return new Promise<void>((resolve, reject) => {
    Service.post({
      url: "tokens",
      data: {...params, grant_type: grantType},
      authentication: false,
    })
      .then((loginData) => {
        saveTokensData(loginData);
        resolve();
      })
      .catch((error) => {
        deleteTokensData();
        reject(error);
      });
  });
}

const LoginService = {
  getTokens: ({password, refreshToken, userName}: GetTokensParams) => {
    return refreshToken
      ? tokensRequestHandler({params: {refreshToken}, grantType: "refreshToken"})
      : tokensRequestHandler({params: {userName, password}, grantType: "Password"});
  },
};

export {LoginService};
