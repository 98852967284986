import PropTypes from "prop-types";

import ReferenceLine from "features/jobs/job-workspace/components/content-area/reference-line/ReferenceLine";

import {useIsRtl} from "shared/hooks/useIsRtl";
import {generateColorsMapById} from "shared/utils/colorMapGenerator";

import ContextInfo from "../context-info/ContextInfo";
import LineEditor from "../line-editor/LineEditor";
import LineInfo from "../line-info/LineInfo";
import LineStatus from "../line-status/LineStatus";

const ContentAreaPropTypes = {
  lineSaveInProgress: PropTypes.bool.isRequired,
  lineSaveError: PropTypes.string.isRequired,
  activeAsset: PropTypes.object.isRequired,
  assetsMap: PropTypes.object.isRequired,
  referenceLineTitle: PropTypes.string.isRequired,
  contextDescriptionTitle: PropTypes.string.isRequired,
  notesTitle: PropTypes.string.isRequired,
  selectedLine: PropTypes.object.isRequired,
  batchTopology: PropTypes.number,
  language: PropTypes.object.isRequired,
  structureLabel: PropTypes.string.isRequired,
  isReadonlyJob: PropTypes.bool,
  onCheckboxChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onChangeActiveAsset: PropTypes.func.isRequired,
  onGenderChange: PropTypes.func.isRequired,
};

const ContentAreaDefaultProps = {
  isReadonlyJob: true,
  batchTopology: 0,
};

const ContentArea = ({
  lineSaveInProgress,
  lineSaveError,
  activeAsset,
  assetsMap,
  referenceLineTitle,
  contextDescriptionTitle,
  notesTitle,
  selectedLine,
  language,
  batchTopology,
  structureLabel,
  isReadonlyJob,
  onCheckboxChange,
  onTextChange,
  onChangeActiveAsset,
  onGenderChange,
}) => {
  const {
    description = "",
    minEstimatedWordcount,
    maxEstimatedWordcount,
    wordcountActual,
    parameters,
    lineId,
  } = selectedLine;
  const {notes = ""} = activeAsset;
  const rightToLeft = useIsRtl(language.id);
  const parametersColorMap = parameters ? generateColorsMapById(parameters) : {};

  return (
    <div className="content-area">
      <LineStatus lineSaveInProgress={lineSaveInProgress} lineSaveError={lineSaveError} />
      <ContextInfo
        contextDescriptionTitle={contextDescriptionTitle}
        notesTitle={notesTitle}
        description={description}
        notes={notes}
        structureLabel={structureLabel}
      />
      <ReferenceLine
        title={referenceLineTitle}
        assets={selectedLine.assets || []}
        activeAssetId={activeAsset.assetId || 0}
      />
      <LineInfo
        parameters={parameters}
        parametersColorMap={parametersColorMap}
        wordcountActual={wordcountActual}
        maxEstimatedWordcount={maxEstimatedWordcount}
        minEstimatedWordcount={minEstimatedWordcount}
      />
      <LineEditor
        activeAsset={activeAsset}
        assetsMap={assetsMap}
        rightToLeft={rightToLeft}
        parametersColorMap={parametersColorMap}
        isReadonlyJob={isReadonlyJob}
        batchTopology={batchTopology}
        lineId={lineId}
        onCheckboxChange={onCheckboxChange}
        onTextChange={onTextChange}
        onChangeActiveAsset={onChangeActiveAsset}
        onGenderChange={onGenderChange}
      />
    </div>
  );
};

ContentArea.propTypes = ContentAreaPropTypes;
ContentArea.defaultProps = ContentAreaDefaultProps;

export default ContentArea;
