import {takeLatest, takeEvery, call, put, select} from "redux-saga/effects";

import TranslationJobService from "services/jobs/translation-job/translationJob.service";

import {TRANSLATION_SELECTED_STRUCTURE_UPDATE} from "store/actions/jobs/job-workspace/translation-job-workspace/structures/translationJobWorkspaceStructures.actions";
import {
  TRANSLATION_JOB_FETCH,
  TRANSLATION_LOADING_START,
  TRANSLATION_ACTION_SUCCESS,
  TRANSLATION_ACTION_FAIL,
  TRANSLATION_CONTENT_BLOCKS_FETCH,
  TRANSLATION_STATS_FETCH,
  TRANSLATION_CLEAR_JOB_DATA,
  TRANSLATION_CONTENT_BLOCK_SELECT,
  TRANSLATION_STATS_SET,
  TRANSLATION_SELECTED_CONTENT_GROUP_INFO_UPDATE,
  TRANSLATION_JOB_STATS_START,
  TRANSLATION_JOB_STATS_FINISH,
} from "store/actions/jobs/job-workspace/translation-job-workspace/translationJobWorkspace.actions";
import {EXPAND_ALL} from "store/actions/layout/layout.actions";
import {createErrorToast} from "store/actions/toaster/toaster.actions";
import {buildStructureContentGroupKey} from "store/helpers/jobs.helper";
import {updateSelectedRow} from "store/utils/assetsUpdate";

import {getTranslationWorkspaceContainers} from "../../resolvers/translationJobWorkspaceContainer.resolver";

import {linesSagas} from "./lines/translationJobWorkspaceLines.sagas";
import {
  fetchNextUntranslatedStructures,
  structuresSagas,
} from "./structures/translation-job-workspace-structures.sagas";

const getTranslationWorkspace = (state) => state.jobs.translationWorkspace;

function* getJob({value}) {
  const {goBack, jobId} = value;
  try {
    yield put({type: TRANSLATION_LOADING_START});
    const selectedJob = yield call(TranslationJobService.fetchJob, jobId);

    yield call(setContainersByTopology, {value: {selectedJob}});
    yield call(getStats, {value: {jobId}});
    yield call(fetchNextUntranslatedContentBlocks, {value: {jobId}});
    yield put({type: EXPAND_ALL});
    yield put({type: TRANSLATION_ACTION_SUCCESS, value: {selectedJob}});
  } catch (error) {
    yield put({type: TRANSLATION_ACTION_FAIL});
    goBack();
    yield put(createErrorToast(error || "Error getting the job information"));
  }
}

function* setContainersByTopology({value}) {
  const {selectedJob} = value;
  const workspaceContainers = getTranslationWorkspaceContainers(selectedJob.batchTopologyId);
  if (workspaceContainers) {
    yield put({type: TRANSLATION_ACTION_SUCCESS, value: {workspaceContainers}});
  } else {
    throw `"${selectedJob.name}" has an unknown topology`;
  }
}

function* getStats({value}) {
  try {
    const {jobId} = value;
    const stats = yield call(TranslationJobService.fetchStats, jobId);
    yield put({type: TRANSLATION_STATS_SET, value: stats});
  } catch (error) {
    yield put({type: TRANSLATION_ACTION_FAIL});
    yield put(createErrorToast(error || "Error getting stats information"));
  }
}

function* fetchNextUntranslatedContentBlocks({value}) {
  try {
    const {jobId} = value;
    const contentBlocks = yield call(TranslationJobService.fetchContentBlocks, {jobId});
    const selectedContentBlock = contentBlocks.find(({isSelected}) => isSelected === true) || contentBlocks[0] || {};
    yield call(selectContentBlock, {value: {jobId, selectedContentBlock}});
    yield put({type: TRANSLATION_ACTION_SUCCESS, value: {contentBlocksTable: {rows: contentBlocks}}});
  } catch (error) {
    yield put({type: TRANSLATION_ACTION_FAIL});
    yield put(createErrorToast(error || "Error getting content blocks information"));
  }
}

function* getContentBlocks({value}) {
  try {
    const {jobId, tableState} = value;
    const contentBlocks = yield call(TranslationJobService.fetchContentBlocks, {jobId, tableState});
    yield put({type: TRANSLATION_ACTION_SUCCESS, value: {contentBlocksTable: {...tableState, rows: contentBlocks}}});
  } catch (error) {
    yield put({type: TRANSLATION_ACTION_FAIL, value: {contentBlocksTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

function* selectContentBlock({value}) {
  yield put({type: TRANSLATION_LOADING_START});
  const {jobId, selectedContentBlock} = value;

  if (selectedContentBlock.id) {
    yield call(fetchNextUntranslatedStructures, {
      value: {jobId, contentGroupId: selectedContentBlock.id, tableState: {pageSize: 10}},
      loading: true,
    });
  }

  // TODO: Review
  // yield put({type: TRANSLATION_STRUCTURES_CLEAR_FILTERS});

  yield put({type: TRANSLATION_ACTION_SUCCESS, value: {selectedContentBlock, loading: value.loading || false}});
}

function* clearJobData() {
  yield put({type: TRANSLATION_ACTION_SUCCESS, value: {structuresTable: {}}});
}

function* updateContentBlock({contentGroup, stats, contentBlocksTable, selectedContentBlock}) {
  let contentBlockStats = {
    totalAssets: contentGroup.totalAssets,
    translatedAssets: contentGroup.translatedAssets,
    wordCountActual: contentGroup.wordcountActual,
    translatedGroups: stats.translatedGroups,
    totalGroups: stats.totalGroups,
  };
  const [contentGroupRows] = updateSelectedRow({
    table: contentBlocksTable,
    id: contentGroup.contentGroupId,
    idSelector: "id",
    stats: contentBlockStats,
  });
  // If the ContentBlock to update is different from the selectedContentBlock, this means that the selected ContentBlock changed, so we must not update it
  if (contentGroup.contentGroupId !== selectedContentBlock.id) {
    contentBlockStats = {};
  }
  yield put({type: TRANSLATION_SELECTED_CONTENT_GROUP_INFO_UPDATE, value: {contentGroupRows, contentBlockStats}});
}

function* updateStructure({structure, contentGroup, structuresTable, selectedStructure}) {
  let structureStats = {
    totalLines: structure.totalLines,
    translatedLines: structure.translatedLines,
    wordcountActual: structure.wordcountActual,
    progress: structure.progress,
  };
  const [structureRows] = updateSelectedRow({
    table: structuresTable,
    id: structure.structureId,
    idSelector: "structureId",
    stats: structureStats,
  });
  // If the Structure to update is different from the selectedStructure, this means that the selected Structure changed, so we must not update it
  if (structure.structureId !== selectedStructure.structureId) {
    structureStats = {};
  }
  yield put({
    type: TRANSLATION_SELECTED_STRUCTURE_UPDATE,
    value: {structureRows, structureStats, hasUntranslatedStructures: contentGroup.hasUntranslatedStructures},
  });
}

// Update the stats and the affected line, structure and content block, only if rendered
export function* refreshData({jobId, contentGroupId, structureId}) {
  yield put({type: TRANSLATION_JOB_STATS_START, value: {contentGroupId, structureId}});

  const {stats, contentGroup, structure} = yield call(TranslationJobService.fetchStatsAfterUpdate, {
    jobId,
    contentGroupId,
    structureId,
  });

  // Update Stats
  yield put({type: TRANSLATION_STATS_SET, value: stats});

  const {contentBlocksTable, selectedContentBlock, structuresTable, selectedStructure, statsOutOfDate} =
    yield select(getTranslationWorkspace);

  yield updateContentBlock({contentGroup, stats, contentBlocksTable, selectedContentBlock});

  yield updateStructure({structure, contentGroup, structuresTable, selectedStructure});

  if (statsOutOfDate[buildStructureContentGroupKey({contentGroupId, structureId})]) {
    yield refreshData({jobId, contentGroupId, structureId});
  } else {
    yield put({type: TRANSLATION_JOB_STATS_FINISH, value: {contentGroupId, structureId}});
  }
}

export default [
  takeLatest(TRANSLATION_JOB_FETCH, getJob),
  takeLatest(TRANSLATION_CONTENT_BLOCKS_FETCH, getContentBlocks),
  takeLatest(TRANSLATION_CONTENT_BLOCK_SELECT, selectContentBlock),
  takeLatest(TRANSLATION_CLEAR_JOB_DATA, clearJobData),
  takeEvery(TRANSLATION_STATS_FETCH, refreshData),
  ...structuresSagas,
  ...linesSagas,
];
