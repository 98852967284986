import {takeLatest, call, put} from "redux-saga/effects";

import LtReviewJobService from "services/jobs/lt-review-job/ltReviewJob.service";

import {LT_REVIEW_LINES_CLEAR_FILTERS} from "store/actions/jobs/job-workspace/lt-review-job-workspace/lines/ltReviewJobWorkspaceLines.actions";
import {
  LT_REVIEW_ACTION_FAIL,
  LT_REVIEW_ACTION_SUCCESS,
  LT_REVIEW_LOADING_START,
} from "store/actions/jobs/job-workspace/lt-review-job-workspace/ltReviewJobWorkspace.actions";
import {
  LT_REVIEW_STRUCTURES_FETCH,
  LT_REVIEW_STRUCTURES_GO_TO,
  LT_REVIEW_STRUCTURES_NEXT_UNREVIEWED,
  LT_REVIEW_STRUCTURES_SET_ACTIVE_NEXT,
  LT_REVIEW_STRUCTURES_SET_ACTIVE_PREVIOUS,
} from "store/actions/jobs/job-workspace/lt-review-job-workspace/structures/ltReviewJobWorkspaceStructures.actions";
import {createErrorToast} from "store/actions/toaster/toaster.actions";

import {fetchNextUnreviewedLine} from "../lines/ltReviewJobWorkspaceLines.sagas";

function* selectStructure({value}) {
  yield put({type: LT_REVIEW_LOADING_START});
  const {jobId, contentGroupId, selectedStructure, structureIndex, structurePage} = value;

  if (selectedStructure.structureId) {
    yield call(fetchNextUnreviewedLine, {
      value: {jobId, contentGroupId, structureId: selectedStructure.structureId, tableState: {pageSize: 25}},
    });
  }

  yield put({
    type: LT_REVIEW_ACTION_SUCCESS,
    value: {selectedStructure: {...selectedStructure, structureIndex, structurePage}, loading: value.loading || false},
  });
}

function* fetchNextUnreviewedStructuresAndMarkAsReviewed({value}) {
  try {
    yield put({type: LT_REVIEW_LOADING_START});
    const {jobId, contentGroupId, selectedStructure = {}, tableState = {}} = value;
    const {rowId = 0} = selectedStructure;

    let structuresTable = yield call(LtReviewJobService.nextUnreviewedStructure, {
      jobId,
      contentGroupId,
      selectedRow: rowId,
      tableState,
    });

    const {pageSize, page, totalRows} = structuresTable;
    const newSelectedStructureIndex = structuresTable.rows.findIndex(({isSelected}) => isSelected === true);
    const newSelectedStructure = structuresTable.rows[newSelectedStructureIndex] || {};

    let newStructureIndex = newSelectedStructureIndex + pageSize * (page - 1);
    newStructureIndex = newStructureIndex >= totalRows ? 1 : newStructureIndex + 1;

    yield call(selectStructure, {
      value: {
        jobId,
        contentGroupId,
        selectedStructure: newSelectedStructure,
        structureIndex: newStructureIndex,
        structurePage: page,
      },
    });
    structuresTable = {...tableState, ...structuresTable};
    yield put({
      type: LT_REVIEW_ACTION_SUCCESS,
      value: {structuresTable, loading: value.loading || false, reviewed: false},
    });
  } catch (error) {
    yield put({type: LT_REVIEW_ACTION_FAIL, value: {loading: false, structuresTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

function* setActiveNextStructureAndMarkAsReviewed({value}) {
  try {
    yield put({type: LT_REVIEW_LOADING_START});

    const {tableState, jobId, contentGroupId, selectedStructure} = value;
    const {pageSize, page} = tableState;
    const {structureIndex, structurePage, rowId} = selectedStructure;
    const activeRowIndex = structureIndex - pageSize * (structurePage - 1) - 1;
    if (page !== selectedStructure.structurePage) {
      yield call(goToStructure, {value: {jobId, contentGroupId, rowId, tableState: selectedStructure}});
    } else if (activeRowIndex >= pageSize - 1) {
      yield call(goToNextPageStructure, {value: {jobId, contentGroupId, selectedStructure, tableState}});
    } else {
      const newSelectedStructure = tableState.rows[activeRowIndex + 1];
      yield call(selectStructure, {
        value: {
          jobId,
          contentGroupId,
          selectedStructure: newSelectedStructure,
          structureIndex: structureIndex + 1,
          structurePage: tableState.page,
        },
      });

      yield put({type: LT_REVIEW_LINES_CLEAR_FILTERS});
      yield put({type: LT_REVIEW_ACTION_SUCCESS, value: {loading: false}});
    }
  } catch (error) {
    yield put({type: LT_REVIEW_ACTION_FAIL, value: {loading: false, structuresTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

function* setActivePreviousStructureAndMarkAsReviewed({value}) {
  try {
    yield put({type: LT_REVIEW_LOADING_START});

    const {tableState, jobId, contentGroupId, selectedStructure} = value;
    const {pageSize, page} = tableState;
    const {structureIndex, structurePage, rowId} = selectedStructure;
    const activeRowIndex = structureIndex - pageSize * (structurePage - 1) - 1;
    if (page !== selectedStructure.structurePage) {
      yield call(goToStructure, {value: {jobId, contentGroupId, rowId, tableState: selectedStructure}});
    } else if (activeRowIndex <= 0) {
      yield call(goToPreviousPageStructure, {value: {jobId, contentGroupId, selectedStructure, tableState}});
    } else {
      const newSelectedStructure = tableState.rows[activeRowIndex - 1];
      yield call(selectStructure, {
        value: {
          jobId,
          contentGroupId,
          selectedStructure: newSelectedStructure,
          structureIndex: structureIndex - 1,
          structurePage: tableState.page,
        },
      });

      yield put({type: LT_REVIEW_LINES_CLEAR_FILTERS});
      yield put({type: LT_REVIEW_ACTION_SUCCESS, value: {loading: false}});
    }
  } catch (error) {
    yield put({type: LT_REVIEW_ACTION_FAIL, value: {loading: false, structuresTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

function* goToNextPageStructure({value}) {
  try {
    yield put({type: LT_REVIEW_LOADING_START});
    const {jobId, contentGroupId, selectedStructure, tableState} = value;
    let structuresTable = yield call(LtReviewJobService.fetchStructures, {
      jobId,
      contentGroupId,
      selectedRow: selectedStructure.rowId,
      tableState: {
        ...tableState,
        page: selectedStructure.structurePage + 1,
      },
    });

    const newSelectedStructure = structuresTable.rows[0] || {};
    const newSelectedStructureIndex = selectedStructure.structureIndex + 1;
    yield call(selectStructure, {
      value: {
        jobId,
        contentGroupId,
        selectedStructure: newSelectedStructure,
        structureIndex: newSelectedStructureIndex,
        structurePage: structuresTable.page,
      },
    });

    structuresTable = {...tableState, ...structuresTable};

    yield put({type: LT_REVIEW_LINES_CLEAR_FILTERS});
    yield put({type: LT_REVIEW_ACTION_SUCCESS, value: {structuresTable, loading: false}});
  } catch (error) {
    yield put({type: LT_REVIEW_ACTION_FAIL, value: {loading: false, structuresTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

function* goToPreviousPageStructure({value}) {
  try {
    yield put({type: LT_REVIEW_LOADING_START});

    const {jobId, contentGroupId, selectedStructure, tableState} = value;
    let structuresTable = yield call(LtReviewJobService.fetchStructures, {
      jobId,
      contentGroupId,
      selectedRow: selectedStructure.rowId,
      tableState: {
        ...tableState,
        page: selectedStructure.structurePage - 1,
      },
    });

    const newSelectedStructure = structuresTable.rows[tableState.pageSize - 1] || {};
    const newSelectedStructureIndex = selectedStructure.structureIndex - 1;
    yield call(selectStructure, {
      value: {
        jobId,
        contentGroupId,
        selectedStructure: newSelectedStructure,
        structureIndex: newSelectedStructureIndex,
        structurePage: structuresTable.page,
      },
    });

    structuresTable = {...tableState, ...structuresTable};

    yield put({type: LT_REVIEW_LINES_CLEAR_FILTERS});
    yield put({type: LT_REVIEW_ACTION_SUCCESS, value: {structuresTable, loading: false, reviewed: false}});
  } catch (error) {
    yield put({type: LT_REVIEW_ACTION_FAIL, value: {loading: false, structuresTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

function* goToStructure({value}) {
  try {
    yield put({type: LT_REVIEW_LOADING_START});
    const {jobId, contentGroupId, rowId, tableState} = value;
    let structuresTable = yield call(LtReviewJobService.goToStructurePageById, {
      jobId,
      contentGroupId,
      rowId,
      tableState,
    });

    const newSelectedStructureIndex = structuresTable.rows.findIndex(({isSelected}) => isSelected === true);
    const index = newSelectedStructureIndex >= 0 ? newSelectedStructureIndex : 0;
    const newSelectedStructure = structuresTable.rows[index] || {};
    const structureIndex = index + structuresTable.pageSize * (structuresTable.page - 1) + 1;
    yield call(selectStructure, {
      value: {
        jobId,
        contentGroupId,
        selectedStructure: newSelectedStructure,
        structureIndex,
        structurePage: structuresTable.page,
      },
    });

    // TODO: Review
    // if (clearFilters) {
    //   yield put({type: LT_REVIEW_STRUCTURES_CLEAR_FILTERS}); // "GoTo" Action should clear the filters
    // }

    structuresTable = {...tableState, ...structuresTable};
    yield put({type: LT_REVIEW_LINES_CLEAR_FILTERS});
    yield put({type: LT_REVIEW_ACTION_SUCCESS, value: {structuresTable, loading: false}});
  } catch (error) {
    yield put({type: LT_REVIEW_ACTION_FAIL, value: {loading: false, structuresTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

function* goToStructureAndMarkAsReviewed({value}) {
  try {
    yield put({type: LT_REVIEW_LOADING_START});
    const {jobId, contentGroupId, rowId, tableState} = value;

    let structuresTable = yield call(LtReviewJobService.goToStructurePageById, {
      jobId,
      contentGroupId,
      rowId,
      tableState,
    });

    const newSelectedStructureIndex = structuresTable.rows.findIndex(({isSelected}) => isSelected === true);
    const index = newSelectedStructureIndex >= 0 ? newSelectedStructureIndex : 0;
    const newSelectedStructure = structuresTable.rows[index] || {};
    const structureIndex = index + structuresTable.pageSize * (structuresTable.page - 1) + 1;
    yield call(selectStructure, {
      value: {
        jobId,
        contentGroupId,
        selectedStructure: newSelectedStructure,
        structureIndex,
        structurePage: structuresTable.page,
      },
    });

    // TODO: Review
    // if (clearFilters) {
    //   yield put({type: LT_REVIEW_STRUCTURES_CLEAR_FILTERS}); // "GoTo" Action should clear the filters
    // }

    structuresTable = {...tableState, ...structuresTable};
    yield put({type: LT_REVIEW_LINES_CLEAR_FILTERS});
    yield put({type: LT_REVIEW_ACTION_SUCCESS, value: {structuresTable, loading: false, reviewed: false}});
  } catch (error) {
    yield put({type: LT_REVIEW_ACTION_FAIL, value: {loading: false, structuresTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

export function* fetchNextUnreviewedStructures({value}) {
  try {
    yield put({type: LT_REVIEW_LOADING_START});
    const {jobId, contentGroupId, selectedStructure = {}, tableState = {}} = value;
    const {rowId = 0} = selectedStructure;

    let structuresTable = yield call(LtReviewJobService.nextUnreviewedStructure, {
      jobId,
      contentGroupId,
      selectedRow: rowId,
      tableState,
    });

    const {pageSize, page, totalRows} = structuresTable;
    const newSelectedStructureIndex = structuresTable.rows.findIndex(({isSelected}) => isSelected === true);
    const newSelectedStructure = structuresTable.rows[newSelectedStructureIndex] || {};

    let newStructureIndex = newSelectedStructureIndex + pageSize * (page - 1);
    newStructureIndex = newStructureIndex >= totalRows ? 1 : newStructureIndex + 1;

    yield call(selectStructure, {
      value: {
        jobId,
        contentGroupId,
        selectedStructure: newSelectedStructure,
        structureIndex: newStructureIndex,
        structurePage: page,
      },
    });
    structuresTable = {...tableState, ...structuresTable};
    yield put({type: LT_REVIEW_ACTION_SUCCESS, value: {structuresTable, loading: value.loading || false}});
  } catch (error) {
    yield put({type: LT_REVIEW_ACTION_FAIL, value: {loading: false, structuresTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

export function* fetchStructures({value}) {
  try {
    const {jobId, contentGroupId, selectedStructure = {}, tableState} = value;

    let structuresTable = yield call(LtReviewJobService.fetchStructures, {
      jobId,
      contentGroupId,
      tableState,
      selectedRow: selectedStructure.rowId,
    });
    structuresTable = {...tableState, ...structuresTable};

    const {selectedIndex, selectedIndexPage} = structuresTable;

    yield put({
      type: LT_REVIEW_ACTION_SUCCESS,
      value: {
        structuresTable,
        selectedStructure: {...selectedStructure, structureIndex: selectedIndex, structurePage: selectedIndexPage},
      },
    });
  } catch (error) {
    yield put({type: LT_REVIEW_ACTION_FAIL, value: {structuresTable: value.tableState}});
    yield put(createErrorToast(error));
  }
}

export const structuresSagas = [
  takeLatest(LT_REVIEW_STRUCTURES_FETCH, fetchStructures),
  takeLatest(LT_REVIEW_STRUCTURES_SET_ACTIVE_NEXT, setActiveNextStructureAndMarkAsReviewed),
  takeLatest(LT_REVIEW_STRUCTURES_SET_ACTIVE_PREVIOUS, setActivePreviousStructureAndMarkAsReviewed),
  takeLatest(LT_REVIEW_STRUCTURES_GO_TO, goToStructureAndMarkAsReviewed),
  takeLatest(LT_REVIEW_STRUCTURES_NEXT_UNREVIEWED, fetchNextUnreviewedStructuresAndMarkAsReviewed),
];
