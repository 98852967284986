/* eslint-disable no-case-declarations */
import {Map} from "immutable";

import {
  TRANSLATION_LINE_SAVE_START,
  TRANSLATION_LINE_SAVE_FINISHED,
  TRANSLATION_ACTIVE_LINE_SET,
  TRANSLATION_SELECTED_LINE_UPDATE,
} from "store/actions/jobs/job-workspace/translation-job-workspace/lines/translationJobWorkspaceLines.actions";
import {TRANSLATION_SELECTED_STRUCTURE_UPDATE} from "store/actions/jobs/job-workspace/translation-job-workspace/structures/translationJobWorkspaceStructures.actions";
import {
  TRANSLATION_LOADING_START,
  TRANSLATION_ACTION_SUCCESS,
  TRANSLATION_ACTION_FAIL,
  TRANSLATION_JOB_DELIVER_CANCEL,
  TRANSLATION_JOB_DELIVER_START,
  TRANSLATION_CLEAR_WORKSPACE,
  TRANSLATION_JOB_DELIVER_IN_PROGRESS,
  TRANSLATION_STATS_SET,
  TRANSLATION_SELECTED_CONTENT_GROUP_INFO_UPDATE,
  TRANSLATION_JOB_STATS_START,
  TRANSLATION_JOB_STATS_FINISH,
} from "store/actions/jobs/job-workspace/translation-job-workspace/translationJobWorkspace.actions";
import {buildStructureContentGroupKey} from "store/helpers/jobs.helper";
import {updateAssetsInLinesRows} from "store/utils/assetsUpdate";

const initialState = {
  workspaceContainers: [],
  loading: false,
  selectedJob: {},
  deliverConfirmationModalVisible: false,
  deliverInProgress: false,
  // Content Blocks
  contentBlocksTable: {},
  selectedContentBlock: {},
  // Structures
  structuresTable: {},
  selectedStructure: {},
  // Lines
  linesTable: {
    pageSize: 25,
  },
  selectedLine: {},
  lineSaveInProgress: false,
  lineSaveError: "",
  // Translation Area
  assetsMap: Map(),
  activeAsset: {},
  // Stats
  refreshingStats: false,
  stats: {},
  statsOutOfDate: {},
  loadingStats: {},
};

const translationWorkspace = (state = initialState, action) => {
  switch (action.type) {
    case TRANSLATION_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case TRANSLATION_ACTION_SUCCESS:
      return {
        ...state,
        ...action.value,
      };
    case TRANSLATION_ACTION_FAIL:
      return {
        ...state,
        loading: false,
        ...action.value,
      };
    case TRANSLATION_JOB_DELIVER_CANCEL:
      return {
        ...state,
        deliverConfirmationModalVisible: false,
      };
    case TRANSLATION_JOB_DELIVER_START:
      return {
        ...state,
        deliverConfirmationModalVisible: true,
      };
    case TRANSLATION_JOB_DELIVER_IN_PROGRESS:
      return {
        ...state,
        deliverInProgress: action.value,
      };
    case TRANSLATION_LINE_SAVE_START:
      return {
        ...state,
        linesTable: {
          ...state.linesTable,
          rows: updateAssetsInLinesRows({rows: state.linesTable.rows, assetInfo: action.value.assetInfo}),
        },
        lineSaveInProgress: true,
        statsOutOfDate: {...state.statsOutOfDate, [buildStructureContentGroupKey(action.value.selectedLine)]: true},
        assetsMap: action.value.assetsMap,
      };
    case TRANSLATION_LINE_SAVE_FINISHED:
      return {
        ...state,
        lineSaveInProgress: false,
      };
    case TRANSLATION_ACTIVE_LINE_SET:
      return {
        ...state,
        activeAsset: action.value,
      };
    case TRANSLATION_CLEAR_WORKSPACE:
      return {
        ...initialState,
      };
    case TRANSLATION_STATS_SET:
      return {
        ...state,
        stats: action.value,
      };
    case TRANSLATION_SELECTED_CONTENT_GROUP_INFO_UPDATE:
      const {contentGroupRows, contentBlockStats} = action.value;
      return {
        ...state,
        contentBlocksTable: {...state.contentBlocksTable, rows: contentGroupRows},
        selectedContentBlock: {...state.selectedContentBlock, ...contentBlockStats},
      };
    case TRANSLATION_SELECTED_STRUCTURE_UPDATE:
      const {structureRows, hasUntranslatedStructures, structureStats} = action.value;
      return {
        ...state,
        structuresTable: {
          ...state.structuresTable,
          rows: structureRows,
          hasUntranslatedLines: hasUntranslatedStructures,
        },
        selectedStructure: {...state.selectedStructure, ...structureStats},
      };
    case TRANSLATION_SELECTED_LINE_UPDATE:
      const {lineRows, lineStats} = action.value;
      return {
        ...state,
        linesTable: {...state.linesTable, rows: lineRows},
        selectedLine: {...state.selectedLine, ...lineStats},
      };
    case TRANSLATION_JOB_STATS_START:
      return {
        ...state,
        refreshingStats: true,
        loadingStats: {...state.loadingStats, [buildStructureContentGroupKey(action.value)]: true},
        statsOutOfDate: {...state.statsOutOfDate, [buildStructureContentGroupKey(action.value)]: false},
      };
    case TRANSLATION_JOB_STATS_FINISH:
      return {
        ...state,
        refreshingStats: false,
        loadingStats: {...state.loadingStats, [buildStructureContentGroupKey(action.value)]: false},
      };
    default:
      return state;
  }
};

export default translationWorkspace;
