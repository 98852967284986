import PropTypes from "prop-types";

import TableContainer from "features/shared/table-container/TableContainer";

const ContentBlockTablePropTypes = {
  elmId: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  onLinkClick: PropTypes.func.isRequired,
};

function ContentBlockTable({elmId, onChange, onLinkClick, columns, rows}) {
  return (
    <TableContainer
      selectableRows
      elmId={elmId}
      columns={columns}
      rows={rows}
      pagination={false}
      onRowSelected={onLinkClick}
      onChange={onChange}
    />
  );
}

ContentBlockTable.propTypes = ContentBlockTablePropTypes;

export default ContentBlockTable;
