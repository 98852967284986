import {useEffect} from "react";

import PropTypes from "prop-types";
import {Input, Button, Tooltip} from "ui_toolkit";

import ContentNavigatorContainerResources from "../contentNavigatorContainer.resources";

const ContentNavigatorControlsPropTypes = {
  batchType: PropTypes.object.isRequired,
  lineType: PropTypes.string,
  currentLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  numberOfLines: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasUntranslatedLines: PropTypes.bool,
  goToValue: PropTypes.string,
  goToDisabled: PropTypes.bool,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNextUntranslatedLine: PropTypes.func.isRequired,
  onGoToInputChange: PropTypes.func.isRequired,
  onGoTo: PropTypes.func.isRequired,
  keyboardShortcuts: PropTypes.object,
};
const ContentNavigatorControlsDefaultProps = {
  lineType: "",
  goToValue: "",
  goToDisabled: false,
  currentLine: 0,
  numberOfLines: 0,
  hasUntranslatedLines: false,
  keyboardShortcuts: {},
};

const ContentNavigatorControls = ({
  batchType,
  lineType,
  currentLine,
  numberOfLines,
  hasUntranslatedLines,
  goToValue,
  goToDisabled,
  onNext,
  onPrevious,
  onNextUntranslatedLine,
  onGoToInputChange,
  onGoTo,
  keyboardShortcuts,
}) => {
  const {NextUnprocessedLineButton, CurrentLineVsTotal, GoTo, GoToPlaceholder} = ContentNavigatorContainerResources;

  useEffect(() => {
    const handleShortcuts = (event) => {
      const shortcutName = Object.keys(keyboardShortcuts).find((name) => keyboardShortcuts[name].key === event.key);
      if (event.ctrlKey === true && event.altKey === true && shortcutName) {
        const shortcut = keyboardShortcuts[shortcutName];
        if (shortcut?.condition && shortcut?.callback) shortcut.callback();
      }
    };

    if (Object.keys(keyboardShortcuts).length > 0) document.addEventListener("keydown", handleShortcuts);
    return () => Object.keys(keyboardShortcuts).length > 0 && document.removeEventListener("keydown", handleShortcuts);
  }, [keyboardShortcuts]);

  const renderWithTooltip = (element, text) => {
    return text ? <Tooltip text={text}>{element}</Tooltip> : element;
  };

  const nextButton = <Button icon="fa-angle-left" disabled={Number(currentLine) <= 1} onClick={onPrevious} />;
  const previousButton = (
    <Button icon="fa-angle-right" disabled={Number(currentLine) >= Number(numberOfLines)} onClick={onNext} />
  );
  return (
    <div className="content-navigator-controls align-items-center m-b-sm m-t-sm">
      <div className="counter flex-container align-items-center">
        {renderWithTooltip(nextButton, keyboardShortcuts.previous?.tooltip)}
        <div className="m-l-xs m-r-xs">{CurrentLineVsTotal({currentLine, numberOfLines})}</div>
        {renderWithTooltip(previousButton, keyboardShortcuts.next?.tooltip)}
      </div>
      <div className="buttons">
        <Button
          text={NextUnprocessedLineButton({batchType, lineType})}
          disabled={!hasUntranslatedLines}
          onClick={onNextUntranslatedLine}
        />
      </div>
      <div className="go-to flex-container align-items-center">
        <Button
          disabled={goToDisabled}
          text={GoTo}
          className="go-to-line flex-container align-items-center"
          onClick={goToDisabled ? null : onGoTo}
        />
        <Input
          className="m-l-xs"
          value={goToValue}
          placeholder={GoToPlaceholder}
          onChange={onGoToInputChange}
          onEnterPress={goToDisabled ? null : onGoTo}
        />
      </div>
    </div>
  );
};

ContentNavigatorControls.propTypes = ContentNavigatorControlsPropTypes;
ContentNavigatorControls.defaultProps = ContentNavigatorControlsDefaultProps;

export default ContentNavigatorControls;
