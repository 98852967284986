export const TRANSLATION_LOADING_START = "TRANSLATION_LOADING_START";
export const TRANSLATION_ACTION_SUCCESS = "TRANSLATION_ACTION_SUCCESS";
export const TRANSLATION_ACTION_FAIL = "TRANSLATION_ACTION_FAIL";
export const TRANSLATION_CLEAR_JOB_DATA = "TRANSLATION_CLEAR_JOB_DATA";
export const TRANSLATION_JOB_FETCH = "TRANSLATION_JOB_FETCH";

export const TRANSLATION_STATS_FETCH = "TRANSLATION_STATS_FETCH";
export const TRANSLATION_STATS_SET = "TRANSLATION_STATS_SET";
export const TRANSLATION_JOB_STATS_START = "TRANSLATION_JOB_STATS_START";
export const TRANSLATION_JOB_STATS_FINISH = "TRANSLATION_JOB_STATS_FINISH";

export const TRANSLATION_CONTENT_BLOCKS_FETCH = "TRANSLATION_CONTENT_BLOCKS_FETCH";
export const TRANSLATION_CONTENT_BLOCK_SELECT = "TRANSLATION_CONTENT_BLOCK_SELECT";

export const TRANSLATION_JOB_DELIVER = "TRANSLATION_JOB_DELIVER";
export const TRANSLATION_JOB_DELIVER_START = "TRANSLATION_JOB_DELIVER_START";
export const TRANSLATION_JOB_DELIVER_IN_PROGRESS = "TRANSLATION_JOB_DELIVER_IN_PROGRESS";
export const TRANSLATION_JOB_DELIVER_CANCEL = "TRANSLATION_JOB_DELIVER_CANCEL";

export const TRANSLATION_CLEAR_WORKSPACE = "TRANSLATION_CLEAR_WORKSPACE";

export const TRANSLATION_SELECTED_CONTENT_GROUP_INFO_UPDATE = "TRANSLATION_SELECTED_CONTENT_GROUP_INFO_UPDATE";

export const fetchTranslationJob = (requestData) => {
  return {
    type: TRANSLATION_JOB_FETCH,
    value: requestData,
  };
};

export const fetchTranslationContentBlocks = (requestData) => {
  return {
    type: TRANSLATION_CONTENT_BLOCKS_FETCH,
    value: requestData,
  };
};

export const selectTranslationContentBlock = (requestData) => {
  return {
    type: TRANSLATION_CONTENT_BLOCK_SELECT,
    value: requestData,
  };
};

export const deliverTranslationJob = (jobId) => {
  return {
    type: TRANSLATION_JOB_DELIVER,
    value: jobId,
  };
};

export const cancelDeliverTranslationJob = () => {
  return {
    type: TRANSLATION_JOB_DELIVER_CANCEL,
  };
};

export const startDeliverTranslationJob = () => {
  return {
    type: TRANSLATION_JOB_DELIVER_START,
  };
};

export const clearTranslationWorkspace = () => {
  return {
    type: TRANSLATION_CLEAR_WORKSPACE,
  };
};
