import {all} from "redux-saga/effects";

import externalJobSagas from "./jobs/external-job/externalJob.sagas";
import ltReviewJobSelectorSagas from "./jobs/job-selector/lt-review-job-selector/ltReviewJobSelector.sagas";
import ltReviewJobWorkspaceSagas from "./jobs/job-workspace/lt-review-job-workspace/ltReviewJobWorkspace.sagas";
import translationJobWorkspaceSagas from "./jobs/job-workspace/translation-job-workspace/translationJobWorkspace.sagas";
import masterSagas from "./master/master.sagas";
import actionsSagas from "./users/user.sagas";

export default function* rootSaga() {
  yield all([
    ...masterSagas,
    ...ltReviewJobSelectorSagas,
    ...translationJobWorkspaceSagas,
    ...ltReviewJobWorkspaceSagas,
    ...actionsSagas,
    ...externalJobSagas,
  ]);
}
