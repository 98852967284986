import {OrderedMap, Map} from "immutable";

import {ADD_TOAST, REMOVE_TOAST} from "store/actions/toaster/toaster.actions";

const initialState = {
  toasts: OrderedMap(),
};

const addToast = (state, {type, message, msec}) => {
  const icon = type === "success" ? "fa-check" : "fa-exclamation-circle";

  const id = state.toasts.last() ? state.toasts.last().get("id") + 1 : 1;
  const toast = {id, type, icon, message, msec};

  const newState = state.toasts.setIn([id], Map(toast));
  return {...state, toasts: newState};
};

const removeToast = (state, toastId) => {
  const newState = state.toasts.delete(toastId);
  return {...state, toasts: newState};
};

const toaster = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TOAST:
      return addToast(state, action.toast);
    case REMOVE_TOAST:
      return removeToast(state, action.toastId);
    default:
      return state;
  }
};

export default toaster;
